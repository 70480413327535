import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { RestService } from '../../service/api/rest.service';
import { map } from 'rxjs/operators';
import { SystemUsers } from './systemUsers.model';
import { Observable } from 'rxjs';

@Injectable()
export class SystemUsersDataService extends DefaultDataService<SystemUsers>
{
    private baseMessages = 'messages';
    private baseUsers = 'users';
    private baseChatRooms = 'chat_rooms';

    constructor(http: HttpClient,
                httpUrlGenerator: HttpUrlGenerator,
                private rest: RestService)
    {
        super('SystemUsers', http, httpUrlGenerator);
    }

    getSystemUsers(query): any
    {
        return this.rest.get(`${(this.baseUsers)}/friends${query}`).pipe(map(data => {
            return data;
        }));
    }

    getChatRoomMessages(query, params): any
    {
        return this.rest.get(`${(this.baseMessages)}/${query}`, params).pipe(map(data => {
            return data;
        }));
    }

    createChatMessage(params): any
    {
        return this.rest.post(`${(this.baseMessages)}`, params).pipe(map(data => {
            return data;
        }));
    }

    deleteChatMessage(id): any
    {
        return this.rest.delete(`${(this.baseMessages)}/${id}`);
    }

    updateChatMessage(id, params): any
    {
        return this.rest.put(`${(this.baseMessages)}/${id}`, params);
    }

    deleteChatFile(signedId: string): Observable<any>
    {
        return this.rest.delete(`${(this.baseMessages)}/delete_attachment/${(signedId)}`);
    }

    deleteChatRoom(id): any
    {
        return this.rest.delete(`${(this.baseChatRooms)}/delete_chat_room/${id}`);
    }
}
