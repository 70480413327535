import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { User } from '../../types';

@Injectable({providedIn: 'root'})
export class NewAuthService extends EntityCollectionServiceBase<User>
{
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private rest: RestService)
    {
        super('User', serviceElementsFactory);
    }

}
