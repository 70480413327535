import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FooterType } from '../../../../../types';


@Component({
  selector: 'app-product-detail-modal',
  templateUrl: './product-detail-modal.component.html',
  styleUrls: ['./product-detail-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductDetailModalComponent implements OnInit {
    product: any;
    isModal: boolean;
    tabBackgroundColor = '#dee1e6';
    showFooterType: FooterType = FooterType.Comment;
    selectedIndex = 0;

    constructor(@Inject(MAT_DIALOG_DATA) private _data: any,
                public matDialogRef: MatDialogRef<ProductDetailModalComponent>)
    {
    }

  ngOnInit(): void {
      this.product = this._data.product;
      this.isModal = this._data.isModal;
      if (this._data.openComment)
      {
          this.selectedIndex = 1;
      }
  }

    _selectedTabChange(index: number) {
        this.selectedIndex = index;
    }

    _selectedIndexChange(index: number) {
        this.selectedIndex = index;
    }
}
