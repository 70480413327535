import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskDataService } from '../../../../../../store/task/task-data.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { NewAuthDataService } from '../../../../../../service/api/newAuth-data.service';
import { FooterType, User } from 'app/types';

@Component({
    selector   : 'app-task-comment-files',
    templateUrl: './task-comment-files.component.html',
    styleUrls  : ['./task-comment-files.component.scss']
})
export class TaskCommentFilesComponent implements OnInit
{
    FooterType = FooterType;
    showFooterType: FooterType = FooterType.Comment;

    @Input() taskData;
    @Output() loadingProgress = new EventEmitter<boolean>();
    currentUser: User;

    constructor(
        private taskDataService: TaskDataService,
        private toast: ToastrService,
        private _matDialog: MatDialog,
        private authService: NewAuthDataService,
    )
    {
    }

    ngOnInit(): void
    {
        this.authService.getUser().subscribe((user) => {
            this.currentUser = user;
        });

    }

    setFooterType(type: FooterType): void
    {
        this.showFooterType = type;
    }

    getFileCount(post: any): number
    {
        return (post.clinicDocFileCount || 0) + (post.clinicDocCommentFileCount || 0);
    }
}
