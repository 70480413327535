import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AppsModule } from './main/apps/apps.module';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { entityConfig } from './entity-auth-metadata';
import { EntityDataModule, EntityDataService } from '@ngrx/data';
import { NewAuthDataService } from './service/api/newAuth-data.service';
import { GroupsDataService } from './store/group/groups-data.service';
import { GroupAdapter, GroupCategoryAdapter, GroupObjAdapter } from './store/group/group.model';
import { GroupUsersDataService } from './store/group/GroupUsers/group-users-data.service';
import { TaskDataService } from './store/task/task-data.service';
import { ThreadsDataService } from './store/thread/threads-data.service';
import { FriendDataService } from './store/friend/friend-data.service';
import { NotificationDataService } from './store/notifications/notification-data.service';
import { SystemUsersDataService } from './store/system-users/system-users-data.service';
import { ChatRoomsDataService } from './store/chat-rooms/chat-room-data.service';
import { StatusUpdateDirective } from './directive/status-update.directive';
import { UserIdleModule } from 'angular-user-idle';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { UploadingFilesDataService } from './store/uploading-files/uploading-files-data.service';
import { DocReadDataService } from './store/doc-read/doc-read-data.service';
import { NgxStripeModule } from 'ngx-stripe';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import {CartDataService} from "./store/cart/cart-data.service";

const appRoutes: Routes = [
    {
        path      : '**',
        redirectTo: 'error/404'
    }
];

@NgModule({
    declarations: [
        AppComponent,
        StatusUpdateDirective,

    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),

        TranslateModule.forRoot(),
        NgxLinkifyjsModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        RecaptchaV3Module,
        NgxStripeModule.forRoot(environment.STRIPE_PUBLIC_KEY),
        AppsModule,
        StoreModule.forRoot({}),
        StoreDevtoolsModule.instrument({
            name   : 'for testing',
            maxAge : 25,
            logOnly: environment.production
        }),
        UserIdleModule.forRoot({}),
        EffectsModule.forRoot([]),
        EntityDataModule.forRoot(entityConfig),
    ],
    providers   : [
        NewAuthDataService,
        GroupAdapter,
        GroupCategoryAdapter,
        GroupObjAdapter,
        GroupUsersDataService,
        ThreadsDataService,
        TaskDataService,
        FriendDataService,
        NotificationDataService,
        SystemUsersDataService,
        ChatRoomsDataService,
        UploadingFilesDataService,
        DocReadDataService,
        CartDataService,
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.RECAPTCHA_SITE_KEY }],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
    constructor(
        entityDataService: EntityDataService,
        newAuthDataService: NewAuthDataService,
        groupEntityService: GroupsDataService,
        groupUserEntityService: GroupUsersDataService,
        threadEntityService: ThreadsDataService,
        taskEntityService: TaskDataService,
        friendEntityService: FriendDataService,
        notificationEntityService: NotificationDataService,
        systemUsersEntityService: SystemUsersDataService,
        chatRoomsEntityService: ChatRoomsDataService,
        uploadingFilesEntityService: UploadingFilesDataService,
        docReadEntityService: DocReadDataService,
        cartEntityService: CartDataService
    )
    {
        entityDataService.registerServices(
            {
                User          : newAuthDataService,
                Group         : groupEntityService,
                GroupUser     : groupUserEntityService,
                Thread        : threadEntityService,
                Task          : taskEntityService,
                Friend        : friendEntityService,
                Notification  : notificationEntityService,
                SystemUsers   : systemUsersEntityService,
                ChatRooms     : chatRoomsEntityService,
                UploadingFiles: uploadingFilesEntityService,
                DocRead       : docReadEntityService,
                Cart          : cartEntityService
            }
        );
    }
}

