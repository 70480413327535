import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ThreadsDataService } from '../../../../../../store/thread/threads-data.service';
import { ToastrService } from 'ngx-toastr';
import { ThreadsEntityService } from '../../../../../../store/thread/threads-entity.service';
import { NewAuthDataService } from '../../../../../../service/api/newAuth-data.service';
import { GroupsDataService } from '../../../../../../store/group/groups-data.service';
import { GalleryRef } from 'ng-gallery/lib/services/gallery-ref';
import { Gallery } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { User } from '../../../../../../types';
import { AppUtils } from '../../../util';

@Component({
    selector   : 'post-files',
    templateUrl: './post-files.component.html',
    styleUrls  : ['./post-files.component.scss']
})
export class PostFilesComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
    @Input() post: any;
    files: any[] = [];
    user: User;
    currentClinic: any;
    deletePrivilige = false;
    loadingProgress = false;
    page = 1;


    galleryId = 'myLightbox' + Math.random();
    galleryRef: GalleryRef;

    constructor(
        private threadDataService: ThreadsDataService,
        private toast: ToastrService,
        private threadEntity: ThreadsEntityService,
        private newAuthDataService: NewAuthDataService,
        private groupsDataService: GroupsDataService,
        public gallery: Gallery,
        private lightbox: Lightbox,
    )
    {
    }

    ngOnDestroy(): void
    {
        this.galleryRef?.destroy();
    }

    ngAfterViewInit(): void
    {
        this.galleryRef = this.gallery.ref(this.galleryId);
    }

    ngOnInit(): void
    {
        this.groupsDataService.getById(this.post.clinicId).subscribe((data) => {
            this.currentClinic = data;
            const temp = this.currentClinic.clinicAdmins?.find((obj, index) => {
                    return obj.id == this.user.id;
                }
            );
            this.deletePrivilige = !!temp;
        });
        this.files.length = 0;
        if ( this.post.files )
        {
            this.files = [...this.post.files];
        }
        for (const commentFile of this.files)
        {
            commentFile.creatorId = this.post.docCreator.id;
            commentFile.uploadedBy = this.post.docCreator.full_name;
            commentFile.postId = this.post.id;
        }
        this.newAuthDataService.getUser().subscribe((user) => {
            this.user = user;
        });
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if ( this.currentClinic )
        {
            const temp = this.currentClinic.clinicAdmins.find((obj, index) => {
                    return obj.id == this.user.id;
                }
            );
            this.deletePrivilige = !!temp;
        }

        this.files.length = 0;
        if ( this.post.files )
        {
            this.files = [...this.post.files];
        }
        for (const commentFile of this.files)
        {
            commentFile.creatorId = this.post.docCreator.id;
            commentFile.uploadedBy = this.post.docCreator.full_name;
        }
        this.page = 1;
        this.loadMore();
    }

    deleteFile(fileObj: any, post: any, index): void
    {
        this.loadingProgress = true;
        this.post.files = this.post.files.filter(data => data !== fileObj);
        this.threadDataService.deleteFileFromCommentFiles(fileObj.signed_id).subscribe(res => {
            this.loadingProgress = false;
            this.toast.success(res.message);
            if ( res.comment_deleted )
            {
                this.post.clinicDocCommentsCount = this.post.clinicDocCommentsCount - 1;
            }
            this.post.clinicDocCommentFileCount = this.post.clinicDocCommentFileCount - 1;
            this.files.splice(index, 1);
            this.post.docComments = this.post.docComments.filter((data) => {
                if ( data.commentType === 'file' )
                {
                    data.files = data.files?.filter((x) => {
                        return x.signed_id !== fileObj.signed_id;
                    });
                    return data.files?.length;
                }
                return true;
            });
            this.threadEntity.updateOneInCache({
                clinicDocCommentFileCount: this.post.clinicDocCommentFileCount,
                clinicDocCommentsCount   : this.post.clinicDocCommentsCount,
                docComments              : this.post.docComments,
                id                       : this.post.id
            });
        }, err => {
            this.loadingProgress = false;
            this.toast.error(err.errors.join(' ,'));
        });
    }

    isTypeImage(fileObj, type): any
    {
        if ( type === 'link' )
        {
            return !(fileObj.content_type.includes('video') || fileObj.content_type.includes('image'));
        }
        return fileObj.content_type.includes(type);
    }

    loadMore(): void
    {
        this.loadingProgress = true;
        this.threadDataService.getThreadCommentFiles(this.post.id, {
            page : this.page++,
            limit: 5
        }).subscribe((data) => {
            const commentFiles = data.clinic_doc_files_comments.filter(res => res.comment_type !== 'comment') || [];
            commentFiles?.forEach(file => {
                for (const obj of file?.files)
                {
                    this.files.push({
                        ...obj,
                        fileType  : 'comment',
                        uploadedBy: file.comment_creator.full_name,
                        creatorId : file.comment_creator.id
                    });
                }
            });
            this.loadingProgress = false;
        });
    }

    openFile(fileObj: any): string
    {
        return fileObj.src;
    }

    openVideo(fileObj: any): void
    {
        this.galleryRef.reset();
        this.galleryRef.remove(0);
        this.galleryRef.addVideo({
            src: [{
                url : fileObj.src,
                type: AppUtils.videoContentType(fileObj.content_type)
            }]
        });
        this.lightbox.open(0, this.galleryId, {
            panelClass: 'fullscreen'
        });

    }
}
