<div class="comments">

    <div class="section-header" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-button class="like-button" (click)="setFooterType(FooterType.Comment)">
                        <span fxLayout="row" fxLayoutAlign="start center">
                            <span>{{'TASKS.comment' | translate}}</span>&nbsp;<span>({{taskData.clinicDocCommentsCount}})</span>
                        </span>
            </button>
            <button mat-button class="share-button" (click)="setFooterType(FooterType.File)">
                        <span fxLayout="row" fxLayoutAlign="start center">
                            <span>{{'TASKS.file' | translate}}</span>&nbsp;<span>({{ getFileCount(taskData) }})</span>
                        </span>
            </button>
        </div>
    </div>

    <div class="section-content">
        <post-footer forTask="true" [post]="taskData" [type]="showFooterType"></post-footer>
    </div>

</div>
