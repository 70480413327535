<quill-editor
        #myInput
        theme="bubble"
        [(ngModel)]="model.editorData"
        trackChanges="all"
        [modules]="modules"
        [placeholder]="placeholder"
        (onEditorCreated)='editorInit($event)'
        (onSelectionChanged)="changeEvent($event)"
        (onContentChanged)="onContentChanged($event)">
</quill-editor>
