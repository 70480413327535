// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production  : false,
    hmr         : true,
    BASE_API_URL: 'https://wshapi.onlinedoc.dk/api/v1/',
    BASE_WS_URL : 'wss://wshapi.onlinedoc.dk/cable',
    BASE_SLID_URL : 'https://wshaslideshow.onlinedoc.dk',
    STRIPE_PUBLIC_KEY : 'pk_test_51KnzqzE57Y6kEfveRg9UJNnoBcbcrC5GSRABQdRZBN6QR3I2vm6yZlFjVqVMiZl24Aefk5nT5qCzgQ799piL6iPb00i1dyS7pE',
    RECAPTCHA_SITE_KEY : '6LfNWfEgAAAAAHswwy3UkI3edXv6EcemTLqpZSQN',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
