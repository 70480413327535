import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { FileUploadService } from './api/file-upload.service';

@Injectable({
    providedIn: 'root'
})
export class UtilityService
{
    constructor(private http: HttpClient,
                private fileUpload: FileUploadService,)
    {
    }

    downloadFile(file: any): Observable<Blob>
    {
        return this.http.get(file.src, {responseType: 'blob'});
    }

    getInitials = (name: any) => {
        let initials;
        const nameSplit = name.split(' ');
        const nameLength = nameSplit.length;
        if ( nameLength > 1 )
        {
            initials =
                nameSplit[0].substring(0, 1) +
                nameSplit[nameLength - 1].substring(0, 1);
        } else if ( nameLength === 1 )
        {
            initials = nameSplit[0].substring(0, 1);
        } else
        {
            return;
        }

        return initials.toUpperCase();
    }
    createImageFromInitials = (size: any, name: any, color: any) => {
        if ( name == null )
        {
            return;
        }
        name = this.getInitials(name);
        const canvas = document.createElement('canvas');
        const context: any = canvas.getContext('2d');
        canvas.width = canvas.height = size;
        context.fillStyle = '#ffffff';
        context.fillRect(0, 0, size, size);
        context.fillStyle = `${color}50`;
        context.fillRect(0, 0, size, size);
        context.fillStyle = color;
        context.textBaseline = 'middle';
        context.textAlign = 'center';
        context.font = `${size / 1.7}px Roboto`;
        context.fillText(name, (size / 1.95), (size / 1.8));
        return canvas.toDataURL();
    }
    collectParams(root: ActivatedRouteSnapshot): Params
    {
        const params: Params = {};
        (function mergeParamsFromSnapshot(snapshot: ActivatedRouteSnapshot): any {
            Object.assign(params, snapshot.params);
            snapshot.children.forEach(mergeParamsFromSnapshot);
        })(root);
        return (params);
    }

    async directUpload( attachments, clinicId? ): Promise<any>
    {
        const temp = {
            files: attachments,
            sub  : new Subject()
        };
        // if ( clinicId )
        // {
        //     for (const tempElement of attachments)
        //     {
        //         tempElement.file.clinic_id = clinicId;
        //     }
        // }
        this.fileUpload.uploadRequests.next(temp);
        return await temp.sub.asObservable().toPromise();
    }
}
