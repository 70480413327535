<div fxLayout="row" fxLayout.lt-md="column" class="main-container">
    <mat-card fxLayout="column">
        <single-product [product] ="product" [isModal]="showEdit" ></single-product>
        <mat-card-footer>
        <div class="footer-container mt-8">
        <post-footer [post]="product" [type]="showFooterType"></post-footer>
        </div>
            </mat-card-footer>
    </mat-card>
</div>
