import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';
import { map, tap } from 'rxjs/operators';
import { LocalStorage, LocalStorageService, SessionStorage, SessionStorageService } from 'angular-web-storage';
import { EMPTY, Observable, of, ReplaySubject } from 'rxjs';
import { NewAuthService } from './newAuth.service';
import { User } from '../../types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { NotificationDataService } from '../../store/notifications/notification-data.service';
import { ChatRoomsDataService } from '../../store/chat-rooms/chat-room-data.service';
import { UserIdleService } from 'angular-user-idle';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionCableService } from '../socket/action-cable.service';
import { NotificationEntityService } from '../../store/notifications/notification-entity.service';
import { ChatRoomsEntityService } from '../../store/chat-rooms/chat-room-entity.service';
import { FriendsEntityService } from '../../store/friend/friend-entity.service';
import { GroupsEntityService } from '../../store/group/groups-entity.service';
import { SystemUsersEntityService } from '../../store/system-users/system-user-entity.service';
import { TasksEntityService } from '../../store/task/task-entity.service';
import { ThreadsEntityService } from '../../store/thread/threads-entity.service';
import { GroupUsersEntityService } from '../../store/group/GroupUsers/group-users-entity.service';
import {CartDataService} from "../../store/cart/cart-data.service";

@Injectable(
    {providedIn: 'root'}
)
export class NewAuthDataService extends DefaultDataService<User>
{
    private _base = 'users/';
    @LocalStorage('user')
    loggedInUser;
    @LocalStorage('last_user')
    lastLoggedInUser;
    @LocalStorage('token')
    userToken;

    @SessionStorage('user')
    sessionUser;
    @SessionStorage('last_user')
    lastSessionUser;
    @SessionStorage('token')
    sessionToken;
    userSubject = new ReplaySubject<User>();
    isCalled = false;
    rememberMe = false;
    myDocs = [{
        id       : 'myTimeline',
        title    : 'My TimeLine',
        translate: 'GROUPS.my_timeline',
        type     : 'item',
        icon     : 'timeline',
        url      : '/time-line',
    }, {
        id       : 'myTask',
        title    : 'My Task',
        translate: 'GROUPS.my_task',
        type     : 'item',
        icon     : 'check_circle_outline',
        url      : '/tasks',
    }, {
        id       : 'myCalendar',
        title    : 'My Calendar',
        translate: 'GROUPS.my_calendar',
        type     : 'item',
        icon     : 'event',
        url      : '/planning',
    }, {
        id       : 'myContacts',
        title    : 'My Contact',
        translate: 'GROUPS.my_contact',
        type     : 'item',
        icon     : 'account_circle',
        url      : '/contact',
    }, {
        id       : 'myFiles',
        title    : 'My Files',
        translate: 'GROUPS.my_files',
        type     : 'item',
        icon     : 'folder_open',
        url      : '/files',
    },
    ];

    constructor(http: HttpClient,
                httpUrlGenerator: HttpUrlGenerator,
                private rest: RestService,
                private local: LocalStorageService,
                private baseService: NewAuthService,
                private sessionStorage: SessionStorageService,
                private _fuseNavigationService: FuseNavigationService,
                private notificationDataService: NotificationDataService,
                private cartDataService: CartDataService,
                private userIdle: UserIdleService,
                private _matDialog: MatDialog,
                private router: Router,
                private actionCableService: ActionCableService,
                private chatRoomsDataService: ChatRoomsDataService,
                private notificationEntityService: NotificationEntityService,
                private chatRoomsEntityService: ChatRoomsEntityService,
                private friendsEntityService: FriendsEntityService,
                private groupsEntityService: GroupsEntityService,
                private activatedRoute: ActivatedRoute,
                private groupUsersEntityService: GroupUsersEntityService,
                private systemUsersEntityService: SystemUsersEntityService,
                private tasksEntityService: TasksEntityService,
                private threadsEntityService: ThreadsEntityService
    )
    {
        super('User', http, httpUrlGenerator);
        if ( this.getSignedInUser() && this.getToken() )
        {
            this.userSubject.next(this.getSignedInUser());
            this.baseService.addOneToCache(this.getSignedInUser());
        }
    }

    register(params): any
    {
        return this.rest.post(`users.json`, params);
    }

    getToken(): string
    {
        if ( this.loggedInUser )
        {
            return this.loggedInUser;
        } else
        {
            return this.sessionToken;
        }
    }

    getLastSignedInUser(): User
    {
        const user = this.lastLoggedInUser;
        return user;
    }

    getSignedInUser(): User
    {
        if ( this.loggedInUser )
        {
            return this.loggedInUser;
        } else
        {
            return this.sessionUser;
        }
    }

    login(params): Observable<any>
    {
        if ( params?.user?.remember )
        {
            this.rememberMe = params.user.remember;
            delete params.user.remember;
        }
        return this.rest.post(`${(this._base)}login.json`, params, 'application/json').pipe(tap(ev => {
                this._setUser(ev);
                this.baseService.addOneToCache(ev.user);
            }),
            map((data) => {
                return {...data.user, id: data.user.id};
            }));
    }

    getUpdatedUser(): any
    {
        return this.rest.get(`${(this._base)}me.json`).pipe(tap(ev => this._setUser(ev)));
    }

    shouldUpdateUser(): boolean
    {
        return !!(!this.sessionUser && this.loggedInUser);
    }

    logout(): void
    {
        this.isCalled = false;
        this.chatRoomsEntityService.clearCache();
        this.friendsEntityService.clearCache();
        this.groupsEntityService.clearCache();
        this.groupUsersEntityService.clearCache();
        this.notificationEntityService.clearCache();
        this.systemUsersEntityService.clearCache();
        this.tasksEntityService.clearCache();
        this.threadsEntityService.clearCache();
        this.baseService.clearCache();
        this.userIdle.stopWatching();
        this.userIdle.ping$ = of(null);
        this.local.remove('user');
        this.local.remove('token');
        this.sessionStorage.clear();
        this.actionCableService.reset();
    }

    isActive(): boolean
    {
        if ( this.loggedInUser && this.userToken )
        {
            return true;
        } else
        {
            return !!(this.sessionStorage.get('token') && this.sessionStorage.get('user'));
        }
    }

    confirmEmail(param): any
    {
        const data = {
            user: param
        };
        return this.rest.post(`${(this._base)}verify_user_email.json`, data);
    }

    forgotPassword(param): any
    {
        return this.rest.post(`${(this._base)}forgot_password.json`, param, 'application/json');
    }

    resetPassword(param): any
    {
        return this.rest.post(`${(this._base)}reset_password.json`, param).pipe();
    }

    changePassword(param): any
    {
        return this.rest.post(`${(this._base)}change_password.json`, param);
    }

    verifyResetPasswordToken(param): any
    {
        return this.rest.post(`${(this._base)}verify_reset_password_token.json`, param).pipe();
    }

    updateInactivityLimit(param): any
    {
        return this.rest.put(`${(this._base)}set_inactivity_limit.json`, param);
    }

    getAllUsers(): any
    {
        return this.rest.get(`${(this._base)}all_system_users?search=1&page=1&limit=10.json`);
    }

    deactivateUser(param): any
    {
        return this.rest.post(`${(this._base)}deactivate_user`, param);
    }

    deleteUser(param): any
    {
        return this.rest.post(`${(this._base)}delete_user`, param);
    }

    reactivateUser(param): any
    {
        return this.rest.post(`${(this._base)}reactivate_user`, param);
    }

    updateUserData(param): any
    {
        return this.rest.put(`${(this._base)}update.json`, param).pipe(tap(ev => this._setUser(ev)));
    }

    private _setUser(ev: any): void
    {
        if ( ev )
        {
            if ( ev.user )
            {
                ev.user.image = ev.user.file?.medium_url || 'assets/images/avatars/profile.jpg';
                this.local.set('last_user', ev.user, 30, 'd');
                if ( this.rememberMe )
                {
                    this.local.set('user', ev.user, 30, 'd');
                } else
                {
                    this.sessionStorage.set('user', ev.user);
                }
                this.baseService.updateOneInCache(ev.user);
                this.userSubject.next(ev.user);
            }
            if ( ev.user.token )
            {
                if ( this.rememberMe )
                {
                    this.local.set('token', ev.user.token, 30, 'd');
                } else
                {
                    this.sessionStorage.set('token', ev.user.token);
                }
                this.getInfoOnLogin(ev.user);
            }
        }
    }

    reSendConfirmation(param): any
    {
        return this.rest.post(`${(this._base)}resend_confirmation_email.json`, param);
    }

    checkConfirmationCode(param): any
    {
        return this.rest.post(`${(this._base)}verify_user_phone_code.json`, param).pipe(tap(ev => this._setUser(ev)));
    }

    getUser(): Observable<User>
    {
        return this.userSubject.asObservable().pipe(tap((res) => {
            this.setIdealWatcher(res as any);
            this.getInfoOnLogin(res);
        }));
    }

    add(newUser: User): Observable<User>
    {
        return of(newUser)
            .pipe(
                map((data) => {
                    return {...newUser, id: newUser.id};
                })
            );
    }

    getAllEmails(): any
    {
        return this.rest.get('user_emails.json').pipe(tap((res) => {
            if ( this.getSignedInUser() )
            {
                this.baseService.updateOneInCache({userEmails: res.user_emails, id: this.getSignedInUser().id});
            }
        }));
    }

    getAllNumbers(): any
    {
        return this.rest.get('user_phone_lists.json').pipe(tap((res) => {
            if ( this.getSignedInUser() )
            {
                this.baseService.updateOneInCache({userNumbers: res.user_phone_list, id: this.getSignedInUser().id});
            }
        }));
    }

    getAllWebsites(): any
    {
        return this.rest.get('user_web_links.json').pipe(tap((res) => {
            if ( this.getSignedInUser() )
            {
                this.baseService.updateOneInCache({userWebsites: res.user_web_links, id: this.getSignedInUser().id});
            }
        }));
    }

    deleteUserNumber(param: string): any
    {
        return this.rest.delete('user_phone_lists/' + param);
    }

    createUserNumber(param): any
    {
        return this.rest.post('user_phone_lists', param);
    }

    updateUserNumber(id, param): any
    {
        return this.rest.put('user_phone_lists/' + id, param);
    }

    deleteUserWebsite(param: string): any
    {
        return this.rest.delete('user_web_links/' + param);
    }

    createUserWebsite(param): any
    {
        return this.rest.post('user_web_links', param);
    }

    updateUserWebsite(id, param): any
    {
        return this.rest.put('user_web_links/' + id, param);
    }

    switchPrimaryEmail(param): any
    {
        return this.rest.post('user_emails/switch_primary_email', param);
    }

    createUserEmail(param): any
    {
        return this.rest.post('user_emails', param);
    }

    deleteUserEmail(param: string): any
    {
        return this.rest.delete('user_emails/' + param);
    }

    addToPhoneList(param): Observable<any>
    {
        return this.rest.post(`${(this._base)}user_phone_lists`, param);
    }

    updateToPhoneList(param): Observable<any>
    {
        return this.rest.put(`${(this._base)}user_phone_lists/` + param.id, param);
    }

    deleteInPhoneList(param): Observable<any>
    {
        return this.rest.delete(`${(this._base)}user_phone_lists/` + param.id, param);
    }

    verifySecondaryEmail(param): Observable<any>
    {
        return this.rest.post(`user_emails/confirm_secondary_email_verification_token`, param).pipe(
            tap(data => data)
        );
    }

    getPinnedGroups(): Observable<any>
    {
        if ( this.getSignedInUser() )
        {
            return this.rest.get('clinics/pinned_clinics').pipe(
                tap(() => {
                    this._fuseNavigationService.removeNavigationItem('quick_access');
                    this._fuseNavigationService.addNavigationItem({
                        id       : 'quick_access',
                        title    : 'Follows',
                        translate: 'NAV.Quick-Access',
                        type     : 'group',
                        children : []
                    }, 'end');
                }),
                map(data => {
                    for (const clinic of data.clinics)
                    {
                        const group = clinic;
                        const navItem = {
                            id        : 'item_' + group.id,
                            title     : group.name,
                            translate : group.name,
                            type      : 'item',
                            icon      : 'plus',
                            url       : '/groups/' + group.id,
                            exactMatch: true
                        };
                        if ( !this._fuseNavigationService.getNavigationItem(navItem.id) )
                        {
                            this._fuseNavigationService.addNavigationItem(navItem, 'quick_access');
                        } else
                        {
                            this._fuseNavigationService.removeNavigationItem(navItem.id);
                            this._fuseNavigationService.addNavigationItem(navItem, 'quick_access');
                        }
                    }
                    return data;
                })
            );

        }
        return EMPTY;
    }
    getApprovedGroups(page, remove): Observable<any>{
        if ( this.getSignedInUser() )
        {
            const signedUser = this.getSignedInUser();
            const params = {
                'page'          : page,
                'request_status': 'approved',
                'limit'         :  25,
            };
            return this.rest.get('clinics/get_all_clinic_listing_web', params).pipe(
                tap(() => {
                    if ( remove )
                    {
                        this._fuseNavigationService.removeNavigationItem('quick_access');
                        this._fuseNavigationService.addNavigationItem({
                            id       : 'quick_access',
                            title    : 'Follows',
                            translate: 'NAV.Quick-Access',
                            type     : 'group',
                            children : []
                        }, 'end');
                    }
                }),
                map(data => {
                    const approvedGroups = [...data.groups];
                    for (const clinic of approvedGroups)
                    {
                        const group = clinic;
                        const navItem = {
                            id        : 'item_' + group.id,
                            title     : group.name,
                            translate : group.name,
                            type      : 'item',
                            icon      : group.group_pinned ? 'bookmark' : 'bookmark_border',
                            pinned    : group.group_pinned,
                            url       : '/groups/' + group.id,
                            classes   : group.group_pinned ? 'favourite-icon' : 'non-favourite-icon',
                            exactMatch: true
                        };
                        if ( !this._fuseNavigationService.getNavigationItem(navItem.id) )
                        {
                            this._fuseNavigationService.addNavigationItem(navItem, 'quick_access');
                        } else
                        {
                            this._fuseNavigationService.removeNavigationItem(navItem.id);
                            this._fuseNavigationService.addNavigationItem(navItem, 'quick_access');
                        }
                    }

                    // for ( const doc of this.myDocs ){
                    //     const navItem = {
                    //         id        : doc?.id,
                    //         title     : doc?.title,
                    //         translate : doc?.translate,
                    //         type      : 'item',
                    //         icon      : doc?.icon,
                    //         url       : '/groups/' + signedUser?.my_doc?.id + doc.url,
                    //         exactMatch: true
                    //     };
                    //     if ( !this._fuseNavigationService.getNavigationItem(navItem.id) )
                    //     {
                    //         this._fuseNavigationService.addNavigationItem(navItem, 'my_docs');
                    //     } else
                    //     {
                    //         this._fuseNavigationService.removeNavigationItem(navItem.id);
                    //         this._fuseNavigationService.addNavigationItem(navItem, 'my_docs');
                    //     }
                    // }
                    return data;
                })
            );
        }
        return EMPTY;
    }

    getInfoOnLogin(data: User): void
    {
        if ( !this.isCalled && this.isActive() )
        {

            this.getApprovedGroups(1, true).subscribe();
            this.notificationDataService.getAllNotificationCount();
            if (data?.email !== 'guest@onlinedoc.dk')
            {
                this.cartDataService.getCartCount(data?.shopping_cart_id);
            }
            // this.chatRoomsDataService.getChatRoomNotificationCount();
            this.isCalled = true;
        }
    }

    private setIdealWatcher({inactivity_limit, email}): void
    {
        if ( !this.userIdle.ping$ )
        {
            this.userIdle.setConfigValues({idle: 60 * inactivity_limit, timeout: 1, ping: 30});
            this.userIdle.startWatching();
            this.userIdle.onTimerStart().subscribe();
            this.userIdle.onTimeout().subscribe((status) => {
                this.logout();
                this._matDialog.closeAll();
                const currentRoute = this.activatedRoute.snapshot?.queryParams?.route ?? this.router.url;
                if (email === 'guest@onlinedoc.dk') {
                    this.router.navigate(['/auth/login'], {queryParams: {route: currentRoute}}).then(() => {
                    });
                } else {
                    this.router.navigate(['/auth/lock'], {queryParams: {route: currentRoute}}).then(() => {
                    });
                }
            });
        }
    }

    getAppVersion(): Observable<any>
    {
        return this.rest.get('app_versions');
    }
}
