<div id="dashboard-main" class="page-layout simple right-sidebar inner-scroll">

    <!-- SIDEBAR -->
    <fuse-sidebar *ngIf="showTasks" class="sidebar" name="project-dashboard-right-sidebar-1" position="left" lockedOpen="gt-md">

        <!-- SIDEBAR CONTENT -->
        <div class="content p-24" fusePerfectScrollbar>
            <dashboard-tasks (hideTasks)="hideTasks($event)"></dashboard-tasks>
        </div>
        <!-- / SIDEBAR CONTENT -->

    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center"
         infinite-scroll
         [scrollWindow]="false"
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="0"
         [infiniteScrollDisabled]="holdingScroll"
         (scrolled)="onScrollDown()"
         fusePerfectScrollbar>
        <!-- HEADER -->
        <div class="header accent py-36 px-60 pb-0" fxLayout="column" fxLayoutAlign="space-between">

            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="5px" class="header-content">
                    <img src="assets/images/backgrounds/dashboard-header.jpg"
                         [@animate]="{value:'*',params:{x:'50px'}}">
                    <div class="input-container" fxLayout="row">
                        <input #searchProductElement [(ngModel)]="searchText" (ngModelChange)="modalChange($event)"
                               placeholder="{{'PRODUCT.search_product' | translate}}">
                        <span *ngIf="resetSearchText"><mat-icon
                                (click)="onClickCloseResetForm()" class="secondary-text"
                                matTooltip="Clear Search">close</mat-icon></span>
                    </div>
                </div>
                <button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md
                        *ngIf="showTasks"
                        (click)="toggleSidebar('project-dashboard-right-sidebar-1')">
                    <mat-icon>menu</mat-icon>
                </button>
                <p (click)="logout()" *ngIf="email === 'guest@onlinedoc.dk'" routerLink="/auth/login" class="cursor-pointer login-text"><u class="login-text">{{'Login Guest' | translate}}</u></p>

            </div>
        </div>
        <!-- / HEADER -->


                <div>
                    <timeline fxFlex="100"
                              fxLayout="column" *fuseIfOnDom
                              fxLayoutAlign="center center"
                              (searchEvent) ="getSearchText($event)"
                              [@animateStagger]="{value:'50'}"></timeline>
                </div>


    </div>
    <!-- / CENTER -->
</div>
