import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector     : 'typing-animation',
    templateUrl  : './typing-animation.component.html',
    styleUrls    : ['./typing-animation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TypingAnimationComponent implements OnInit
{
    isTyping = false;
    @Input() userTyping;

    constructor()
    {
    }

    ngOnInit(): void
    {
        if ( this.userTyping )
        {
            this.userTyping.subscribe((data) => {
                this.typing();
            });
        }
    }

    typing(): any
    {
        this.isTyping = true;
        setTimeout(() => {
            this.isTyping = false;
        }, 2000);
    }
}
