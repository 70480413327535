import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductDetailModalComponent } from './product-detail-modal.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { SingleProductModule } from '../../single-product/single-product.module';
import { PostModule } from '../../post/post.module';
import { MatIcon, MatIconModule } from '@angular/material/icon';


@NgModule({
    declarations: [ProductDetailModalComponent],
    imports     : [
        CommonModule,
        MatTabsModule,
        TranslateModule,
        SingleProductModule,
        PostModule,
        MatIconModule
    ],
    exports     : [
        ProductDetailModalComponent
    ]
})
export class ProductDetailModule
{
}
