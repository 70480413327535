import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import { FriendDataService } from '../../../../store/friend/friend-data.service';
import { FriendsEntityService } from '../../../../store/friend/friend-entity.service';
import { Router } from '@angular/router';
import { GroupInviteComponent } from '../../components/dialogs/group-invite-modal/group-invite.component';
import { GroupsDataService } from '../../../../store/group/groups-data.service';
import { NewAuthDataService } from '../../../../service/api/newAuth-data.service';
import { User } from '../../../../types';
import { AppUtils } from '../../components/util';
import { IFriend, IFriendDetails, IFriendUser } from '../../../../types/Friend';

@Component({
    selector     : 'friend-detail-modal',
    templateUrl  : './friend-detail-modal.component.html',
    styleUrls    : ['./friend-detail-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FriendDetailModalComponent implements OnInit, OnDestroy
{
    connectionToggle: boolean;
    detail: IFriendDetails;
    index: number;
    isFriend: boolean;
    isAlreadyFriend = false;
    friend: IFriend | any ;
    viewProfile: boolean;
    removeFriendRequest: boolean;
    user: string;
    newUser: string;
    addFriendRequest: boolean;
    currentUser: User;
    friendRequestAcceptDelete = false;
    preNames: string;

    constructor(public matDialogRef: MatDialogRef<FriendDetailModalComponent>,
                @Inject(MAT_DIALOG_DATA) private _data: any,
                private _matDialog: MatDialog,
                private _formBuilder: FormBuilder,
                private progressBarService: FuseProgressBarService,
                private toast: ToastrService,
                private friendDataService: FriendDataService,
                private friendsEntityService: FriendsEntityService,
                private groupDataService: GroupsDataService,
                private router: Router,
                private _authService: NewAuthDataService,
    )
    {
        this.connectionToggle = this._data.viewProfile;
        this.viewProfile = this._data.viewProfile;
        this.detail = this._data.detail;
        this.index = this._data.index;
        this.isFriend = this._data.isFriend;
        this.friend = this._data.friend;
    }

    ngOnInit(): void
    {
        this.preNames = AppUtils.preNameStatus(this._data.detail.pre_name);
        this.groupDataService.castUser.subscribe(user => this.user = user);
        this._authService.getUser().subscribe(data => {
            this.currentUser = data;
        });
        if ( this._data.detail.friend_status !== null )
        {
            if ( this._data.detail.friend_status.receiver_id === this.currentUser.id )
            {
                this.friendRequestAcceptDelete = !this.friendRequestAcceptDelete;
            }
        }
        if ( this._data.detail.friend_status === null ||
            this._data.detail.friend_status.request_status === 'message' ||
            this._data.detail.friend_status.request_status === 'cancel' )
        {
            this.friendRequestAcceptDelete = false;
            this.removeFriendRequest = false;
            this.addFriendRequest = true;
        } else if ( this._data.detail.friend_status.request_status === 'request' )
        {
            this.removeFriendRequest = true;
            this.addFriendRequest = false;
        } else if ( this._data.detail.friend_status.request_status === 'accept' )
        {
            this.removeFriendRequest = false;
            this.addFriendRequest = false;
            this.isAlreadyFriend = true;
        }
    }

    ngOnDestroy(): void
    {
        this.removeFriendRequest = false;
    }

    acceptRequest(): void
    {
        this.progressBarService.show();
        const statusUpdate = {
            friend: {
                receiver_id   : this.friend.user.id,
                request_status: 'accept'
            }
        };
        this.friendDataService.updateFriendStatus(statusUpdate).subscribe((data) => {
            this.removeFriendRequest = false;
            this.addFriendRequest = false;
            this.isAlreadyFriend = true;
            this.friendRequestAcceptDelete = false;
            this.progressBarService.hide();
            this.toast.success('', 'Friend Request Accepted');
            this.friendsEntityService.addOneToCache({...this.friend, id: this.friend.id});
            this.matDialogRef.close('Request Accepted');
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.errors.join(', '), 'Error');
        });
    }

    rejectRequest(): void
    {
        this.progressBarService.show();
        const statusUpdate = {
            friend: {
                receiver_id   : this.friend.user.id,
                request_status: 'cancel'
            }
        };
        this.friendDataService.updateFriendStatus(statusUpdate).subscribe((data) => {
            this.friendRequestAcceptDelete = false;
            this.removeFriendRequest = false;
            this.addFriendRequest = true;
            this.progressBarService.hide();
            this.toast.success('', 'Friend Request Rejected');
            this.matDialogRef.close('Request Rejected');
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.errors.join(', '), 'Error');
        });
    }

    requestConnectionRequest(): void
    {
        this.progressBarService.show();
        const statusUpdate = {
            friend: {
                receiver_id   : this._data.detail.id,
                request_status: 'request'
            }
        };
        this.friendDataService.createFriendRequest(statusUpdate).subscribe((data) => {
            this.addFriendRequest = false;
            this.removeFriendRequest = true;
            this.progressBarService.hide();
            this.toast.success('', data.message);
            this.connectionToggle = false;
        }, (error) => {
            this.progressBarService.hide();
            // this.toast.error('Something Went Wrong', 'Error');
            this.toast.error(error.errors.join(', '), 'Error');
        });
    }

    removeConnectionRequest(): void
    {
        this.progressBarService.show();
        const statusUpdate = {
            friend: {
                receiver_id   : this._data.detail.id,
                request_status: 'cancel'
            }
        };
        this.friendDataService.updateFriendStatus(statusUpdate).subscribe((data) => {
            this.addFriendRequest = true;
            this.removeFriendRequest = false;
            this.progressBarService.hide();
            this.toast.success('', 'Remove friend request successfully.');
            this.connectionToggle = false;
        }, (error) => {
            this.progressBarService.hide();
            // this.toast.error('Something Went Wrong', 'Error');
            this.toast.error(error.errors.join(', '), 'Error');
        });
    }

    onClickSendMessage(): void
    {
        this.matDialogRef.close();
        this.router.navigate(['/messages', this.friend.user.id],
            {state: {data: this.friend.user}});
    }

    openInviteModal(): void
    {
        this._matDialog.open(GroupInviteComponent, {
            panelClass: 'group-invite',
            data      : {
                user        : this._data.friend.user,
                profileShown: true,
            }
        }).afterClosed().subscribe((res) => {

        });
    }

}
