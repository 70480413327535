import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

    constructor(private _domSanitizer: DomSanitizer) {}

    transform(value: any, args?: any): any {
        const val = value.split('<p style="user-select: auto;">').join(' ').split('</p>').join(' ');
        const exp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
        const newVal = val ? val.replace(exp, '<a href=\'$1\' target=\'_blank\'>$1</a>') : val;
        return this._domSanitizer.bypassSecurityTrustHtml(newVal);
    }

}
