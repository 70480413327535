import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Thread } from '../../../../../../store/thread/thread.model';
import {
    ProductDetailModalComponent
} from '../../../../components/dialogs/product-detail-modal/product-detail-modal.component';
import { User } from '../../../../../../types';
import {
    ConfirmationDialogComponent
} from '../../../../components/dialogs/confirmation-dailog/confirmation-dialog.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import { ThreadsDataService } from '../../../../../../store/thread/threads-data.service';
import { ThreadsEntityService } from '../../../../../../store/thread/threads-entity.service';
import {
    ProductCreationModalComponent
} from '../../../../components/dialogs/product-creation-modal/product-creation-modal.component';
import { NewAuthDataService } from '../../../../../../service/api/newAuth-data.service';
import { GuestModalComponent } from '../../../../components/dialogs/guest-modal/guest-modal.component';
import { CartDataService } from '../../../../../../store/cart/cart-data.service';
import { ActivatedRoute } from '@angular/router';
import { GroupsEntityService } from '../../../../../../store/group/groups-entity.service';
import { IGroup } from '../../../../../../store/group/group.model';

@Component({
  selector: 'store-grid-view',
  templateUrl: './store-grid-view.component.html',
  styleUrls: ['./store-grid-view.component.scss']
})
export class StoreGridViewComponent implements OnInit {
    @Input() product: Thread | any;
    currentUser: User;
    threadAuthor: boolean;
    currentClinic: IGroup | any;
    isVideo = false;
    private clinicId: number;


  constructor( private _matDialog: MatDialog,
               private progressBarService: FuseProgressBarService,
               private authService: NewAuthDataService,
               private toast: ToastrService,
               private threadDataService: ThreadsDataService,
               private cartDataService: CartDataService,
               private threadEntity: ThreadsEntityService,
               private _route: ActivatedRoute,
               private groupEntity: GroupsEntityService,) { }

  ngOnInit(): void {

      this.authService.getUser().subscribe((data) => {
          this.currentUser = data;
      });
      this.threadAuthor = this.currentUser.id === this.product.docCreator?.id;
      if ( this.product?.files )
      {
          this.isVideo = this.product?.files[0]?.content_type.includes('video');
      }
  }
    deleteThread(): void
    {
        this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: 'confirmation-dialog',
            data      : {
                title  : 'Delete Thread',
                message: 'Are you sure you want to delete this product?'
            }
        }).afterClosed().subscribe((res) => {
            if ( res && res[0] === 'yes' )
            {
                this.progressBarService.show();
                this.threadDataService.deleteThread(this.product?.id).subscribe((data) => {
                    this.toast.success('Your Product is Deleted Successfully');
                    this.threadEntity.delete(this.product?.id);
                    this.progressBarService.hide();
                }, (error) => {
                    this.progressBarService.hide();
                    this.toast.error(error.error, 'Error');
                });
            }
        });
    }

    openProductDetails(openComment = false): void
    {

        this._matDialog.open(ProductDetailModalComponent, {
            panelClass: 'product-dialog-container',
            data: {
                product: this.product,
                isModal: true,
                openComment: openComment
            }
        });

    }
    editProduct(): void
    {
        this._matDialog.open(ProductCreationModalComponent, {
            panelClass: 'card-dialog',
            data      : {
                new       : false,
                threadData: this.product,
            }
        });
    }

    addToBasket(): void
    {
        if (this.currentUser.email === 'guest@onlinedoc.dk')
        {
            this._matDialog.open(GuestModalComponent, {
                panelClass: 'guest-modal',
                data      : {
                    name: 'test',
                }
            }).afterClosed().subscribe((res) => {

            });
            return;
        }
        this.progressBarService.show();
        const data = {
            "clinic_id": this.product.clinicId,
            "doc_id": this.product.id,
            "variation_id": this.product?.productVariations[0]?.id,
            "quantity": 1
        };
        this.cartDataService.addToCart(this.currentUser.shopping_cart_id, data).subscribe((res) => {
            this.toast.success('', res?.message ?? 'Product added to basket successfully');
            this.cartDataService.updateCartCount({total_cart_items: res?.total_cart_items ?? 69})
            this.progressBarService.hide();
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.errors.join(','), 'Error');
        })
    }

    turnThreadNotificationOff(): void
    {
        if (this.currentUser.email === 'guest@onlinedoc.dk')
        {
            this._matDialog.open(GuestModalComponent, {
                panelClass: 'guest-modal',
                data      : {
                    name: 'test',
                }
            }).afterClosed().subscribe((res) => {

            });
            return;
        }
        this.progressBarService.show();
        const clinicData = {
            'doc_notification': {
                clinic_id   : this.product.clinicId,
                doc_id      : this.product.id,
                notification: false
            }
        };
        this.threadDataService.threadNotificationToggle(clinicData).subscribe((data) => {
            this.toast.success('Product Notifications Off');
            this.progressBarService.hide();
            this.product.docNotification = false;
            this.threadEntity.updateOneInCache({docNotification: false, id: this.product.id});
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.error, 'Error');
        });
    }


    turnThreadNotificationOn(): void
    {
        if (this.currentUser.email === 'guest@onlinedoc.dk')
        {
            this._matDialog.open(GuestModalComponent, {
                panelClass: 'guest-modal',
                data      : {
                    name: 'test',
                }
            }).afterClosed().subscribe((res) => {

            });
            return;
        }
        this.progressBarService.show();
        const clinicData = {
            'doc_notification': {
                clinic_id   : this.product.clinicId,
                doc_id      : this.product.id,
                notification: true
            }
        };
        this.threadDataService.threadNotificationToggle(clinicData).subscribe((data) => {
            this.toast.success('Product Notifications On');
            this.progressBarService.hide();
            this.product.docNotification = true;
            this.threadEntity.updateOneInCache({docNotification: true, id: this.product.id});
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.error, 'Error');
        });
    }
}
