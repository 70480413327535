import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { MatDialog } from '@angular/material/dialog';
import { GroupInviteComponent } from '../../../main/apps/components/dialogs/group-invite-modal/group-invite.component';
import { ActivatedRoute, Event as NavigationEvent, NavigationEnd, Router } from '@angular/router';
import { NewAuthDataService } from '../../../service/api/newAuth-data.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { LocalStorageService } from 'angular-web-storage';
import { NewAuthService } from '../../../service/api/newAuth.service';
import { GroupDialogComponent } from '../../../main/apps/components/dialogs/group/group.component';
import { User } from '../../../types';
import { GroupsEntityService } from '../../../store/group/groups-entity.service';
import {GuestModalComponent} from "../../../main/apps/components/dialogs/guest-modal/guest-modal.component";

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    name: string;
    imageSrc: string;
    // Private
    private _unsubscribeAll: Subject<any>;
    showInviteGroup = false;
    private currentClinic: any;
    private currentUser: User;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param _fuseNavigationService
     * @param {TranslateService} _translateService
     * @param _authService
     * @param _matDialog
     * @param router
     * @param _route
     * @param groupEntity
     * @param newAuthDataService
     * @param local
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseNavigationService: FuseNavigationService,
        private _translateService: TranslateService,
        private _authService: NewAuthService,
        private _matDialog: MatDialog,
        private router: Router,
        private _route: ActivatedRoute,
        private groupEntity: GroupsEntityService,
        private newAuthDataService: NewAuthDataService,
        private local: LocalStorageService
    )
    {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon : 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon : 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon : 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'da',
                title: 'Danish',
                flag : 'da'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this.router.events
            .subscribe(
                (event: NavigationEvent) => {

                    if ( event instanceof NavigationEnd )
                    {
                        if ( event.url.split('/')[1] === 'groups' && event.url.split('/')[2] !== 'all' )
                        {
                            this.showInviteGroup = true;
                            this.groupEntity.entities$.subscribe((data) => {
                                if ( data.length )
                                {
                                    this.currentClinic = data.find((groupData) => +groupData.id === +event.url.split('/')[2] );
                                }

                            });

                        } else
                        {
                            this.showInviteGroup = false;
                        }
                    }
                });
        this.newAuthDataService.getUser().subscribe((user: User) => {
            this.currentUser = user;
            this.name = `${user.first_name} ${user.last_name}`;
            this.imageSrc = user.image;
        });
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
        // @ts-ignore
        const userLang = navigator.language || navigator.userLanguage;
        if (userLang.includes('da') )
        {
            this.setLanguage(this.languages[1]);
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        this.router.navigate(['/search', value.target.value]);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout(): void
    {
        this.newAuthDataService.logout();

        // remove previously added quick access links
        const navList = this._fuseNavigationService.getCurrentNavigation().filter(data => {
            return data.id === 'quick_access';
        });
        if ( navList.length > 0 )
        {
            navList[0].children = [];
        }

    }

    openInviteModal(): void
    {
        this._matDialog.open(GroupInviteComponent, {
            panelClass: 'group-invite',
            data      : {
                name: 'test',
                currentClinic: this.currentClinic,
            }
        }).afterClosed().subscribe((res) => {

        });
    }

    openCreateGroupPopDialog(): void
    {
        this._matDialog.open(GroupDialogComponent, {
            panelClass: 'group-card-dialog',
            data      : {
                name: 'data test'
            }
        });
    }

    openProfile() {
        if (this.currentUser.email === 'guest@onlinedoc.dk')
        {
            this._matDialog.open(GuestModalComponent, {
                panelClass: 'guest-modal',
                data      : {
                    name: 'test',
                }
            }).afterClosed().subscribe((res) => {

            });
            return;
        }
        this.router.navigate(['/profile']);
    }
}
