import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { RestService } from '../../service/api/rest.service';
import { Task } from './task.model';

@Injectable({providedIn: 'root'})
export class TasksEntityService extends EntityCollectionServiceBase<Task>
{
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private rest: RestService)
    {
        super('Task', serviceElementsFactory);
    }

}
