import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ThreadsDataService } from '../../../../../store/thread/threads-data.service';
import { ActionCableService } from '../../../../../service/socket/action-cable.service';
import { Subscription } from 'rxjs';
import { User } from '../../../../../types';
import { NewAuthDataService } from '../../../../../service/api/newAuth-data.service';
import { DocReadEntityService } from '../../../../../store/doc-read/doc-read-entity.service';
import { IDocRead, ReadType } from '../../../../../store/doc-read/doc-read.model';

@Component({
    selector   : 'comment-read',
    templateUrl: './comment-read.component.html',
    styleUrls  : ['./comment-read.component.scss']
})
export class CommentReadComponent implements OnInit, OnDestroy
{

    @Input() id: number;
    @Input() type: ReadType;
    commentReadList:any = [];
    readList: IDocRead[] = [];
    allReadList: IDocRead[] = [];
    commentReadPage = 1;
    selectedComment: any;
    holdingScroll = false;
    entitySubscription: Subscription;
    socketSubscription: Subscription;
    currentUser: User;

    constructor(
        private threadDataService: ThreadsDataService,
        private actionCableService: ActionCableService,
        private authService: NewAuthDataService,
        private docReadEntityService: DocReadEntityService
    )
    {
    }

    ngOnInit(): void
    {
        setTimeout(() => {
            this.entitySubscription = this.docReadEntityService.entities$.subscribe((reads: IDocRead[]) => {
                this.allReadList = reads;
                this.readList = reads.filter((read: IDocRead) => (read.type === this.type && read.itemId === this.id));
            });
        }, 1000);
        this.socketSubscription = this.actionCableService?.getDocReadChannel()?.subscribe((data) => {
            const tempRead: IDocRead = {
                id    : data.doc_read.id,
                itemId: data.doc_read.comment_id ?? data.doc_read.doc_id,
                user  : data.doc_read.user,
                type  : data.doc_read.comment_id ? ReadType.comment : ReadType.doc
            };
            const exists = this.allReadList.find((item) => (item.itemId == tempRead.itemId && tempRead.type == item.type));
            if ( exists )
            {
                this.docReadEntityService.updateOneInCache({...tempRead, id: tempRead.id});
            } else
            {
                this.allReadList.push(tempRead);
                if ( this.allReadList.length === 1 )
                {
                    this.docReadEntityService.addOneToCache(tempRead);
                } else
                {
                    this.docReadEntityService.addAllToCache(this.allReadList);
                }
            }
        });
    }

    getCommentReads(): any
    {
        this.threadDataService.getCommentReadList(this.id, this.commentReadPage, this.type).subscribe((data) => {
            this.commentReadList = data.doc_reads;
        });
    }

    loadMoreReads(): any
    {
        this.threadDataService.getCommentReadList(this.id, ++this.commentReadPage, this.type).subscribe((data) => {
            this.commentReadList = [...this.commentReadList, ...data.doc_reads];
        });
    }

    menuClosed(): any
    {
        this.commentReadList = [];
        this.commentReadPage = 1;
    }

    ngOnDestroy(): void
    {
        this.entitySubscription?.unsubscribe();
        this.socketSubscription?.unsubscribe();
    }
}
