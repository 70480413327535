import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { TypingAnimationComponent } from './typing-animation.component';

@NgModule({
    declarations: [TypingAnimationComponent],
    exports     : [
        TypingAnimationComponent
    ],
    imports: [
        FuseSharedModule,
    ]
})
export class TypingAnimationModule
{
}
