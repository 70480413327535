import { Component, OnInit } from '@angular/core';
import { RestService } from '../../../../service/api/rest.service';
import { Subscription } from 'rxjs';

@Component({
    selector   : 'upload-progress',
    templateUrl: './upload-progress.component.html',
    styleUrls  : ['./upload-progress.component.scss']
})
export class UploadProgressComponent implements OnInit
{
    percentage = 0;
    show = false;
    uploadProgressSubscription: Subscription;
    uploadingText = '';
    titleText = '';

    constructor(private restService: RestService)
    {
    }

    ngOnInit(): void
    {
        this.uploadProgressSubscription = this.restService.getUploadProgressSubject().subscribe((data) => {
            this.titleText = `Uploading ${++data.currentFile} of ${data.totalFiles}`;
            this.uploadingText = data.name.length > 12 ? data.name.slice(0, 12) + '...' : data.name;
            if ( data.progress == 100 )
            {
                this.percentage = data.progress;
                setTimeout(() => {
                    this.percentage = 0;
                    this.show = false;
                }, 3000);
            } else if ( data.progress < 100 )
            {
                this.percentage = data.progress;
                this.show = true;
            }
        });
    }
}
