import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkifyReadMoreComponent } from './linkify-read-more/linkify-read-more.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonPipeModule } from '../../../common-pipe/common-pipe.module';



@NgModule({
  declarations: [LinkifyReadMoreComponent],
    exports     : [
        LinkifyReadMoreComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        CommonPipeModule
    ]
})
export class LinkifyReadMoreModule { }
