import {Injectable} from '@angular/core';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {HttpClient} from '@angular/common/http';
import {ICart} from './cart.model';
import {FuseNavigationService} from "@fuse/components/navigation/navigation.service";
import {RestService} from "../../service/api/rest.service";
import {tap} from "rxjs/operators";

@Injectable()
export class CartDataService extends DefaultDataService<ICart> {

    private baseCart = 'shopping_carts';

    constructor(http: HttpClient,
                httpUrlGenerator: HttpUrlGenerator,
                private rest: RestService,
                private _fuseNavigationService: FuseNavigationService,
    ) {
        super('Cart', http, httpUrlGenerator);
    }

    addToCart(cartId, data): any
    {
        return this.rest.post(`${(this.baseCart)}/${cartId}/shop_sections/add_to_cart`, data).pipe(tap(data => {
            return data;
        }));
    }

    updateProductQuantity(cartId, shopId, params): any
    {
        return this.rest.put(`${(this.baseCart)}/${cartId}/shop_sections/${shopId}`, params).pipe(tap(data => {
            return data;
        }));
    }

    deleteProduct(cartId, shopId, params): any
    {
        return this.rest.delete(`${(this.baseCart)}/${cartId}/shop_sections/${shopId}`, params).pipe(tap((data) => {
            return data;
        }));
    }
    getCartProducts(cartId): any
    {
        return this.rest.get(`${(this.baseCart)}/${cartId}/shop_sections`).pipe(tap(data => {
            return data;
        }));
    }

    getCartCount(cartId): any {
        this.rest.get(`${(this.baseCart)}/${cartId}/shop_sections/cart_products_count`).subscribe((data) => {
            this.updateCartCount(data);
        });
    }

    updateCartCount(data: any): any {
        if (data.total_cart_items === 0) {
            const item =
                {
                    badge: null
                };
            this._fuseNavigationService.updateNavigationItem('checkout', item);

        } else {
            setTimeout(() => {
                const nullItem =
                    {
                        badge: null
                    };
                this._fuseNavigationService.updateNavigationItem('checkout', nullItem);
            }, 200);
            setTimeout(() => {
                const item =
                    {
                        badge: {
                            title: data.total_cart_items,
                            bg: '#92254e',
                            fg: '#FFFFFF'
                        }
                    };
                this._fuseNavigationService.updateNavigationItem('checkout', item);
            }, 200);

        }
    }
}
