import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    DeleteConfirmationDialogComponent
} from '../../user-setting/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { fuseAnimations } from '@fuse/animations';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import { GroupsDataService } from '../../../../../../../../store/group/groups-data.service';
import { GroupsEntityService } from '../../../../../../../../store/group/groups-entity.service';
import { ActivatedRoute } from '@angular/router';
import { ThreadsDataService } from '../../../../../../../../store/thread/threads-data.service';
import { ThreadsEntityService } from '../../../../../../../../store/thread/threads-entity.service';
import { IGroup } from '../../../../../../../../store/group/group.model';
import { fromEvent, Subject } from 'rxjs';
import {
    FusePerfectScrollbarDirective
} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseConfigService } from '@fuse/services/config.service';
import { debounceTime, distinctUntilChanged, finalize, map, takeUntil } from 'rxjs/operators';

@Component({
    selector     : 'update-category-dialog',
    templateUrl  : './update-category-dialog.component.html',
    styleUrls    : ['./update-category-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class UpdateCategoryDialogComponent implements OnInit, AfterViewInit, OnDestroy
{
    categoryUpdateForm: FormGroup;
    private _unsubscribeAll: Subject<any>;
    currentGroup: any;
    currentClinic: IGroup;
    create: boolean;
    categories: any[];
    searchText = '';
    private inputValue: string;
    isLoading: boolean;
    isStateChanged = false;
    page: number;
    @ViewChild('searchFoldersElement', {static: false}) searchFoldersElement: ElementRef;
    scrollMore = false;
    resetSearchText = false;
    @ViewChild(FusePerfectScrollbarDirective)
    directiveScroll: FusePerfectScrollbarDirective;
    constructor(public matDialogRef: MatDialogRef<UpdateCategoryDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private _data: any,
                private _matDialog: MatDialog,
                private _formBuilder: FormBuilder,
                private _fuseConfigService: FuseConfigService,
                private progressBarService: FuseProgressBarService,
                private toast: ToastrService,
                private groupDataService: GroupsDataService,
                private groupEntity: GroupsEntityService,
                private _route: ActivatedRoute,
                private threadsDataService: ThreadsDataService,
                private threadsEntityService: ThreadsEntityService,
                private toastrService: ToastrService,
                )
    {
        this._unsubscribeAll = new Subject();
        this.page = 1;
        this.categories = [];
        this.onScrollDown();
    }

    ngOnInit(): void
    {
        this.create = this._data.create;
        this.inputValue = '';
        if ( this._data.item )
        {
            this.currentGroup = this._data.item;
            this.inputValue = this.currentGroup.categoryName;
        }
        this.currentClinic = this._data.currentClinic;
        this.categoryUpdateForm = this._formBuilder.group({
            name: [{value: this.inputValue, disabled: false}, Validators.required],
        });
    }

    ngAfterViewInit(): void
    {
        fromEvent(this.searchFoldersElement.nativeElement, 'keyup').pipe(
            map((event: any) => {
                return event.target.value;
            })
            , debounceTime(250)
            , distinctUntilChanged()
        ).subscribe((text: string) => {
            this.searchFolders();
        });
    }

    ngOnDestroy(): void
    {

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    openDeleteModal(category): void
    {
        this.currentGroup = category;
        this._matDialog.open(DeleteConfirmationDialogComponent, {
            panelClass: 'deleteDialog',
            data      : {
                title  : 'Remove ' + this.currentGroup.categoryName,
                message: 'If you delete a category all content will be deleted and can NOT be restored. Alternative you' +
                    ' can deactivate the folder by unchecking it in the list.'
            }
        }).afterClosed().subscribe((res) => {
            if ( res[0] === 'yes' )
            {
                this.isStateChanged = true;
                this.progressBarService.show();
                this.groupDataService.removeGroupCategory(this.currentGroup.id).subscribe((data) => {
                    this.toast.success('Your Category is deleted Successfully');
                    this.page = 1;
                    this.onScrollDown();
                    this.directiveScroll.scrollToTop(0);
                    this.progressBarService.hide();
                    const oldObj: IGroup| any = this.currentClinic.categories.find((obj, index) => {
                            return +obj.id === +this.currentGroup.id;
                        }
                    );
                    const tempIndex = this.currentClinic.categories.indexOf(oldObj);
                    this.currentClinic.categories.splice(tempIndex, 1);
                    this.groupEntity.updateOneInCache({
                        categories: this.currentClinic.categories,
                        id        : this.currentClinic.id
                    });
                    // this.matDialogRef.close('Name Updated');
                }, (error) => {
                    this.progressBarService.hide();
                    this.toast.error(error.errors.join(', '), 'Error');
                });
            }

        });
    }
    modalChange(event): void
    {
        this.resetSearchText = event.length >= 1;
    }
    onClickCloseResetForm(): void
    {
        this.resetSearchText = false;
        this.searchText = '';
        this.categories = [];
        this.page = 0;
        this.onScrollDown();
    }
    searchFolders(): void
    {
        this.progressBarService.show();
        this.groupDataService.getCategories(this._data.currentClinic.id, {
            page  : 1,
            limit : 20,
            search: this.searchText
        }).pipe(takeUntil(this._unsubscribeAll), finalize(() => {
            this.progressBarService.hide();
        })).subscribe((res) => {
            this.categories = [];
            this.categories = res;
        }, (error) => {
            this.toastrService.error(error.errors.join(', '), 'Error');
        });
    }
    onScrollDown(): void
    {
        this.progressBarService.show();
        this.isLoading = true;
        const params: any = {
            page : this.page++,
            limit: 7,
        };
        this.groupDataService.getCategories(this._data.currentClinic.id, params).subscribe((res) => {
            this.scrollMore = res.length === 0;
            this.isLoading = false;
            if ( this.page === 2 )
            {
                this.categories = [];
            }
            this.categories = [...this.categories, ...res];
            this.progressBarService.hide();
        });
    }

    toggleCreate(): void {
        this.create = true;
        this.categoryUpdateForm.patchValue({
            name: '',
        });
    }

    hideCategory(show: boolean , category): void {
        this.currentGroup = category;
        const clinicData = {
            is_hidden: show,
        };
        this.progressBarService.show();

        this.groupDataService.updateGroupCategory(clinicData, this.currentGroup.id).subscribe((data) => {
            this.toast.success('', 'Category Updated');
            this.progressBarService.hide();
            const oldObj: IGroup | any = this.currentClinic.categories.find((obj, index) => {
                    return +obj.id === +data.id;
                }
            );
            const tempIndex = this.currentClinic.categories.indexOf(oldObj);
            this.currentClinic.categories[tempIndex] = data;
            this.groupEntity.updateOneInCache({
                categories: this.currentClinic.categories,
                id        : this.currentClinic.id
            });
            this.page = 1;
            this.onScrollDown();
            this.create = true;
            this.categoryUpdateForm.reset();
            const threadData = {
                'clinic_filter': {
                    'clinic_ids'  : [this.currentClinic.id],
                    'category_ids': [],
                    'page'        : 1,
                    'limit'       : 10
                }
            };
            this.threadsEntityService.clearCache();
            this.threadsDataService.getGroupThreads(threadData).subscribe((res) => {
                this.threadsEntityService.addAllToCache(res);
            });
            // this.matDialogRef.close('Name Updated');
        }, (error) => {
            this.progressBarService.hide();
            if ( error.errors )
            {
                this.toast.error(error.errors.join(', '), 'Error');
            }
            if ( error.name )
            {
                this.toast.error(error.name.join(', '), 'Name');
            }
        });
    }
    updateCategory(category): void {
        this.create = false;
        this.inputValue = category.categoryName;
        this.currentGroup = category;
        this.categoryUpdateForm.patchValue({
            name: this.inputValue,
        });

        this.directiveScroll.scrollToTop(0);
    }

    categoryUpdate(): void
    {

        if ( this.categoryUpdateForm.valid )
        {
            if ( this.create )
            {
                const clinicData: any = {
                    clinic_category: {
                        clinic_id : this.currentClinic.id || this.currentClinic,
                        name      : this.categoryUpdateForm.get('name')?.value,
                        sort_order: 0
                    }
                };
                if ( this.currentClinic?.categories?.length )
                {
                    clinicData.clinic_category.sort_order = this.currentClinic.categories.length;
                } else
                {
                    delete clinicData.clinic_category.sort_order;
                }
                this.progressBarService.show();
                this.groupDataService.createGroupCategory(clinicData).subscribe((data) => {
                    this.isStateChanged = true ;
                    this.toast.success('', 'Category Created');
                    this.progressBarService.hide();
                    this.page = 1;
                    this.onScrollDown();
                    this.currentClinic?.categories?.push(data);
                    if ( this.currentClinic?.categories )
                    {
                        this.groupEntity.updateOneInCache({
                            categories: this.currentClinic.categories,
                            id        : this.currentClinic.id
                        });
                    }
                    this.categoryUpdateForm.reset();
                }, (error) => {
                    this.progressBarService.hide();
                    if ( error.errors )
                    {
                        this.toast.error(error.errors.join(', '), 'Error');
                    }
                    if ( error.name )
                    {
                        this.toast.error(error.name.join(', '), 'Name');
                    }
                });
            } else
            {
                const clinicData = {
                    name: this.categoryUpdateForm.get('name')?.value
                };
                this.progressBarService.show();
                this.groupDataService.updateGroupCategory(clinicData, this.currentGroup.id).subscribe((data) => {
                    this.isStateChanged = true;
                    this.toast.success('', 'Category Updated');
                    this.progressBarService.hide();
                    const oldObj: IGroup | any = this.currentClinic.categories.find((obj, index) => {
                            return +obj.id === +data.id;
                        }
                    );
                    const tempIndex = this.currentClinic.categories.indexOf(oldObj);
                    this.currentClinic.categories[tempIndex] = data;
                    this.groupEntity.updateOneInCache({
                        categories: this.currentClinic.categories,
                        id        : this.currentClinic.id
                    });
                    this.page = 1;
                    this.onScrollDown();
                    this.create = true;
                    this.categoryUpdateForm.reset();
                    const threadData = {
                        'clinic_filter': {
                            'clinic_ids'  : [this.currentClinic.id],
                            'category_ids': [],
                            'page'        : 1,
                            'limit'       : 10
                        }
                    };
                    this.threadsEntityService.clearCache();
                    this.threadsDataService.getGroupThreads(threadData).subscribe((res) => {
                        this.threadsEntityService.addAllToCache(res);
                    });
                    // this.matDialogRef.close('Name Updated');
                }, (error) => {
                    this.progressBarService.hide();
                    if ( error.errors )
                    {
                        this.toast.error(error.errors.join(', '), 'Error');
                    }
                    if ( error.name )
                    {
                        this.toast.error(error.name.join(', '), 'Name');
                    }
                });
            }
        }
    }
}
