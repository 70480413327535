<div class="navbar-top" >
<!--    <div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">-->
<!--    <div class="logo">-->
<!--        <img class="logo-icon icon-size" src="assets/images/logos/Webshop-logo.png">-->
<!--        <span class="logo-text secondary-text logo-text-size">WebshopHerning</span>-->
<!--    </div>-->
    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>
    <div>
                <button mat-button fxHide fxShow.gt-xs
                        class="language-button"
                        [matMenuTriggerFor]="languageMenu">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage?.flag+'.png'">
                        <span class="iso text-uppercase">{{selectedLanguage?.id}}</span>
                    </div>
                </button>

                <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                    <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                        <span fxLayout="row" fxLayoutAlign="start center">
                            <img class="flag mr-16" [src]="'assets/icons/flags/'+lang?.flag+'.png'">
                            <span class="iso">{{lang?.title}}</span>
                        </span>
                    </button>

                </mat-menu>
    </div>



</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar  (scrolled)="onScrollDown()"[infiniteScrollDisabled]="false"
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="0"
     [scrollWindow]="false" infinite-scroll [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user" fxLayout="column" >

        <div class="h3 username">{{name}}</div>
        <div class="h5 email hint-text mt-8 email-color">{{email}}</div>
        <div class="avatar-container user-profile-border">
            <img class="avatar cursor-pointer" [src]=imageSrc  [mat-menu-trigger-for]="userMenu">
        </div>
        <mat-menu #userMenu="matMenu" xPosition="after" [overlapTrigger]="false">
            <button mat-menu-item (click)="openProfile()">
                <mat-icon>account_circle</mat-icon>
                <span>{{'TOOLBAR.my_profile' | translate}}</span>
            </button>


            <button mat-menu-item class="" (click)="logout()" routerLink="/auth/login">
                <mat-icon>exit_to_app</mat-icon>
                <span>{{'TOOLBAR.logout' | translate}}</span>
            </button>

        </mat-menu>

    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>
<div class="newGroupContainer" *ngIf="sidebarOpened">
    <div class="newContainerChild" [routerLink]="'/groups/all'">
        <mat-icon class="mt-8 icon-size">search</mat-icon>
    <button>
        {{'NAV.NEWGROUP.FINDGROUP'| translate}}
    </button>
    </div>
    <div class="newContainerChild" (click)="openDialog()" >
        <mat-icon class=" mt-8 icon-size">add</mat-icon>
    <button>
        {{'NAV.NEWGROUP.ADDGROUP' | translate}}
    </button>
    </div>
</div>
