import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector   : 'app-linkify-read-more',
    templateUrl: './linkify-read-more.component.html',
    styleUrls  : ['./linkify-read-more.component.scss']
})
export class LinkifyReadMoreComponent
{
    @Input() limit: any;
    @Input() set content(content)
    {
        if ( content )
        {
            this.linkifyContent = content;
            this.nonEditedContent = content;
            if ( content?.length > this.limit )
            {
                this.show = true;
                this.linkifyContent = this.formatContent(this.linkifyContent);
            } else
            {
                this.linkifyContent = this.nonEditedContent;
            }
        }
    }
    isContentToggled = false;
    nonEditedContent: any;
    linkifyContent: any;
    show = false;

    constructor()
    {
    }

    toggleContent(): void
    {
        this.isContentToggled = !this.isContentToggled;
        this.linkifyContent = this.isContentToggled ? this.nonEditedContent : this.formatContent(this.linkifyContent);
    }

    formatContent(content: string): any
    {
        return this.linkifyContent = content.slice(0, this.limit);
    }

}
