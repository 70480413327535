import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import {
    FusePerfectScrollbarDirective
} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { NewAuthDataService } from '../../../../../service/api/newAuth-data.service';
import { NewAuthService } from '../../../../../service/api/newAuth.service';
import { User } from '../../../../../types';
import { GroupDialogComponent } from '../../../../../main/apps/components/dialogs/group/group.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import {GuestModalComponent} from "../../../../../main/apps/components/dialogs/guest-modal/guest-modal.component";

@Component({
    selector     : 'navbar-vertical-style-1',
    templateUrl  : './style-1.component.html',
    styleUrls    : ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;
    name: string;
    email: string;
    imageSrc: string;
    sidebarOpened = true;
    page = 1;
    languages: any;
    selectedLanguage: any;
    private currentUser: User;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param _authService
     * @param {Router} _router
     * @param newAuthDataService
     * @param _matDialog
     * @param _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _authService: NewAuthService,
        private _router: Router,
        private newAuthDataService: NewAuthDataService,
        private _matDialog: MatDialog,
        private _translateService: TranslateService,

    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'da',
                title: 'Danish',
                flag : 'da'
            }
        ];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, {static: true})
    set directive(theDirective: FusePerfectScrollbarDirective)
    {
        if ( !theDirective )
        {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                    setTimeout(() => {
                        this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
                    });
                }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if ( this._fuseSidebarService.getSidebar('navbar') )
                    {
                        this._fuseSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });
        this.newAuthDataService.getUser().subscribe((user: User) => {
            this.currentUser = user;
            this.name = `${user.first_name} ${user.last_name}`;
            this.email = user.email;
            this.imageSrc = user.image;
        });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
        // @ts-ignore
        const userLang = navigator.language || navigator.userLanguage;
        if (userLang.includes('da') )
        {
            this.setLanguage(this.languages[1]);
        }
    }

    logout(): void
    {
        this.newAuthDataService.logout();

        // remove previously added quick access links
        const navList = this._fuseNavigationService.getCurrentNavigation().filter(data => {
            return data.id === 'quick_access';
        });
        if ( navList.length > 0 )
        {
            navList[0].children = [];
        }

    }
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    openDialog(): void
    {
        if (this.currentUser.email === 'guest@onlinedoc.dk')
        {
            this._matDialog.open(GuestModalComponent, {
                panelClass: 'guest-modal',
                data      : {
                    name: 'test',
                }
            }).afterClosed().subscribe((res) => {

            });
            return;
        }
        this._matDialog.open(GroupDialogComponent, {
            panelClass: 'group-card-dialog',
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void
    {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void
    {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
        this.sidebarOpened = !this.sidebarOpened;

    }

    openGroupCreateModal(): void{
        this._matDialog.open(GroupDialogComponent, {
            panelClass: 'group-card-dialog',
        });
    }

    onScrollDown(): void {
        const page = ++this.page;
        this.newAuthDataService.getApprovedGroups(page, false).subscribe();
    }

    openProfile() {
        if (this.currentUser.email === 'guest@onlinedoc.dk')
        {
            this._matDialog.open(GuestModalComponent, {
                panelClass: 'guest-modal',
                data      : {
                    name: 'test',
                }
            }).afterClosed().subscribe((res) => {

            });
            return;
        }
        this._router.navigate(['/profile']);
    }
}
