import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { IDocRead } from './doc-read.model';

@Injectable({providedIn: 'root'})
export class DocReadEntityService extends EntityCollectionServiceBase<IDocRead>
{
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory)
    {
        super('DocRead', serviceElementsFactory);
    }
}
