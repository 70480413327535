import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { fuseAnimations } from '@fuse/animations';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ThreadDialogComponent } from '../../components/dialogs/thread/thread.component';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { NewAuthDataService } from '../../../../service/api/newAuth-data.service';
import { NotificationDataService } from '../../../../store/notifications/notification-data.service';
import { ThreadsDataService } from '../../../../store/thread/threads-data.service';
import { ThreadsEntityService } from '../../../../store/thread/threads-entity.service';

import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../../types';
import { GroupsDataService } from '../../../../store/group/groups-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector     : 'app-main-dashboard',
    templateUrl  : './main-dashboard.component.html',
    styleUrls    : ['./main-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class MainDashboardComponent implements OnInit, OnDestroy, AfterViewInit
{
    name: string;
    routeQueryParams$: Subscription;
    private _unsubscribeAll: Subject<any>;
    page = 1;
    selectedLanguage: any;
    holdingScroll = false;
    showTasks = true;
    searchText: string;
    resetSearchText = false;
    @ViewChild('searchProductElement', {static: false}) searchProductElement: ElementRef;
    email: string | undefined;
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _authService: NewAuthDataService,
        private store: Store<any>,
        private _matDialog: MatDialog,
        private route: ActivatedRoute,
        private _fuseNavigationService: FuseNavigationService,
        private notificationDataService: NotificationDataService,
        private threadDataService: ThreadsDataService,
        private threadsEntityService: ThreadsEntityService,
        private _translateService: TranslateService,
        private groupDataService: GroupsDataService,
        private toast: ToastrService,
        // public matDialogRef: MatDialogRef<GroupDialogComponent>
    )
    {
        this._unsubscribeAll = new Subject();
        this.routeQueryParams$ = route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(params => {

            if ( params['dialog'] === 'group' )
            {
                // this.openPopDialog();
            } else if ( params['dialog'] === 'thread' )
            {
                this.openThreadDialog();
            }
        });


    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    getSearchText(text): void
    {

        this.searchText = text;
        if ( text === '' )
        {
            this.page = 1;
        }
    }
    openThreadDialog(): void
    {
        this._matDialog.open(ThreadDialogComponent, {
            panelClass: 'card-dialog',
            data      : {
                new: true
            }
        });
    }

    /**
     * After ViewInit
     */
    ngAfterViewInit(): void
    {
        fromEvent(this.searchProductElement.nativeElement, 'keyup').pipe(
            map((event: any) => {
                return event.target.value;
            })
            , debounceTime(250)
            , distinctUntilChanged()
        ).subscribe((text: string) => {
            if ( this.searchText )
            {
                this.page = 0;
                this.searchProducts();
            }

        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onScrollDown(): void
    {
        if ( !this.searchText )
        {
            const taskData = {
                'clinic_filter': {
                    'clinic_ids'  : [],
                    'category_ids': [],
                    'page'        : ++this.page,
                    'limit'       : 12
                }
            };

            this.threadDataService.getDashboardThreads(taskData).subscribe((data) => {
                data.length < 12 ? this.holdingScroll = true : this.holdingScroll = false;
                this.threadsEntityService.addManyToCache(data);
            });
        }
        else{

            this.searchProducts(true);
        }
    }
    modalChange(event): void
    {
        this.resetSearchText = event.length >= 1;
        if ( !event )
        {
            this.page = 0;
            this.threadsEntityService.clearCache();
            this.onScrollDown();
        }
    }
    searchProducts(scroll= false): void
    {

        const threadData = {

            'page'        : ++this.page,
            'limit'       : 12,
            'search'      : this.searchText,
        };

        this.groupDataService.getClinicSearch(threadData).subscribe((data) => {
            data.length < 12 ? this.holdingScroll = true : this.holdingScroll = false;
            if (scroll)
            {
                this.threadsEntityService.addManyToCache(data);
            }else{
                this.threadsEntityService.clearCache();
                this.threadsEntityService.addManyToCache(data);
            }
        }, (error) => {
            this.toast.error(error.errors.join(','), 'Error');
        });

    }
    onClickCloseResetForm(): void
    {
        this.resetSearchText = false;
        this.searchText = '';
        this.page = 0;
        this.onScrollDown();
        this.threadsEntityService.clearCache();
    }
    ngOnInit(): void
    {
        this._authService.getUser()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.name = user.first_name;
                this.email = user?.email;
            });

    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    hideTasks($event): void
    {
        this.showTasks = !$event;
    }
    logout(): void
    {
        this._authService.logout();

        // remove previously added quick access links
        const navList = this._fuseNavigationService.getCurrentNavigation().filter(data => {
            return data.id === 'quick_access';
        });
        if ( navList.length > 0 )
        {
            navList[0].children = [];
        }

    }
}
