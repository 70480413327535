import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import * as moment from 'moment';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import { NewAuthDataService } from '../../../../../service/api/newAuth-data.service';
import { TasksEntityService } from '../../../../../store/task/task-entity.service';
import { TaskDataService } from '../../../../../store/task/task-data.service';
import { TaskComponent } from '../../../components/dialogs/task/task.component';
import { MatDialog } from '@angular/material/dialog';
import { FriendDataService } from '../../../../../store/friend/friend-data.service';
import { User } from '../../../../../types';
import { IDropDown } from '../../../../../types/Dropdown';
import { Task } from '../../../../../store/task/task.model';


@Component({
    selector     : 'dashboard-tasks',
    templateUrl  : './dashboard-tasks.component.html',
    styleUrls    : ['./dashboard-tasks.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class DashboardTasksComponent implements OnInit
{
    currentUser: User;
    tasks: any;
    selectedValue = '';
    taskDates: IDropDown[] = [
        {value: '', viewValue: 'All'},
        {value: '3', viewValue: 'Today and Past'},
        {value: '2', viewValue: 'Today Only'},
        {value: '1', viewValue: 'Today and Forward'}
    ];
    @Output() hideTasks = new EventEmitter<any>();

    constructor(private progressBarService: FuseProgressBarService,
                private toast: ToastrService,
                private taskEntity: TasksEntityService,
                private taskDataService: TaskDataService,
                private authDataService: NewAuthDataService,
                private _matDialog: MatDialog,
    )
    {
    }

    ngOnInit(): void
    {
        this.authDataService.getUser().subscribe((user: User) => {
            this.currentUser = user;
        });
        this.progressBarService.show();
        const userData = {
            'clinic_filter': {
                preference: '',
                page      : 1,
                limit     : 10
            }
        };
        this.taskEntity.clearCache();
        this.taskDataService.getAllTasks(userData).subscribe((data: Task[]) => {
                this.sortedTasks(data);
                this.progressBarService.hide();
                if (data.length === 0)
                {
                    this.hideTasks.emit(true);
                }
                this.taskEntity.addManyToCache(data);
                this.taskEntity.entities$.subscribe((dataList) => {
                        this.tasks = dataList;
                });
            },
            (error) => {
                this.progressBarService.hide();
                this.toast.error(error.error, 'Error');
            });
    }

    sortedTasks(allTasks): any
    {
        return allTasks.sort((a, b) => {
            return moment(a.taskStart).valueOf() - moment(b.taskStart).valueOf();
        });
    }

    changeDate(event: any): any
    {
        const userData = {
            'clinic_filter': {
                preference: 1,
                page      : 1,
                limit     : 10
            }
        };
        switch (event.value)
        {
            case '1':
                userData.clinic_filter.preference = 1;
                break;
            case '2':
                userData.clinic_filter.preference = 2;
                break;
            case '3':
                userData.clinic_filter.preference = 3;
                break;
            default:
                userData.clinic_filter.preference = event.value;
        }
        this.progressBarService.show();

        this.taskDataService.getAllTasks(userData).subscribe((data) => {
                this.progressBarService.hide();
                this.sortedTasks(data);
                this.taskEntity.addAllToCache(data);
                this.taskEntity.entities$.subscribe((dataList) => {
                    this.tasks = dataList;
                });
            },
            (error) => {
                this.progressBarService.hide();
                this.toast.error(error.error, 'Error');
            });
    }

    openPopTaskDialog(): void
    {
        // this.store.dispatch(new groupActions.InitializeCurrentGroup());
        this._matDialog.open(TaskComponent, {
            panelClass: 'task-card-dialog',
            data      : {
                new: true
            }
        });
    }
}
