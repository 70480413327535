<form fxLayout="column" [formGroup]="basicInformationForm" (ngSubmit)="submit()">

    <div fxFlex fxLayout="column">

        <div fxLayout="row" fxLayoutAlign="start start">

            <mat-form-field (click)="datePicker.open()" appearance="outline" fxFlex>
                <mat-label>{{'CHAT.date_of_birth' | translate}}</mat-label>
                <input [max]="maxDate" name="dateOfBirth" formControlName="dateOfBirth"
                       matInput [matDatepicker]="datePicker" readonly>
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
                <mat-error>{{'CHAT.date_of_birth' | translate}} {{'SLIDE.is_required' | translate}}!</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'CHAT.gender' | translate}}</mat-label>
                <mat-select name="gender" formControlName="gender">
                    <mat-option *ngFor="let text of genders" [value]="text.value">
                        {{text.viewValue}}
                    </mat-option>
                </mat-select>
<!--                <mat-error>{{'CHAT.gender' | translate}} {{'SLIDE.is_required' | translate}}!</mat-error>-->
            </mat-form-field>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">

        <button mat-raised-button class="mr-8" (click)="cancelEvent.emit(false)">
            {{'TOOLBAR.cancel' | translate}}
        </button>
        <button mat-raised-button type="submit" color="accent">
            {{'TOOLBAR.update' | translate}}
        </button>
    </div>

</form>
