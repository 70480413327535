import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseSharedModule } from '@fuse/shared.module';

import { ChatPanelComponent } from 'app/layout/components/chat-panel/chat-panel.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GalleryModule } from '../../../main/apps/components/gallery/gallery.module';
import { AttachmentPreviewModule } from '../../../main/apps/components/dialogs/attachment/attachment-preview-component/attachment-preview.module';
import { AttachmentsModule } from '../../../main/apps/components/attachments/attachments.module';
import { MatMenuModule } from '@angular/material/menu';
import { CommonDirectiveModule } from '../../../directive/common-directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { LinkifyReadMoreModule } from '../linkify-read-more/linkify-read-more.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TypingAnimationModule } from '../../../main/apps/components/typing-animation/typing-animation.module';
import { RichTextFieldModule } from '../../../main/apps/components/rich-text-field/rich-text-field.module';

@NgModule({
    declarations: [
        ChatPanelComponent
    ],
    providers   : [],
    imports: [
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        MatTooltipModule,
        MatRippleModule,

        FuseSharedModule,
        InfiniteScrollModule,
        GalleryModule,
        AttachmentPreviewModule,
        AttachmentsModule,
        MatMenuModule,
        CommonDirectiveModule,
        TranslateModule,
        LinkifyReadMoreModule,
        MatDialogModule,
        MatToolbarModule,
        MatCheckboxModule,
        TypingAnimationModule,
        RichTextFieldModule
    ],
    exports     : [
        ChatPanelComponent
    ]
})
export class ChatPanelModule
{
}
