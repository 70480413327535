<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{'Requires login' | translate}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div mat-dialog-content class="overflowContent">
        <div class="h1  mb-20">{{'To perform this action, you need to ' | translate}} <a class="cursor-pointer" (click)="openLogin()" href="#">{{'Login Modal' | translate}}</a> <mat-icon>warning</mat-icon></div>
    </div>
</div>
