import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// import Swiper core and required modules
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import { Thread } from '../../../../../../store/thread/thread.model';
import { FooterType } from '../../../../../../types';

// install Swiper modules
SwiperCore.use([Navigation, Thumbs]);

@Component({
    selector     : 'store-list-view',
    templateUrl  : './store-list-view.component.html',
    styleUrls    : ['./store-list-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StoreListViewComponent implements OnInit
{
    thumbsSwiper: any;
    swiperDirection = 'vertical';
    showFooterType: FooterType = FooterType.Comment;
    @Input() product: Thread | any;
    pFiles: any[];
    @Input() showEdit?: boolean;
    isModal = false;
    constructor()
    {
    }

    ngOnInit(): void
    {

    }

}
