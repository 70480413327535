import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreListViewComponent } from './store-list-view.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { SingleProductModule } from '../../../../components/single-product/single-product.module';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { SwiperModule } from 'swiper/angular';
import { StoreGridViewComponent } from '../store-grid-view/store-grid-view.component';
import { PostModule } from '../../../../components/post/post.module';
import { ProductDetailModule } from '../../../../components/dialogs/product-detail-modal/product-detail.module';
import { ProductCreationModule } from '../../../../components/dialogs/product-creation-modal/product-creation.module';



@NgModule({
  declarations: [ StoreListViewComponent],
  imports: [
    CommonModule,
      FuseSharedModule,
      MatButtonModule,
      TranslateModule,
      SingleProductModule,
      MatIconModule,
      MatRippleModule,
      MatProgressSpinnerModule,
      MatInputModule,
      MatTooltipModule,
      MatCardModule,
      MatMenuModule,
      SwiperModule,
      PostModule,
      ProductDetailModule,
  ],
    exports: [
        StoreListViewComponent
    ]
})
export class StoreListViewModule { }
