import { IReadUser } from '../thread/thread.model';

export interface IDocRead
{
    id: number;
    itemId: number;
    user: IReadUser;
    type: ReadType;
}

export enum ReadType
{
    comment = 'comment',
    doc = 'doc'
}
