import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { IUploadingFiles } from './uploading-files.model';

@Injectable()
export class UploadingFilesDataService extends DefaultDataService<IUploadingFiles>
{
    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator)
    {
        super('UploadingFiles', http, httpUrlGenerator);
    }
}
