import { Component, Input } from '@angular/core';

@Component({
    selector   : 'profile-image',
    templateUrl: './profile-image.component.html',
    styleUrls  : ['./profile-image.component.scss']
})
export class ProfileImageComponent
{
    @Input() src: any;
    @Input() class: any;
    defaultImage = 'assets/images/avatars/profile.jpg';

    constructor()
    {
    }

}
