import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector     : 'confirmation-dialog',
    templateUrl  : './confirmation-dialog.component.html',
    styleUrls    : ['./confirmation-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmationDialogComponent implements OnInit
{
    title = '';
    message = '';

    constructor(public matDialogRef: MatDialogRef<ConfirmationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private _data: any,
                private _matDialog: MatDialog)
    {
    }

    ngOnInit(): void
    {
        this.title = this._data.title;
        this.message = this._data.message;
    }
}
