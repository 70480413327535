import { NgModule } from '@angular/core';
import { CommentsComponent } from './comments.component';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MomentModule } from 'ngx-moment';
import { AttachmentsModule } from '../attachments/attachments.module';
import { GalleryModule } from '../gallery/gallery.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UtilModule } from '../util/util.module';
import { CommentReplyComponent } from './comment-reply/comment-reply.component';
import { CommonDirectiveModule } from '../../../../directive/common-directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { LinkifyReadMoreModule } from '../../../../layout/components/linkify-read-more/linkify-read-more.module';
import { CommentReadComponent } from './comment-read/comment-read.component';
import { MatBadgeModule } from '@angular/material/badge';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RichTextFieldModule } from '../rich-text-field/rich-text-field.module';


@NgModule({
    declarations: [CommentsComponent, CommentReplyComponent, CommentReadComponent],
    exports     : [
        CommentsComponent,
        CommentReadComponent
    ],
    imports: [
        FuseSharedModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatInputModule,
        MatTooltipModule,
        MomentModule,
        AttachmentsModule,
        GalleryModule,
        MatProgressBarModule,
        UtilModule,
        CommonDirectiveModule,
        TranslateModule,
        LinkifyReadMoreModule,
        MatBadgeModule,
        InfiniteScrollModule,
        RichTextFieldModule
    ]
})
export class CommentsModule
{
}
