import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { RestService } from '../../service/api/rest.service';
import { Notification } from './notification.model';

@Injectable({providedIn: 'root'})
export class NotificationEntityService extends EntityCollectionServiceBase<Notification>
{
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private rest: RestService)
    {
        super('Notification', serviceElementsFactory);
    }

}
