import { Injectable } from '@angular/core';
import { LocalStorage, SessionStorage } from 'angular-web-storage';
import { environment } from 'environments/environment';
import * as ActionCable from 'actioncable';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ActionCableService
{

    @LocalStorage('token')
    userToken;
    @SessionStorage('token')
    sessionToken;
    private statusSubject = new Subject<any>();
    private cable: ActionCable.Cable| null;
    private appChannelSet: boolean | null;
    notificationSubject = new Subject<any>();
    private clinicUpdate = new Subject<any>();
    private appearanceUpdate = new Subject<any>();
    private patientCallerUpdate = new Subject<any>();
    private slideUpdate = new Subject<any>();
    private threadUpdate = new Subject<any>();
    private docReadUpdate = new Subject<any>();
    private appearanceChannel: ActionCable.Channel & { received: (obj: any) => void }| any;
    private notificationChannel: ActionCable.Channel & { received: (obj: any) => void }| any;
    private clinicChannel: ActionCable.Channel & { received: (obj: any) => void } | any;
    private patientCallerChannel: ActionCable.Channel & { received: (obj: any) => void } | any;
    private slideCallerChannel: ActionCable.Channel & { received: (obj: any) => void } | any;
    private threadChannel: ActionCable.Channel & { received: (obj: any) => void } | any;
    private commentsReadChannel: ActionCable.Channel & { received: (obj: any) => void } | any;

    constructor()
    {

    }

    private getCable(verifyToken = false): ActionCable.Cable
    {
        if ( this.cable )
        {
            return this.cable;
        }
        if ( this.userToken || this.sessionToken || verifyToken )
        {
            let cableToken = '';
            if (this.userToken)
            {
                cableToken = `?token=${this.userToken}`;
            }else if (this.sessionToken)
            {
                cableToken = `?token=${this.sessionToken}`;
            }
            this.appearanceChannel = this.notificationChannel = this.clinicChannel = null;
            this.cable = ActionCable.createConsumer(`${environment.BASE_WS_URL}${cableToken}`);
            return this.cable;
        } else
        {
            throw new Error('User is not sign in');
        }
    }

    reset(): void
    {
        this.appearanceChannel?.unsubscribe();
        this.notificationChannel?.unsubscribe();
        this.clinicChannel?.unsubscribe();
        this.cable?.disconnect();
        this.cable = null;
    }

    getChatUpdates(roomID): any
    {
        const subject = new Subject();
        const chatChannel = this.getCable().subscriptions.create({
                channel: 'ChatChannel',
                room_id: roomID
            },
            {
                connected(): any
                {
                },
                disconnected(): any
                {
                },
                received: (obj: any): any => {
                    subject.next(obj);
                }
            }
        );
        return {observer: subject.asObservable(), chatChannel};
    }

    getNotificationsUpdate(): Observable<any>
    {
        if ( !this.notificationChannel )
        {
            this.notificationChannel = this.getCable().subscriptions.create('NotificationChannel', {
                received: (obj: any): void => {
                    this.notificationSubject.next(obj);
                }
            });
        }
        return this.notificationSubject.asObservable();
    }

    getStatusUpdate(): Observable<any>
    {
        if ( !this.appChannelSet )
        {
            this.appChannelSet = true;
            this.getCable().subscriptions.create('AppearanceChannel', {
                unsubscribe: () => {
                    this.appChannelSet = null;
                },
                received   : (obj: any): void => {
                    this.statusSubject.next(obj);
                }
            });
        }
        return this.statusSubject
            .asObservable();
    }


    getClinicUpdate(): Observable<any>
    {
        if ( !this.clinicChannel )
        {
            this.clinicChannel = this.getCable().subscriptions.create('ClinicChannel', {
                unsubscribe: () => {
                    this.clinicChannel = null;
                },
                received   : (obj: any): void => {
                    this.clinicUpdate.next(obj);
                }
            });
        }
        return this.clinicUpdate.asObservable();
    }

    getUserAppearances(): Observable<any>
    {
        if ( !this.appearanceChannel )
        {
            this.appearanceChannel = this.getCable().subscriptions.create('AppearanceChannel', {
                unsubscribe: () => {
                    this.appearanceChannel = null;
                },
                connected  : () => {
                    this.appearanceChannel?.send({
                        online_status: 'online',
                        action       : 'update'
                    });
                },
                received   : (obj: any): void => {
                    this.appearanceUpdate.next(obj);
                },

            });
        }
        return this.appearanceUpdate.asObservable();
    }
    getAppearanceChannel(): ActionCable.Channel
    {
        return this.appearanceChannel;
    }

    getPatientCallerUpdates(): Observable<any>
    {
        if ( !this.patientCallerChannel )
        {
            this.patientCallerChannel = this.getCable(true).subscriptions.create('PatientChannel', {
                unsubscribe: () => {
                    this.patientCallerChannel = null;
                },
                received   : (obj: any): void => {
                    this.patientCallerUpdate.next(obj);
                },
            });
        }
        return this.patientCallerUpdate.asObservable();
    }

    getSlideUpdates(clinicId): Observable<any>
    {
        if ( !this.slideCallerChannel )
        {
            this.slideCallerChannel = this.getCable( true).subscriptions.create({
                channel  : 'SlideShowChannel',
                clinic_id: clinicId
            }, {
                unsubscribe: () => {
                    this.slideCallerChannel = null;
                },
                received   : (obj: any): void => {
                    this.slideUpdate.next(obj);
                },
            });
        }
        return this.slideUpdate.asObservable();
    }
    getThreadUpdate(threadId): Observable<any>
    {
        if ( !this.threadChannel )
        {
            this.threadChannel = this.getCable( true).subscriptions.create({
                channel  : 'ShareThreadChannel',
                doc_id: threadId
            }, {
                unsubscribe: () => {
                    this.threadChannel = null;
                },
                received   : (obj: any): void => {
                    this.threadUpdate.next(obj);
                },
            });
        }
        return this.threadUpdate.asObservable();
    }
    getDocReadChannel(): any
    {
        if ( !this.commentsReadChannel )
        {
            this.commentsReadChannel = this.getCable(true).subscriptions.create('DocReadChannel', {
                unsubscribe: () => {
                    this.commentsReadChannel = null;
                },
                received   : (obj: any): void => {
                    this.docReadUpdate.next(obj);
                },
            });
        }
        return this.docReadUpdate.asObservable();
    }
}
