import { Directive , EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[DropZone]'
})
export class DragDropFilesDirective {

    // tslint:disable-next-line:no-output-on-prefix
    @Output() FileDropped = new EventEmitter<any>();
    @HostBinding('style.opacity') private opacity = '1';
    @HostBinding('style.border') private border = 'none';
    @HostListener('dragover', ['$event']) public onDragOver(evt): any {
        evt.preventDefault();
        evt.stopPropagation();
        this.opacity = '0.8';
        this.border = 'dotted 2px #FF4D2A';
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(evt): any {
        evt.preventDefault();
        evt.stopPropagation();
        this.opacity = '1';
        this.border = 'none';
    }

    @HostListener('drop', ['$event']) public ondrop(evt): any {
        evt.preventDefault();
        evt.stopPropagation();
        this.opacity = '1';
        this.border = 'none';
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.FileDropped.emit(files);
        }
    }
  constructor() { }

}
