import { Injectable } from '@angular/core';
import { IFile } from '../../types/File';

export enum Priorities
{
    critical = 'Critical',
    important = 'Important',
    notice = 'Notice',
    none = 'none'
}

export enum FileUploadRights
{
    All = 'all',
    OnlyMe = 'only_authors'
}
export  interface  IVariation{
    id: number;
    variation: string;
    quantity: number;
    selected?: boolean;
}
export interface Thread
{
    id?: number;
    priority?: string; // set list from database
    title?: string;
    clinicCategoryId?: number; // 5 is community id default for thread
    docCreator?: any;
    clinicId?: number;
    description?: string;
    docType?: string;
    docSubscribedUsers?: any; // ??? all , SU ,   []
    docNotification?: boolean;
    files?: any;
    createdTime?: any;
    categoryName?: string;
    clinicName?: string;
    docComments?: ThreadComment[];
    clinicDocCommentsCount?: number;
    clinicDocCommentFileCount?: number;
    clinicDocFileCount?: number;
    isAdmin?: boolean;
    isFileBeingUploaded?: boolean;
    docReads?: IRead[];
    attachingFile?: boolean;
    allowSharing: boolean;
    price: string;
    productVariations: IVariation[];
    isMarketplaceProduct: boolean;
    marketplaceCategoryId: number | string;
    productEditable: boolean;
}

export interface ThreadComment
{
    id: number;
    userId: number;
    comment: string;
    commentType: string;
    createdAt: string;
    userCommenting: IReadUser;
    files: any;
    alreadyRead: boolean;
    commentReadCount: number;
    commentReads: IRead[];
}

export interface IRead
{
    id: number;
    user: IReadUser;
}

export interface IReadUser
{
    id: number;
    full_name: string;
    file: IFile;
}

@Injectable({
    providedIn: 'root'
})
export class ThreadAdapter
{
    adapt(item: any): Thread
    {
        const commentAdapter = new ThreadCommentAdapter();
        const readAdapter = new ReadAdapter();
        return {

            id                       : item.id,
            clinicId                 : item.clinic_id,
            clinicCategoryId         : item.clinic_category_id,
            title                    : item.title,
            description              : item.description,
            docType                  : item.doc_type,
            priority                 : item.priority,
            createdTime              : item.created_at,
            clinicName               : item.clinic_name,
            isAdmin                  : item.is_admin,
            categoryName             : item.clinic_category_name,
            docCreator               : item.doc_creator,
            docNotification          : item.doc_notification_on,
            docSubscribedUsers       : item.doc_sub_users,
            files                    : item.doc_files,
            docComments              : item.clinic_doc_comments ? item.clinic_doc_comments.map(data => commentAdapter.adapt(data)) : [],
            clinicDocCommentsCount   : +item.clinic_doc_comments_count,
            clinicDocCommentFileCount: +(item.clinic_doc_comment_file_count || 0),
            clinicDocFileCount       : +(item.clinic_doc_file_count || 0),
            isFileBeingUploaded      : false,
            allowSharing             : item.allow_sharing,
            docReads                 : item.doc_reads ? item.doc_reads.map(data => readAdapter.adapt(data)) : [],
            attachingFile            : item.attaching_file,
            price                    : item.price,
            productVariations        : item.product_variations,
            isMarketplaceProduct     : item.is_marketplace_product,
            marketplaceCategoryId    : item.marketplace_category_id,
            productEditable          : item.product_editable,
        };
    }
}

@Injectable({
    providedIn: 'root'
})
export class ThreadCommentAdapter
{
    public adapt(item: any): ThreadComment
    {
        const readAdapter = new ReadAdapter();
        return {
            comment         : item.comment,
            commentType     : item.comment_type,
            createdAt       : item.created_at,
            files           : item.files,
            id              : item.id,
            userCommenting  : item.comment_creator,
            userId          : item.user_id,
            commentReadCount: item.comment_reads_count,
            commentReads    : item.comment_reads ? item.comment_reads.map(data => readAdapter.adapt(data)) : [],
            alreadyRead     : item.already_read
        };
    }
}

@Injectable({
    providedIn: 'root'
})
export class ReadAdapter
{
    public adapt(item: any): IRead
    {
        return {
            id  : item.id,
            user: item.user
        };
    }
}
