<div class="fuse-countdown">

    <div class="time days">
        <div class="value">
            {{countdown.days}}
        </div>
        <div class="title">
            {{'GROUPS.day' | translate}}
        </div>
    </div>

    <div class="time hours">
        <div class="value">
            {{countdown.hours}}
        </div>
        <div class="title">
            {{'GROUPS.hours' | translate}}
        </div>
    </div>

    <div class="time minutes">
        <div class="value">
            {{countdown.minutes}}
        </div>
        <div class="title">
            {{'GROUPS.minutes' | translate}}
        </div>
    </div>

    <div class="time seconds">
        <div class="value">
            {{countdown.seconds}}
        </div>
        <div class="title">
            {{'GROUPS.seconds' | translate}}
        </div>
    </div>

</div>
