import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { RestService } from '../../service/api/rest.service';
import { Friend } from './friend.model';

@Injectable({providedIn: 'root'})
export class FriendsEntityService extends EntityCollectionServiceBase<Friend>
{
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private rest: RestService)
    {
        super('Friend', serviceElementsFactory);
    }

}
