import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ShareThreadDialogComponent } from './share-thread-dialog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
  declarations: [ShareThreadDialogComponent],
  imports: [
    CommonModule,
      NgxMatIntlTelInputModule,
      TranslateModule,
      MatIconModule,
      MatChipsModule,
      MatInputModule,
      MatBadgeModule,
      MatFormFieldModule,
      MatButtonModule,
      FormsModule,
      ReactiveFormsModule,
      MatToolbarModule,
      MatTooltipModule,
      FuseSharedModule,
      FuseSidebarModule,
      FlexLayoutModule,
      InfiniteScrollModule,

  ]
})
export class ShareThreadModule { }
