import { Directive, HostListener, Input } from '@angular/core';
import { FriendDetailModalComponent } from '../main/apps/profile/friend-detail-modal/friend-detail-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FriendDataService } from '../store/friend/friend-data.service';
import { NewAuthDataService } from '../service/api/newAuth-data.service';
import { User } from 'app/types';
import { Router } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';

@Directive({
    selector: '[userProfile]'
})
export class UserProfileDirective
{
    @Input() userProfile;
    private current: User;
    listedUser: any;

    @HostListener('click', ['$event']) onClick($event): void
    {
        this.progressBarService.show();
        this.friendDataService.getFriendProfile(this.userProfile).subscribe((data) => {
            this.listedUser = data;
            this.progressBarService.hide();
            if ( this.userProfile === this.current.id )
            {
                this.router.navigate(['/profile']);
                this._matDialog.closeAll();
            } else
            {
                let isFriendStatus = true;
                let viewProfileStatus = true;
                if ( data.user.friend_status === null )
                {
                    isFriendStatus = true;
                    viewProfileStatus = true;
                } else if ( data.user.friend_status.request_status === 'message' || data.user.friend_status.request_status === 'request' )
                {
                    isFriendStatus = true;
                    viewProfileStatus = true;
                } else if ( data.user.friend_status.request_status === 'accept' )
                {
                    isFriendStatus = true;
                    viewProfileStatus = false;
                }
                this._matDialog.open(FriendDetailModalComponent, {
                    panelClass: 'friend-detail-modal',
                    data      : {
                        detail     : data.user,
                        index      : 0,
                        isFriend   : isFriendStatus,
                        friend     : data,
                        viewProfile: viewProfileStatus
                    }
                }).afterClosed().subscribe((res) => {
                    const obj = {
                        status: res,
                        user: this.listedUser
                    };
                    this.friendDataService.passValue(obj);
                });
            }
        }, (error) => {
            this.progressBarService.hide();
            error.status === 404 ? this.toast.error('This user haven\'t created profile yet', 'Error') : this.toast.error(error.errors.join(', '), 'Error');
        });


    }

    constructor(private _matDialog: MatDialog,
                private router: Router,
                private progressBarService: FuseProgressBarService,

                private friendDataService: FriendDataService,
                private authDataService: NewAuthDataService,
                private toast: ToastrService)
    {
        this.authDataService.getUser().subscribe((user) => {
            this.current = user;
        });
    }

}
