import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../../../../../i18n/en';
import { locale as danish } from '../../../../../../../i18n/da';

@Component({
    selector   : 'app-categories-list',
    templateUrl: './categories-list.component.html',
    styleUrls  : ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit
{
    inputCat = '';

    categoriesList: any = [];

    constructor(private toastrService: ToastrService,
    )
    {
    }

    ngOnInit(): void
    {
    }

    remove(i: number): void
    {

    }

    removeItem(item: any, index: number): void
    {
        this.categoriesList.splice(index, 1);
    }

    addItem(form: NgForm): void
    {
        const newCommentText = form.value.checkItem;
        let nameExists = false;
        if ( newCommentText.length === 0 )
        {
            this.toastrService.error('Folder name cannot be added.', 'Error');
            return;
        }

        for (const category of  this.categoriesList)
        {
            nameExists = newCommentText === category.name;
            if ( nameExists )
            {
                this.toastrService.error('Same name folders cannot be added.', 'Error');
                nameExists = false;
                return;
            }
        }
        if ( !nameExists )
        {
            this.categoriesList.push({
                name   : newCommentText,
                checked: true
            });
            form.setValue({checkItem: ''});
        }

    }

    onDrop($event: any): void
    {

    }

    moveItemDown(item, index): void
    {
        [this.categoriesList[index], this.categoriesList[index + 1]] = [this.categoriesList[index + 1], this.categoriesList[index]];
    }

    moveItemUp(item, index): void
    {
        [this.categoriesList[index], this.categoriesList[index - 1]] = [this.categoriesList[index - 1], this.categoriesList[index]];
    }
}
