<div class="dialog-content-wrapper">

    <mat-toolbar matDialogTitle class="accent m-0" fxFlex="1 0 auto" fxLayout="row">

        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

            <div fxFlex fxLayout="row" fxLayoutAlign="start center">

            </div>

            <!-- CLOSE DIALOG BUTTON -->
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close Dialog">
                <mat-icon matTooltip="{{'THREAD.close' | translate}}">close</mat-icon>
            </button>

            <!-- / CLOSE DIALOG BUTTON -->

        </div>

    </mat-toolbar>

    <div mat-dialog-content class="py-24 px-0 m-0">

        <!-- BREADCRUMB -->
        <div class="card-breadcrumb mt-8 mb-32 px-24" fxLayout="row" fxLayoutAlign="center center">
            <span *ngIf="newThread" class="md-title">{{'THREAD.where_to_create_thread' | translate}}</span>
            <span *ngIf="!newThread" class="md-title">{{'THREAD.where_to_update_thread' | translate}}</span>
        </div>
        <!-- / BREADCRUMB -->

        <div class="sections">

            <!-- LABELS & Select memeber who could see SECTION -->
            <div class="section" fxLayout="column">

                <div fxLayout="row" fxLayoutAlign="start center" class="mb-20 ml-20">

                    <span *ngIf="newThread">{{'THREAD.your_thread_will_be_created' | translate}}</span>
                    <span *ngIf="!newThread">{{'THREAD.your_thread_will_be_updated' | translate}}</span>

                </div>

                <div fxLayout="row" fxLayoutAlign="start center" class="mb-4 ml-20">

                    <span style="width: 20%">{{'THREAD.location_group' | translate}}:</span>
                    <span style="color:#039be5">{{group}}</span>

                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="mb-32 ml-20">

                    <span style="width: 20%">Folder:</span>
                    <span style="color:#039be5">{{category}}</span>

                </div>

                <div fxLayout="column" fxLayoutAlign="center center" class="mb-32">


                    <button mat-stroked-button class="mb-8" style="width: 25%" (click)="changeGroup()">
                        {{'THREAD.change_group' | translate}}
                    </button>

                    <button mat-flat-button color="accent" style="width: 25%" class=" p-8" (click)="saveThread()">OK
                        <span *ngIf="newThread" style="display:block">{{'THREAD.create_thread' | translate}}</span>
                        <span *ngIf="!newThread" style="display:block">{{'THREAD.update_thread' | translate}}</span>
                    </button>
                </div>


                <div fxLayout="row" fxLayoutAlign="start " class="mt-24">
                    <mat-checkbox [checked]="confirmation" (change)="confirmationChange($event)"
                                  color="primary">{{'THREAD.dont_show_this_confirmation_page' | translate}}</mat-checkbox>
                </div>
            </div>

        </div>

    </div>

</div>

