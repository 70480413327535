import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewAuthDataService } from '../../../../../service/api/newAuth-data.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { GroupsEntityService } from '../../../../../store/group/groups-entity.service';
import { GroupsDataService } from '../../../../../store/group/groups-data.service';
import { ThreadsDataService } from '../../../../../store/thread/threads-data.service';
import { ThreadsEntityService } from '../../../../../store/thread/threads-entity.service';
import { UploadingFilesEntityService } from '../../../../../store/uploading-files/uploading-files-entity.service';
import { LocalStorageService } from 'angular-web-storage';
import { User } from '../../../../../types';
import { Thread } from '../../../../../store/thread/thread.model';
import { Subject } from 'rxjs';
import { AttachmentsComponent } from '../../attachments/attachments.component';
import { IGroup } from '../../../../../store/group/group.model';
import { UtilityService } from '../../../../../service/utility.service';

@Component({
    selector     : 'app-product-creation-modal',
    templateUrl  : './product-creation-modal.component.html',
    styleUrls    : ['./product-creation-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductCreationModalComponent implements OnInit
{

    prioritycheck: boolean;
    confirmation: boolean;
    counter = 0;
    list: any;
    priorities: any;
    community = false;
    userData: User;
    threadData: Thread;
    dialogTitle: string;
    componentActive = true;
    productForm: FormGroup;
    currentSelectedGroup: any;
    currentSelectedCategory: any;
    groupsList: any[];
    dialogOpenStatus: boolean;
    newThread: boolean;
    // Use with the generic validation message class
    displayMessage: { [key: string]: string } = {};
    // Private
    private _unsubscribeAll: Subject<any>;
    @ViewChild('attachmentsC', {static: false}) attachmentsC: AttachmentsComponent;
    removedFiles: any = [];
    allowGroupEdit = true;
    loadingProgress = false;
    priority: string;
    groupsDataList: any;
    prioritiesList = true;
    saveButton = false;
    allFiles: any[] = [];
    currentClinic: IGroup | any;
    allowLargeFiles: boolean;
    isSharePost: boolean;
    categories: any[] = [];
    imageSrc: any[] = [];
    removedVariation: any[] = [];
    marketCategories: any[] = [];
    constructor(private toast: ToastrService,
                public matDialogRef: MatDialogRef<ProductCreationModalComponent>,
                @Inject(MAT_DIALOG_DATA) private _data: any,
                private _matDialog: MatDialog,
                private _authService: NewAuthDataService,
                private _formBuilder: FormBuilder,
                private _matSnackBar: MatSnackBar,
                private _router: Router,
                private progressBarService: FuseProgressBarService,
                private _groupService: GroupsEntityService,
                private groupDataService: GroupsDataService,
                private threadDataService: ThreadsDataService,
                private threadEntity: ThreadsEntityService,
                private uploadingFilesEntityService: UploadingFilesEntityService,
                private local: LocalStorageService,
                private utilityService: UtilityService)
    {
        // this._authService.getUser()
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((user) => {
        //         this.userData = user;
        //     });
        this.priorities = [
            {
                name : 'offer',
                color: 'pink-900'
            }, {
                name : 'limited_number',
                color: 'pink-900'
            },
            {
                name : 'time_limited',
                color: 'pink-900'
            },
            {
                name : 'just_notify_followers',
                color: 'pink-900'
            }
        ];

        this.dialogOpenStatus = false;
        if ( this._data.notAllowGroupEdit )
        {
            this.allowGroupEdit = false;
        }
    }

    ngOnInit(): void
    {
        this.newThread = this._data.new;
        this.currentClinic = this._data.currentClinic ?? undefined;

        this._authService.getUser()
            .subscribe((user) => {
                this.userData = user;
            });
        this.productForm = this._formBuilder.group({
            priority   : [''],
            title      : [''],
            description: [''],
            groupId    : [''],
            categoryId : [],
            marketCategory: [],
            attachment : [''],
            price      : ['1'],
            variations : this._formBuilder.array([this.getNewVariation()]),
            isMarketplaceProduct: [false],
        });
        this.userClinicWithCategories();
    }

    get variations(): FormArray{
        return this.productForm.get('variations') as FormArray;
    }

    getType(type): boolean
    {
        return type.includes('image') ? true : false;
    }

    getNewVariation(): FormGroup
    {
        return this._formBuilder.group({
            variation    : this._formBuilder.control(''),
            quantity: this._formBuilder.control(''),
        });
    }
    addVariation(): void{
            this.variations.push(this.getNewVariation());

    }

    setVariations( variations): FormArray {
        const formArray = new FormArray([]);
        variations.forEach((item) => {
          formArray.push(  this._formBuilder.group({
                variation: item.variation,
                quantity : item.quantity,
                id       : item.id,
            }));
        });
        return formArray;
    }

    removeVariation(index: number , variation: any): void{
        this.variations.removeAt(index);
        if(variation.value.id)
        {
            variation.value.action = 'destroy';
            this.removedVariation.push(variation.value);
        }
    }

    droppedFiles(allFiles: File[]): void
    {
        const filesAmount = allFiles.length;
        for (let i = 0; i < filesAmount; i++)
        {

            const dropfile = allFiles[i];
            if ( dropfile.type.includes('image') )
            {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    this.imageSrc.push({src: event.target.result , content_type: allFiles[i].type  });
                };
                reader.readAsDataURL(dropfile);
            }
            else{
                this.imageSrc.push({src: 'video', content_type: 'video'});
            }
            this.allFiles.push({file: dropfile, progressStatus: ''});
        }
        }

    removeAttachment(index , file): void
    {
        this.allFiles.splice(index, 1);
        this.imageSrc.splice(index, 1);
        if ( !this.newThread )
        {
            this.removedFiles.push(file);
        }
    }
    
    // Number Input Validator

    inputValidator(event: any): void
    {
        event.target.value = event.target.value.replace(/[^0-9,.]/g, '')
            .replace(/(,.*?),(.*,)?/, '$1');
        if ( event.target.value.includes(',') )
        {
            const afterComma = event.target.value.split(',')[1];

            event.target.value = `${event.target.value.split(',')[0]},${afterComma.replaceAll('.', '')}`;
        }
        let currentValue = event.target.value;
        if ( currentValue.includes(',') )
        {
            currentValue = currentValue.replaceAll(',', '');

        }
        if ( currentValue.includes('.') )
        {
            currentValue = currentValue.replaceAll('.', '');
        }
        if ( +currentValue > 10000000 )
        {
            this.productForm?.get('price')?.setErrors({invalid: true});
        }
    }

    inputValidatorQuantity(event: any): void {
        const pattern = /^[0-9]*$/;
        if (!pattern.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^0-9]/g, "");
        }
    }
    userClinicWithCategories(): void {
        this.threadDataService.getUserClinicsWithCategories().subscribe((data) => {
            this.groupsList = data.clinics;

            if ( this.newThread )
            {
                const  currentClinic = this.groupsList.find((group) =>  group.id ===  this._data.currentClinic?.id );
                const  marketPlace = this.groupsList.find((group) =>  +group.id ===  12 );

                this.marketCategories = [...marketPlace?.clinic_categories];

                this.currentSelectedGroup = currentClinic;
                this.categories = [...currentClinic?.clinic_categories];
                this.productForm.patchValue({
                    categoryId: this.categories[0]?.id,
                    marketCategory: this.marketCategories[0]?.id
                });
            } else
            {
                const  currentClinic = this.groupsList.find((group) =>  group.id ===  this._data?.threadData?.clinicId  );
                const  marketPlace = this.groupsList.find((group) =>  +group.id ===  12 );
                this.marketCategories = [...marketPlace?.clinic_categories];
                this.currentSelectedGroup = currentClinic;
                this.categories = [...currentClinic?.clinic_categories];

                const uploadedFiles = this._data?.threadData.files;
                this.imageSrc = [...uploadedFiles];
                this.subscribedThreadData(data);
            }

        });
    }

    subscribedThreadData(data): void
    {
        this.groupsDataList = data;
        if ( !this.newThread )
        {
            this.groupsDataList.clinics.forEach(ele => {
                if ( this._data.threadData.clinicId === ele.id )
                {
                    ele.is_default ? this.prioritiesList = false : this.prioritiesList = true;
                }
            });
            this.allowGroupEdit = false;
            this.confirmation = false;
            this.threadData = {...this._data.threadData};

            this.productForm.patchValue({
                priority   : this.threadData.priority,
                title      : this.threadData.title,
                description: this.threadData.description,
                groupId    : this.threadData.clinicId,
                categoryId : this.threadData.clinicCategoryId,
                marketCategory : this.threadData.marketplaceCategoryId,
                price      : this.threadData.price.replace('.', ','),
                isMarketplaceProduct: this.threadData.isMarketplaceProduct,
            });
            if ( this.threadData?.productVariations.length > 0 )
            {
                this.productForm.setControl('variations', this.setVariations(this.threadData.productVariations));
            }
            this.currentSelectedGroup = {
                id  : this.threadData.clinicId,
                name: this.threadData.clinicName
            };
            this.currentSelectedCategory = {
                id  : this.threadData.clinicCategoryId,
                name: this.threadData.categoryName
            };
            this.currentSelectedGroup = this.groupsDataList.clinics.find(group => group.id === this.currentSelectedGroup.id);
            // this.currentSelectedCategory.push(this.currentSelectedGroup.clinic_categories);
            this.allowLargeFiles = this.currentSelectedGroup?.allow_large_files;
        } else
        {
            if ( this.currentClinic?.id )
            {
                this.currentSelectedGroup = this.groupsList.filter(c => +c.id === +this.currentClinic?.id).pop();
                this.currentSelectedCategory = this.currentSelectedGroup.clinic_categories.filter(({name}) => name === 'Standard (Default)').pop();

            }
        }
    }
    saveProduct(): void {

        const descriptionControl: FormControl = this.productForm.get('description') as FormControl;
        if ( this.allFiles.length > 0 && (descriptionControl.value === null || descriptionControl.value === '') )
        {
            this.allFiles.length > 1 ?
                descriptionControl.setValue(this.allFiles[0].name + ' + ' + (this.allFiles.length - 1)) :
                descriptionControl.setValue(this.allFiles[0].name);
        }
        const title = this.productForm.get('title')?.value?.trim() || '';
        const price = this.productForm.get('price')?.value?.trim() || '';
        if (+price > 10000000) {
            this.productForm?.get('price')?.setErrors({invalid: true});
            return;
        }
        if ( !descriptionControl.value )
        {
            this.toast.error('', 'Description is required');
            return;
        }
        if ( title === '' && descriptionControl.value )
        {
            this.productForm.patchValue({title: descriptionControl.value.trim().split(' ').slice(0, 5).join(' ')});
        }
        this.productForm.controls.groupId.setValue(this.currentSelectedGroup.id);
        for (let  i = 0;  i < this.variations.value.length ; i++)
        {
            if ( Object.values(this.variations.value[i]).every((item) => item === '') )
            {
                this.variations.removeAt(i);
            }
            if ( this.variations.value.length !== 0 && Object?.values(this.variations?.value[i])?.some((item) => item !== '') )
            {
                const formGroupCOnt = this.variations.controls[i] as FormGroup;
                if ( formGroupCOnt?.get('quantity')?.value === '' )
                {
                    formGroupCOnt?.get('quantity')?.setErrors({required: true});
                    formGroupCOnt?.get('quantity')?.markAsTouched();
                }
                if ( formGroupCOnt?.get('variation')?.value === '' )
                {
                    formGroupCOnt?.get('variation')?.setErrors({required: true});
                    formGroupCOnt?.get('variation')?.markAsTouched();
                }
            }

        }
        const g = {...this.productForm.value};
        g.doc_type = 'thread';
        // tslint:disable-next-line:no-unused-expression
        this.prioritiesList ? g.priority : g.priority = null;
        g.priority = g.priority === null ? g.priority : g.priority.toLowerCase();
        g.files = this.threadData?.files;

        this.__saveThread(g);
    }

    async __saveThread(g): Promise<void>
    {
        if ( this.productForm.valid )
        {
            // if (this.threadForm.dirty) {
            // Copy over all of the original product properties
            // Then copy over the values from the form
            // This ensures values not on the form, such as the Id, are retained


            if ( this.newThread )
            {
                this.loadingProgress = true;
                if ( g.groupId === 1 )
                {
                    g.priority = null;
                }
                g.price = this.removeComma(g.price);

                const userData = {
                    'doc'               : {
                        clinic_id             : g.groupId,
                        clinic_category_id    : g.categoryId,
                        doc_type              : 'thread',
                        priority              : g.priority,
                        title                 : g.title,
                        description           : g.description,
                        files                 : g.files,
                        price                 : parseFloat(g.price),
                        is_marketplace_product: g.isMarketplaceProduct,
                    },
                    'product_variations': g.variations,
                };

                if ( g.isMarketplaceProduct )
                {
                    userData.doc['marketplace_category_id'] = g.marketCategory;
                }
                // @ts-ignore
                this.threadEntity.add(userData).subscribe((data) => {
                    this.toast.success('', 'Product Created');
                    this.loadingProgress = false;
                    let threads: any = [];
                    let first = false;
                    this.threadEntity.entities$.subscribe((res) => {
                        if ( !first )
                        {
                            threads = [...res];
                            first = true;
                        }
                    });
                    const moveToTop = threads.splice(-1);
                    threads.unshift(moveToTop[0]);
                    this.threadEntity.clearCache();
                    if ( this.allFiles.length > 0 )
                    {
                        const index = threads.findIndex((thread) => thread.id === data.id);
                        if ( index !== -1 )
                        {
                            threads[index].isFileBeingUploaded = true;
                        }
                    }
                    this.threadEntity.addManyToCache(threads);
                    this.matDialogRef.close(['Thread Created']);
                    if ( this.allFiles.length > 0 )
                    {
                        this.toast.info('Product attachments are uploading!');
                        this.uploadFilesToServer(data.id, data).then((res) => {
                            this.saveButton = false;
                            this.matDialogRef.close(['Product Created']);
                        });
                    } else
                    {
                        this.matDialogRef.close(['Product Created']);
                    }
                }, (error) => {
                    this.toast.error(error.errors.join(', '), 'Error');
                    this.loadingProgress = false;
                });
            } else
            {
                g.variations = [...this.removedVariation, ...g.variations];
                this.loadingProgress = true;
                for (const removedFile of this.removedFiles)
                {
                    await this.threadDataService.deleteFile(removedFile.signed_id).toPromise();
                }
                g.price = this.removeComma(g.price);
                const userData = {
                    'doc'               : {
                        clinic_id             : g.groupId,
                        clinic_category_id    : g.categoryId,
                        doc_type              : 'thread',
                        priority              : g.priority,
                        title                 : g.title,
                        description           : g.description,
                        price                 : parseFloat(g.price),
                        is_marketplace_product: g.isMarketplaceProduct,
                    },
                    'product_variations': g.variations,
                };
                if ( g.isMarketplaceProduct )
                {
                    userData.doc['marketplace_category_id'] = g.marketCategory;
                }

                this.threadDataService.updateThread(userData, this.threadData.id).subscribe((data) => {
                    this.toast.success('', 'Product Updated');
                    this.loadingProgress = false;
                    this.threadEntity.updateOneInCache({...data, id: data.id});
                    this.matDialogRef.close(['Product Updated']);
                    if ( this.allFiles.length > 0 )
                    {
                        this.toast.info('Product attachments are uploading!');
                        this.uploadFilesToServer(data.id, data).then((res) => {
                            this.matDialogRef.close(['Product Updated']);
                        });
                    } else
                    {
                        this.matDialogRef.close(['Thread Updated']);
                    }
                }, (error) => {
                    this.toast.error(error.errors.join(', '), 'Error');
                    this.loadingProgress = false;
                });
            }
        }

    }

    removeComma(value): any {

        let r = value.replaceAll('.' , '');
        return r.replace(',' , '.');
    }
    async uploadFilesToServer(threadId, threadData): Promise<void>
    {
        this.uploadingFilesEntityService.addOneToCache({threadId: threadId, id: threadId});
        let signedIdList = await this.utilityService.directUpload( this.allFiles, threadData.clinicId);
        if ( typeof signedIdList[0] === 'string' && signedIdList[0]?.includes('Error') )
        {
            this.uploadingFilesEntityService.removeOneFromCache(threadId);
            this.threadEntity.updateOneInCache({isFileBeingUploaded: false, id: threadId});
            this.toast.error('Clinic file uploading limit exceeded', 'Error');
        } else
        {
            if ( threadData.files )
            {
                signedIdList = [...signedIdList, ...threadData.files];
            }
            const data = {
                doc: {
                    files: signedIdList.map(t => t.signed_id)
                },
                skip_activity_update: true
            };
            this.uploadingFilesEntityService.removeOneFromCache(threadId);
            this.threadDataService.updateThread(data, threadId).toPromise().then((resData) => {
                this.threadEntity.updateOneInCache({...resData, isFileBeingUploaded: false, id: resData.id});
                this.toast.success('Product attachments successfully uploaded!');
            });
        }
    }
    prioritySelection(prioritySelection: any): void
    {
        if ( this.productForm.value.priority !== prioritySelection )
        {
            this.productForm.patchValue({
                priority: prioritySelection
            });
        } else
        {
            this.productForm.patchValue({
                priority: ''
            });
        }
    }
}
