import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';
import { TaskDataService } from '../../../../../store/task/task-data.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { NewAuthDataService } from '../../../../../service/api/newAuth-data.service';
import * as _ from 'lodash';
import { ActionCableService } from '../../../../../service/socket/action-cable.service';
import { TaskCommentAdapter } from '../../../../../store/task/task.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AttachmentsComponent } from '../../attachments/attachments.component';
import { ThreadsDataService } from '../../../../../store/thread/threads-data.service';
import { TasksEntityService } from '../../../../../store/task/task-entity.service';
import { FriendDataService } from '../../../../../store/friend/friend-data.service';
import { User } from '../../../../../types';
import {
    DeleteConfirmationDialogComponent
} from '../../../groups/group/sidenavs/settings/user-setting/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
    MY_FORMATS
} from '../../../profile/tabs/profile-about/basic-information-form/basic-information-form.component';
import { IGroup } from '../../../../../store/group/group.model';

@Component({
    selector     : 'app-task',
    templateUrl  : './task.component.html',
    styleUrls    : ['./task.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers    : [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class TaskComponent implements OnInit, OnDestroy
{
    list: any;
    hours = [];
    currentDate = moment();
    archived = false;
    timeInterval: any = [];
    selectedUsers: any = [];
    calendarShow = true;
    taskShow = true;
    today = new Date();
    todaysDataTime = '';
    apiResponse = [];
    taskList: any = [];
    groupUserList: any = [];
    dialogTitle: string;
    componentActive = true;
    taskForm: FormGroup;
    groupsList: any[];
    @ViewChild('attachmentsC', {static: false}) attachmentsC: AttachmentsComponent;

    @ViewChild('checklistMenuTrigger')
    checklistMenu: MatMenuTrigger;

    @ViewChild('newCheckListTitleField')
    newCheckListTitleField;

    categoriesFormArray: FormArray;
    groupSelected: boolean;
    groupIndex = 0;
    reporterObj: any;
    subUserList: any = [];
    currentUser: User;
    // Private
    private _unsubscribeAll: Subject<any>;
    private currentSelectedGroup: any;
    intervalValueIndex = 5;
    newTask: boolean;
    taskData: any;
    subUserChange = false;
    archivedTask = false;
    @ViewChild('groupNameMenu') trigger: MatMenuTrigger;
    private taskCommentsUpdate: Subscription;
    loadingProgress = false;
    allowGroupEdit = true;
    removedAttachment: any = [];
    showAttachment = false;
    groupTag = true;
    allDaySelected = false;
    currentClinic: IGroup;
    allowLargeFiles: boolean;
    isMyDoc = false;

    constructor(
        public matDialogRef: MatDialogRef<TaskComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _matDialog: MatDialog,
        private store: Store<any>,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private taskDataService: TaskDataService,
        private toast: ToastrService,
        private taskEntityService: TasksEntityService,
        private authService: NewAuthDataService,
        private threadsDataService: ThreadsDataService,
        private actionCableService: ActionCableService,
        private taskCommentAdapter: TaskCommentAdapter,
        private _activatedRoute: ActivatedRoute,
        private friendDataService: FriendDataService,
        private router: Router,
        private progressBarService: FuseProgressBarService,
    )
    {
        this.todaysDataTime = formatDate(this.today, 'hh:mm:ss a', 'en-US', '+0530');
        this._unsubscribeAll = new Subject();

        this.categoriesFormArray = new FormArray([]);
        this.groupSelected = false;


        for (let i = 5; i <= 55; i += 5)
        {
            this.timeInterval.push(i + ' mins');
        }
        for (let i = 1; i <= 8; i++)
        {
            this.timeInterval.push(i + ' hrs');
        }
        this.timeInterval.pop();
        this.timeInterval.push('All Day');

    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.newTask = this._data.new;
        this.authService.getUser().subscribe((user) => {
            this.currentUser = user;
            this.dialogTitle = 'Select Group';
            if ( this._data.taskData?.startDate )
            {
                this.currentDate = this._data.taskData.startDate;
            }


            this.taskForm = this._formBuilder.group({
                clinicId    : ['', Validators.required],
                title       : [''],
                description : ['', Validators.required],
                task        : [''],
                date        : [moment(this.currentDate)],
                startTime   : [moment(this.currentDate).format('HH:mm')],
                timeInterval: [this.timeInterval[5]],
                showCalendar: [this.calendarShow],
                showTask    : [this.taskShow],
                taskEndDate : ['']
            });
            if ( this._data.taskData?.timeInterval && +this._data.taskData?.timeInterval === 1439 )
            {
                this.taskForm.patchValue({
                    timeInterval: `All Day`
                });
                this.allDaySelected = true;
            }
            this.taskList = [
                {
                    name : 'To Do',
                    color: 'blue-600'
                },
                {
                    name : 'In Progress',
                    color: 'blue-600'
                },
                {
                    name : 'Done',
                    color: 'blue-600'
                },
                {
                    name : 'Archived',
                    color: 'blue-600'
                }
            ];
            this.taskForm.patchValue({
                task: 'To Do'
            });
            if ( this._data.state?.name )
            {
                this.taskForm.patchValue({
                    task: this._data.state?.name
                });
            }
            setTimeout(() => {
                this.taskDataService.getUserClinicsWithCategories().subscribe((data) => {
                    this.groupsList = data.clinics;
                    const communityGroupIndex = this.groupsList.findIndex(x => x.is_default === true);
                    this.groupsList.splice(communityGroupIndex, 1);
                    this.groupsList.length < 1 ? this.groupTag = false : this.groupTag = true;
                    if ( this._data.currentClinic )
                    {
                        this.currentClinic = this._data.currentClinic;
                        this.allowLargeFiles = this.currentClinic?.clinicModules?.large_file_size ?? false;
                        this.allowGroupEdit = false;
                        this.groupSelection(null, _.findIndex(this.groupsList, {id: +this._data.currentClinic.id}));
                    }
                });
            }, 500);
            if ( !this._data.new )
            {
                this.showAttachment = true;
                this.allowGroupEdit = false;
                this.taskData = this._data.taskData;
                if ( !(this.taskData.files?.length > 0) )
                {
                    this.showAttachment = false;
                }
                this.taskForm.patchValue({
                    clinicId    : this.taskData.clinicId,
                    title       : this.taskData.title,
                    description : this.taskData.description,
                    date        : this.taskData.taskStart,
                    startTime   : moment(this.taskData.taskStart).format('HH:mm'),
                    showCalendar: this.taskData.showCalendar,
                    showTask    : this.taskData.showTask,
                    taskEndDate : this.taskData.taskEnd
                });
                if ( this.taskData.timeInterval )
                {
                    const timeI = +this.taskData.timeInterval;
                    if ( timeI > 55 )
                    {
                        if ( timeI === 1439 )
                        {
                            this.allDaySelected = true;
                            this.taskForm.patchValue({
                                timeInterval: `All Day`,
                            });
                        } else
                        {
                            this.taskForm.patchValue({
                                timeInterval: `${(timeI / 60)} hrs`,
                            });
                        }
                    } else
                    {
                        this.taskForm.patchValue({
                            timeInterval: `${timeI} mins`,
                        });
                    }

                }

                switch (this.taskData.taskStatus)
                {
                    case 'to_do':
                        this.taskForm.patchValue({
                            task: 'To Do'
                        });
                        break;
                    case 'in_progress':
                        this.taskForm.patchValue({
                            task: 'In Progress'
                        });
                        break;
                    case 'done':
                        this.taskForm.patchValue({
                            task: 'Done'
                        });
                        break;
                    case 'archived':
                        this.taskForm.patchValue({
                            task: 'Archived'
                        });
                        break;
                }
                this.dialogTitle = this.taskData.clinicName;
                this.taskShow = this.taskData.showTask;
                this.calendarShow = this.taskData.showCalender;

                setTimeout(() => {
                    this.taskDataService.getGroupUsers(this.taskData.clinicId).subscribe((data: any) => {
                        this.groupUserList.length = 0;
                        this.apiResponse = data;
                        for (const item of data)
                        {
                            this.groupUserList.push(item);
                        }
                        this.reporterObj = this.apiResponse.find((obj: any) => {
                                return obj.id === this.taskData.reporterId;
                            }
                        );
                        this.subUserList = this.groupUserList.filter(item => item.id !== this.reporterObj.id);
                        this.selectedUsers = this.taskData.subscribedUsers;
                        this.subUserList = this.subUserList.map((user) => {
                            user.selectedUser = true;
                            user.selectedUser = this.selectedUsers.filter((item: any) => {
                                return item.id === user.id;
                            }).length > 0;
                            return user;
                        });
                    });
                }, 50);
                if ( this.currentUser.id === this.taskData.docCreator.id )
                {
                    this.archivedTask = true;
                }
                this.currentSelectedGroup = this.groupsList?.find(group => +group.id === +this.taskData.clinicId);
                this.allowLargeFiles = this.currentSelectedGroup?.allow_large_files;
            }
        });
    }

    updateGroupUsers($event: any, group: any): void
    {
        if ( this.groupsList.length > 0 )
        {
            this.currentSelectedGroup = '';
            this.currentSelectedGroup = this.groupsList.filter(obj => obj.id === group.id)[0];
        }
    }

    groupSelection(event: any, index: any): void
    {

        if ( this.groupsList[index]?.name === 'My Docs' ){
            this.isMyDoc = true;
        }else{
            this.isMyDoc = false;
        }
        this.dialogTitle = this.groupsList[index]?.name;
        this.taskForm.patchValue({
            clinicId: this.groupsList[index].id
        });
        this.taskDataService.getGroupUsers(this.groupsList[index].id).subscribe((data) => {
            this.groupUserList.length = 0;
            this.apiResponse = data.filter(res => res.requestStatus === 'approved');
            for (const item of  this.apiResponse)
            {
                this.groupUserList.push(item);
            }
            this.reporterObj = this.groupUserList.find((obj) => {
                    return obj.id === this.currentUser.id;
                }
            );
            if ( this.subUserList )
            {
                this.subUserList.length = 0;
            }
            if ( this.selectedUsers )
            {
                this.selectedUsers.length = 0;
            }
            this.subUserList = this.groupUserList.filter(item => item !== this.reporterObj);

        });
        this.groupIndex = index;
        this.groupSelected = !this.groupSelected;
        this.allowLargeFiles = this.groupsList[index]?.allow_large_files;
        if ( this.attachmentsC?.attachments )
        {
            this.attachmentsC.attachments = [];
        }
    }

    get f(): any
    {
        return this.taskForm.controls;
    }

    get t(): FormArray
    {
        return this.f.categories as FormArray;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.taskCommentsUpdate?.unsubscribe();
        this.componentActive = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    async saveGroup(): Promise<void>
    {
        this.beforeSave();
        if ( this.taskForm.valid )
        {
            if ( this.taskForm.get('showTask')?.value === false && this.taskForm.get('showCalendar')?.value === false )
            {
                this.toast.error('Please select task or calender.', 'Error');
            } else
            {
                this.loadingProgress = true;
                if ( this.removedAttachment.length > 0 )
                {
                    for (const removedAttachmentElement of this.removedAttachment)
                    {
                        await this.threadsDataService.deleteFile(removedAttachmentElement).toPromise();
                    }
                    this.removedAttachment = [];
                }
                const tempSubUsers: any = [];
                this.selectedUsers.forEach((item: any) => {
                    tempSubUsers.push({
                        user_id: item.id
                    });
                });
                let files: any = [];
                if ( this.attachmentsC?.attachedCount > 0 )
                {
                    const res = await this.attachmentsC.directUpload(this.taskForm.get('clinicId')?.value);
                    if ( typeof res[0] === 'string' && res[0]?.includes('Error') )
                    {
                        this.loadingProgress = false;
                        this.toast.error('Clinic file uploading limit exceeded', 'Error');
                        return;
                    } else
                    {
                        files = res.map(f => f.signed_id);
                    }
                }
                if ( this._data.taskData?.files?.length )
                {
                    files = [...files, ...this._data.taskData?.files.map(f => f.signed_id)];
                }
                let tempTaskStatus = '';
                switch (this.taskForm.get('task')?.value)
                {
                    case 'To Do':
                        tempTaskStatus = 'to_do';
                        break;
                    case 'In Progress':
                        tempTaskStatus = 'in_progress';
                        break;
                    case 'Done':
                        tempTaskStatus = 'done';
                        break;
                    case 'Archived':
                        tempTaskStatus = 'archived';
                        break;
                }
                const date = moment(this.taskForm.get('date')?.value).format('MM/DD/YYYY') + ' ' + this.taskForm.get('startTime')?.value;
                const taskStartDate = moment(date, 'MM/DD/YYYY HH:mm');
                const taskStartDateISO = taskStartDate.toISOString();
                const ti = this.taskForm.get('timeInterval')?.value.split(' ');
                let tim = +ti[0];
                if ( ti[1] === 'hrs' )
                {
                    tim = 60 * +ti[0];
                }
                if ( ti[0] === 'All' )
                {
                    tim = (60 * 24) - 1;
                }
                taskStartDate.add(tim, 'minute');
                const taskData = {
                    doc: {
                        clinic_id               : this.taskForm.get('clinicId')?.value,
                        doc_type                : 'task',
                        title                   : this.taskForm.get('title')?.value,
                        description             : this.taskForm.get('description')?.value,
                        reporter_id             : this.reporterObj.id,
                        task_start              : taskStartDateISO,
                        task_end                : taskStartDate.toISOString(),
                        task_status             : tempTaskStatus,
                        time_interval           : tim,
                        show_task               : this.taskForm.get('showTask')?.value,
                        show_calendar           : this.taskForm.get('showCalendar')?.value,
                        doc_sub_users_attributes: tempSubUsers,
                        files                   : files,
                    },
                    activity_type: '',
                };
                if ( this.newTask )
                {
                    this.taskDataService.createTask(taskData).subscribe((data) => {
                        this.toast.success('', 'Task Created');
                        if ( data.taskStatus === 'done' )
                        {
                            if ( this.router.url !== '/home' )
                            {
                                this.taskEntityService.addOneToCache({...data, id: data.id});
                            }
                        } else
                        {
                            this.taskEntityService.addOneToCache({...data, id: data.id});
                        }
                        this.loadingProgress = false;
                        this.matDialogRef.close('Task Created');
                    }, (error) => {
                        this.loadingProgress = false;
                        this.toast.error(error.errors.join(', '), 'Error');
                    });
                } else
                {
                    if ( this.attachmentsC?.attachedCount > 0 )
                    {
                        taskData.activity_type = 'file_added';
                    } else
                    {
                        taskData.activity_type = '';
                    }
                    this.taskDataService.updateTask(taskData, this.taskData.id).subscribe((data) => {
                        this.toast.success('', 'Task Updated');
                        if ( data.taskStatus === 'done' )
                        {
                            if ( this.router.url === '/home' )
                            {
                                this.taskEntityService.removeOneFromCache(this.taskData.id);
                            } else
                            {
                                this.taskEntityService.updateOneInCache({...data, id: data.id});
                            }
                        } else
                        {
                            this.taskEntityService.updateOneInCache({...data, id: data.id});
                        }
                        this.loadingProgress = false;
                        this.matDialogRef.close('Task Updated');
                    }, (error) => {
                        this.loadingProgress = false;
                        this.toast.error(error.error, 'Error');
                    });
                }
            }
        } else if ( this.taskForm.get('clinicId')?.errors )
        {
            this.toast.error('Please Select a group First', 'Error');
        } else if ( this.taskForm.get('description')?.errors )
        {
            this.toast.error('Please Enter Description', 'Error');
        }
    }

    removeAttachment($event: any): void
    {
        this.removedAttachment.push($event.signed_id);
        this._data.taskData.files = this._data.taskData.files?.filter(({signed_id}) => {
            return $event.signed_id !== signed_id;
        });
    }


    taskStatus(task: any): void
    {
        if ( task )
        {
            this.archived = true;
        }
        this.taskForm.patchValue({
            task: task
        });
    }

    subUserSelection(event, userIndex): void
    {
        if ( event.checked && this.selectedUsers.indexOf(this.subUserList[userIndex]) === -1 )
        {
            this.selectedUsers.push(this.subUserList[userIndex]);
        } else
        {
            userIndex = this.selectedUsers.findIndex(user => user.id === this.subUserList[userIndex].id);
            this.selectedUsers.splice(userIndex, 1);
        }
    }

    showCalendar(): void
    {
        this.calendarShow = !this.calendarShow;
        this.taskForm.patchValue({
            showCalendar: this.calendarShow
        });
    }

    showTask(): void
    {
        this.taskShow = !this.taskShow;
        this.taskForm.patchValue({
            showTask: this.taskShow
        });
    }

    intervalChange(event, index): any
    {
        if ( event.isUserInput )
        {
            if ( +index === 18 )
            {
                this.taskForm.patchValue({
                    startTime: '00:00'
                });
                this.allDaySelected = true;
            } else
            {
                this.allDaySelected = false;
            }
            this.intervalValueIndex = index;
        }
    }

    userSelectionReporter(event, userIndex): void
    {
        this.subUserChange = true;
        this.reporterObj = this.groupUserList[userIndex];
        this.subUserList = this.groupUserList.filter(item => item !== this.reporterObj);
        this.selectedUsers = this.selectedUsers.filter(item => item.id !== this.reporterObj.id);

        this.subUserList = this.subUserList.map((data: any) => {
            data.selectedUser = false;
            data.selectedUser = this.selectedUsers.filter(item => item.id === data.id).length > 0 ? true : false;
            return data;
        });

    }


    openGroupMenu(menuTrigger): void
    {
        menuTrigger.openMenu();
    }

    subMenuListener(): void
    {
        if ( this.dialogTitle === 'Select Group' )
        {
            this.toast.error('Please Select a Group First', 'Error');
        }
    }

    editAttachment(): void
    {
        this.showAttachment = !this.showAttachment;
    }

    private beforeSave(): void
    {
        let description = '';
        const des = this.taskForm.get('description')?.value?.trim() || '';
        const title = this.taskForm.get('title')?.value?.trim() || '';
        if ( title === '' && des === '' )
        {
            if ( this.attachmentsC?.attachedCount > 0 )
            {
                description = this.attachmentsC.attachedCount === 1 ? `${this.attachmentsC.attachments[0].file.name}` : `${this.attachmentsC.attachments[0].file.name} +${this.attachmentsC.attachments.length - 1}`;
                this.taskForm.patchValue({description});
            }

        } else if ( title === '' && des )
        {
            this.taskForm.patchValue({title: des.split(' ').slice(0, 5).join(' ')});
        }
    }

    deleteTask(): void
    {
        this._matDialog.open(DeleteConfirmationDialogComponent, {
            panelClass: 'deleteDialog',
            data      : {
                title  : this.taskData?.title,
                message: 'Are you sure you want to delete this Task?'
            }
        }).afterClosed().subscribe((res) => {
            if ( res[0] === 'yes' )
            {
                this.progressBarService.show();
                this.taskDataService.deleteTask(this.taskData.id).subscribe((data) => {
                    this.progressBarService.hide();
                    this.matDialogRef.close('Task Deleted');
                    this.toast.success('', data.message);
                    this.taskEntityService.removeOneFromCache(this.taskData.id);
                }, (error) => {
                    this.progressBarService.hide();
                    this.toast.error(error.errors.join(', '), 'Error');
                });
            }
        });
    }
}
