import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ThreadsEntityService } from '../../../store/thread/threads-entity.service';
import { ThreadsDataService } from '../../../store/thread/threads-data.service';

@Injectable()
export class DashboardResolve implements Resolve<any>
{
    constructor(private threadsEntityService: ThreadsEntityService, private threadDataService: ThreadsDataService)
    {
    }

    resolve(route: ActivatedRouteSnapshot): any
    {
        const taskData = {
            'clinic_filter': {
                'clinic_ids'  : [],
                'category_ids': [],
                'page'        : 1,
                'limit'       : 12,
            }
        };
        return forkJoin({
            thread: this.threadDataService.getDashboardThreads(taskData).pipe(
                tap((data: any) => {
                    this.threadsEntityService.clearCache();
                    return this.threadsEntityService.addManyToCache(data);
                })
            )
        }).pipe(tap(data => {
            return data;
        }));
    }
}
