<div id="timeline" class="p-24" fxLayout="row wrap">

    <div class="timeline-content" fxLayout="column" fxFlex="100">

        <carousel [arrowsOutside]="true">

                <div class="carousel-cell" *ngFor="let group of allGroupData">
                    <div *ngIf="!group.icon" routerLink = "/groups/{{group.id}}">
                <img *ngIf="group.file !== null" [src]="group?.file?.src" class="">
                <img class="" src="assets/images/Group/users-group.png"
                     *ngIf="group.file == null">
                <div class="bottom-left">
                    <div class="groupInfo">
                        <div><p>{{group.name}}</p></div>
                        <div *ngFor="let admin of group?.clinic_admins">
                            <mat-icon *ngIf="admin.id === currentUser.id">font_download</mat-icon></div>
                    </div>
                </div>
                    </div>
                    <div class="carouselFeature carousel-cell" *ngIf="group.icon === 'search' " [routerLink]="['/groups/all']">
                        <mat-icon class="mt-40">{{group.icon}}</mat-icon>
                        <p>{{group.title | translate}}</p>
                    </div><div class="carouselFeature carousel-cell" (click)="openGroupDialog()" *ngIf="group.icon === 'add' ">
                        <mat-icon class="mt-40">{{group.icon}}</mat-icon>
                        <p>{{group.title | translate}}</p>
                    </div>
                </div>
        </carousel>
        <mat-divider></mat-divider>

        <div>
            <mat-button-toggle-group #group="matButtonToggleGroup">
                <mat-button-toggle value="grid" (click)="viewMode='grid'" aria-label="Change to Grid View">
                    <mat-icon class="icon-color icon-size-grid">view_module</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="list" (click)="viewMode='list'" aria-label="Change to List View">
                    <mat-icon class=" icon-size-list icon-color" >view_headline</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <ng-container *ngIf="threads$ | async as threads">
            <div *ngIf="viewMode === 'grid'" fxLayout="row wrap " class="grid-container"  fxLayoutAlign="center" fxLayoutAlign.lt-md="center" >
                <store-grid-view   *ngFor="let thread of threads" [product]="thread" [@animate]="{value:'*',params:{y:'100%'}}">
                </store-grid-view>
            </div>
            <div *ngIf="viewMode === 'list'" fxLayout="column" fxLayoutAlign="center center" fxLayout.lt-sm="start start
">
                <store-list-view  *ngFor="let thread of threads" [product]="thread" [@animate]="{value:'*',params:{y:'100%'}}"></store-list-view>
            </div>
        </ng-container>
    </div>

</div>

