export class AppUtils
{
    public static videoContentType(contentType: string): string
    {
        if ( contentType === 'video/quicktime' )
        {
            return 'video/mp4';
        }
        return contentType;
    }

    public static preNameStatus(item: string): string
    {
        if ( item === 'standard_user' )
        {
            item = 'Standard User';
        } else if ( item === 'healthcare_professional' )
        {
            item = 'Store manager';
        } else if ( item === 'doctor' )
        {
            item = 'Store owner';
        }
        return item;
    }

    public static getGender(item: string|null): string|null
    {
        if ( item === 'M' )
        {
            item = 'Male';
        } else if ( item === 'F')
        {
            item = 'Female';
        } else {
            item = null;
        }
        return item;
    }

}
