<div  [ngSwitch]="type">
    <div *ngSwitchCase="'image'">
        <img class="img-height" [src]="item?.medium_url" alt="">
    </div>
    <div *ngSwitchCase="'file'">

        <div fxLayout="column" fxLayoutAlign="center stretch" class="p-12">
            <span class="main-title wrap">{{ item?.name }}</span>
            <span class="sub-title">{{'GALLERY.application' | translate}}</span>
            <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="p-12">
                <mat-icon class="s-50 icon">picture_as_pdf</mat-icon>
            </div>
            <div class="bb"></div>
        </div>

    </div>
    <div *ngSwitchCase="'video'">
        <div class="video">
            <div class="overlay">

            </div>
            <video disabled>
                <source [src]='item?.src' [type]="item?.content_type"/>
                {{'GALLERY.browser_not_supported' | translate}}
            </video>
        </div>
    </div>
</div>
