import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { LocalStorageService } from 'angular-web-storage';
import { Observable, Subject } from 'rxjs';
import { NewAuthService } from '../../service/api/newAuth.service';
import { RestService } from '../../service/api/rest.service';
import { Thread, ThreadAdapter, ThreadCommentAdapter } from './thread.model';
import { TaskAdapter } from '../task/task.model';

@Injectable()
export class ThreadsDataService extends DefaultDataService<Thread>
{
    private baseThread = 'docs';
    private baseThreadNotification = 'doc_notifications';
    private baseThreadComment = 'doc_comments';
    private baseClinic = 'clinics';
    private queue: any = [];
    public viewedDocIds: any = [];
    public docReadsRequests = new Subject<any>();
    isDocReadCalled = false;
    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private rest: RestService, private local: LocalStorageService,
                private baseService: NewAuthService, private threadAdapter: ThreadAdapter, private commentAdapter: ThreadCommentAdapter,
                private taskAdapter: TaskAdapter)
    {
        super('Thread', http, httpUrlGenerator);
        this.docReadsRequests.subscribe(async (readData) => {
            this.queue = [...this.queue, readData];
            if ( this.queue.length > 0 && !this.isDocReadCalled )
            {
                this.isDocReadCalled = true;
                for (const item of this.queue)
                {
                    await this.commentReadByUser(item).toPromise();
                }
                this.queue = [];
                this.isDocReadCalled = false;
            }
        });
    }

    add(params): any
    {
        return this.rest.post(`${(this.baseThread)}`, params).pipe(map(data => {
            const temp = this.threadAdapter.adapt(data);
            return {...temp, id: temp.id};
        }));
    }

    deleteThread(key): any
    {
        return this.rest.delete(`${(this.baseThread)}/${(key)}`).pipe(tap(data => {
            return data;
        }));
    }

    getDashboardThreads(params): any
    {
        const data = params;
        // data.clinic_filter.home_enabled = '1';
        return this.rest.post(`${this.baseClinic}/index_mobile_doc`, data).pipe(
            map(res => {
                const threadData: Thread[] = [];
                for (const item of res.clinic_docs)
                {
                    const temp = this.threadAdapter.adapt(item);
                    threadData.push(temp);
                }

                return threadData;
            })
        );
    }

    getByIdThreads(id: string, type: string): any
    {
        return this.rest.get(`${(this.baseThread)}/${id}`).pipe(map(data => {
            let temp;
            type === 'thread' ? temp = this.threadAdapter.adapt(data) : temp = this.taskAdapter.adapt(data);
            return {...temp, id: temp.id};
        }));
    }

    threadNotificationToggle(params): any
    {
        return this.rest.post(`${(this.baseThreadNotification)}`, params).pipe(tap(data => {
            return data;
        }));
    }

    postThreadComment(params): any
    {
        return this.rest.post(`${(this.baseThreadComment)}`, params).pipe(map(data => {
            return this.commentAdapter.adapt(data.doc_comment);
        }));
    }

    deleteThreadComment(id): any
    {
        return this.rest.delete(`${(this.baseThreadComment)}/${(id)}`).pipe(map(data => {
            return data;
        }));
    }

    updateThreadComment(id, param): any
    {
        return this.rest.put(`${(this.baseThreadComment)}/${(id)}`, param).pipe(map(data => {
            return data;
        }));
    }

    getThreadComments(threadID, param): Observable<any>
    {
        return this.rest.get(`${(this.baseThreadComment)}/${(threadID)}`, param).pipe(map(data => {
            return data;
        }));
    }

    getThreadCommentFiles(threadID, param): Observable<any>
    {
        return this.rest.get(`${(this.baseThreadComment)}/show_files/${(threadID)}`, param).pipe(map(data => {
            return data;
        }));
    }

    getUserClinicsWithCategories(): any
    {
        return this.rest.get(`${(this.baseClinic)}/user_clinics_with_categories`).pipe(tap(data => {
            return data;
        }));
    }

    createThread(params): any
    {
        return this.rest.post(`${(this.baseThread)}`, params).pipe(tap(data => {
            return this.threadAdapter.adapt(data);
        }));
    }

    updateThread(params, docId): any
    {
        return this.rest.put(`${(this.baseThread)}/${(docId)}`, params).pipe(map(data => {
            return this.threadAdapter.adapt(data);
        }));
    }

    getGroupThreads(params): any
    {
        return this.rest.post(`${this.baseClinic}/index_mobile_doc`, params).pipe(
            map(res => {
                const threadData: Thread[] = [];
                for (const doc of res.clinic_docs)
                {
                    const temp = this.threadAdapter.adapt(doc);
                    threadData.push(temp);
                }
                return threadData;
            })
        );
    }

    searchGroupThreads(params , id): any
    {
        return this.rest.get(`${this.baseClinic}/${id}/shop_specific_search`, params).pipe(
            map(res => {
                const threadData: Thread[] = [];
                for (const doc of res.products)
                {
                    const temp = this.threadAdapter.adapt(doc);
                    threadData.push(temp);
                }
                return threadData;
            })
        );
    }

    deleteFile(signedId: string): Observable<any>
    {
        return this.rest.delete(`${(this.baseThread)}/delete_attachment/${(signedId)}`);
    }

    deleteFileFromCommentFiles(signedId): Observable<any>
    {
        return this.rest.delete(`${(this.baseThread)}/delete_attachment/${(signedId)}` + '?deletion_enabled=true');
    }

    commentReadByUser(params): any
    {
        return this.rest.post(`doc_reads`, params);
    }

    getCommentReadList(id: number, page: number, type: string): any
    {
        return this.rest.get(`doc_reads/${id}?page=${page}&limit=20&type=${type}`);
    }
    shareProduct(params): any
    {
        return this.rest.post(`${(this.baseThread)}/share_product`, params);
    }
}
