export const locale = {
    lang: 'en',
    data: {
        'NAV'              : {
            'APPLICATIONS': 'Application',
            'DASHBOARD' : 'Home',
            'CHECKOUT' : 'Checkout',
            'NOTIFICATION' : {
                'TITLE': 'Notifications',
                'BADGE': ''
            },
            'NEWGROUP' : {
                'FINDGROUP': 'Find shop',
                'ADDGROUP': 'Create new shop',
            },
            'MESSAGES' : {
                'TITLE': 'Chat',
                'BADGE': ''
            },
            'CONTACTS' : {
                'TITLE': 'Contacts',
                'BADGE': ''
            },
            'OTHER' : 'Other',
            'Quick-Access' : 'Follows',
            'COMMUNITY' : 'Community',
            'GROUP' : 'Shops'
        },
        DASHBOARD: {
            WELCOME_BACK  : 'Welcome Back',
            budget_summary: 'Budget Summary',
            HOME          : 'Home',
        },
        TIMELINE: {
            write_something_here: 'Write Something Here...',
            mention_somebody: 'Mention Somebody',
            add_photo: 'Add Photo',
            add_location: 'Add Location',
            post: 'Post',
        },
        CHECKOUT: {
            basket: 'Basket',
            godkendt: 'Approved',
            payment: 'Payment',
            confirmation: 'Confirmation',
            continue: 'Continue',
            accept: 'I agree to purchase and accept',
            terms_and_conditions: 'terms and conditions.',
            delete_all: 'Delete all items in this basket',
            description: 'Description',
            single_price: 'Single Price',
            number: 'Number',
            price: 'Price',
            not_reserved: '*the items in the basket cannot be reserved before the purchase is completed.',
            total_purchase: 'Total purchase of goods incl. VAT:',
            fragt: 'Freight:',
            back: 'Back',
            receive_mobile_pay_1: 'You receive a mobilepay charge from the individual store',
            receive_mobile_pay_2: 'and can follow the status of the payment here on the page',
            click_here_to_pay: 'Click here to pay',
            read_here: 'Read here',
            thank_you_for_order: 'Thank you for your order',
            order_number_is_1: 'Your order number is ',
            order_number_is_2: ' and you will receive an email from the store as soon as the order has been confirmed and dispatched.',
            no_items: 'No Items found in basket. Please Add some before proceeding to checkout.',
        },
        DASHBOARD_TASK: {
            filter_tasks: 'Filter Tasks',
            edit: 'Edit',
            delete: 'Delete',
        },
        post: {
            thread_notification_on: 'Product Notification On',
            thread_notification_off: 'Product Notification Off',
            post_comment: 'Post Comment',
            add_comment: 'Add a Comment',
            update_comment: 'Update Comment',
            time_limited: 'Timelimited',
            just_notify_followers: 'Notify Followers',
            offer: 'Offer',
            limited_number: 'Limited number',
            show: 'Show',
            more_comments: 'More Comments',
            show_more: '... Show More',
            show_less: ' Show less'
        },
        TASKS: {
            file: 'Files',
            comment: 'Comments',
        },
        NOTIFICATIONS: {
            notification: 'Notifications',
            select_notification_to_view: 'Select a notification to read the content!'

        },
        TOOLBAR: {
            new_version: 'A new update is available, please<a> click here </a>to view...',
            invite_to_group: 'Invite To Shop',
            create_group   : 'Create Shop',
            create_task    : 'Create Task',
            create_contact   : 'Create Contact',
            my_profile     : 'My Profile',
            inbox          : 'Inbox',
            logout         : 'Logout',
            no_shortcuts_yet: 'No Shortcuts yet!',
            click_to_add_remove_shortcut : 'Click to Add/Remove Shortcuts.',
            remove: 'Remove',
            cancel: 'Cancel',
            confirm: 'Confirm',
            update: 'Update'
        },
        CHAT: {
            select_contact_to_chat: 'Select a contact to start a chat!',
            search_or_start_chat: 'Search or Start new chat',
            start_conversation_by_typing: 'Start a conversation by typing your message below.',
            select_contact_to_conversation: 'Select a contact to start a conversation!',
            type_your_message: 'Type your Message',
            accept_connection_request: 'Accept Connection Request',
            accept_connection: 'Accept Connection',
            reject_connection: 'Reject Connection',
            send_connection: 'Send Connection',
            remove_connection: 'Remove Connection',
            delete_chat: 'Delete Chat',
            send_message: 'Send Message',
            away: 'away',
            do_not_disturb: 'Do Not Disturb',
            mood: 'Mood',
            other_users: 'Other Users',
            contact_info: 'Contact Information',
            date_of_birth: 'Date Of Birth',
            gender: 'Gender',
            websites_links: 'Websites & Links',
            mobile_phone: 'Mobile Phone',
            mobile_no: 'Mobile Number'
        },
        GROUPS : {
            welcome : 'WELCOME TO SHOPS',
            sub_welcome : 'Shops will help you through the process of building a community or a team, ' +
                ' or you can use it as your personal space to collect, order and plan you digital stuff..',
            filter_group_by : 'Filter shops by name...',
            all : 'All',
            valid_till : 'You are currently using a free trial period for pro shops, valid till',
            your_groups: 'Your Shops',
            other_groups: 'Other shops',
            go_to_group: 'Go to Shop',
            no_group_found: 'No Shop Found',
            search_for_group: 'Search for a Shop',
            patient_caller_slide: 'Caller Slide',
            slide_show: 'Slides',
            day: 'Day',
            hours: 'Hours',
            minutes : 'Minutes',
            seconds : 'Seconds',
            week: 'Week',
            month: 'Month',
            become_admin: 'Become An Admin',
            group_request: 'Shop Request',
            group_member: 'Shop Members',
            members: 'Members',
            leave_group: 'Leave Shop',
            send_group_email: 'Send Shop Email',
            setting: 'Settings',
            select_room: 'Select Room',
            enter_name: 'Enter Name',
            reset_form: 'Reset Form',
            patient_name: 'Patient Name',
            delete_patient_caller: 'Delete Patient Caller',
            edit_patient_caller: 'Edit Patient Caller',
            time: 'Time',
            caller: 'Caller',
            room : 'Room',
            create_post: 'Create Post',
            timeline: 'Timeline',
            task_management: 'Tasks',
            planning: 'Calender',
            contact: 'Contacts',
            files: 'Files',
            membership: 'Follow',
            only_administrator_can_approve: 'Admins',
            all_members_can_approve: 'All Members',
            visible_to_everyone: 'Visible',
            visible_to_everyone_tooltip: 'Shows in lists but only members can access',
            not_visible_to_everyone: 'Not Visible',
            not_visible_to_everyone_tooltip: 'Only invited users can access',
            group_visibility: 'Shop Visibility',
            visibility: 'Visibility',
            hidden: 'Hidden',
            slider: 'Slider',
            task_control_ring: 'Task Control Ring',
            all_mem_can_approve: 'All members can approve.',
            profile_form: 'Profile Form',
            group_profile: 'Shop profile',
            group_security: 'Security',
            group_plan_billing: 'Plan & Billing',
            group_Invoices: 'Invoices',
            group_spendings: 'Usage',
            group_settings: 'Settings',
            group_allow_sharing: 'Allow sharing',
            group_profile_desc: 'Manage the shops expression and identity',
            group_security_desc: 'Manage rights and visibility',
            group_plan_billing_desc: 'Manage shops and inventory plan, as well as payment and invoice information',
            group_invoices_desc: 'Manage and view all shops invoices',
            group_spendings_desc: 'Get an overview of the shop\'s data consumption',
            group_setting_desc: 'Manage opportunities and limitations',
            allow_large_file_desc: 'Allow users to upload large files. If allowed there will be no size limitation. Be aware that this can increase the shop\'s storage consumption.',
            allow_sharing_desc: 'Allow users to share product content and contacts from the shop. Userspecific info such as user info and comments are not shared.',
            group_privacy: 'Privacy',
            group_visible_desc: ' The shop will be sight in search and anyone can apply for follow',
            group_hidden_desc: 'The shop will be hidden. Only invited users will have access',
            group_membership_desc: 'Only shop administrators can approve members',
            group_members_desc: 'Everyone in the shop can approve new ones\n' + 'members',
            group_profile_warning: 'The following information is displayed publicly, be carefull',

            economy: 'Economy',
            save: 'Save',
            invoice_overview: 'Invoice overview',
            zip_code: 'Zip code',
            city: 'City',
            graphic: 'Graphic\n',
            module_settings: 'MODULE SETTINGS',
            slide_settings: 'Slide Settings',
            caller_settings: 'Caller Settings',
            planning_settings: 'Planning',
            background_color: 'Background Color',
            add_more: 'Add More',
            cannot_be_empty: 'cannot be empty.',
            group_approved_by : 'Follow approval by',
            show_on_dashboard: 'Show On Dashboard',
            create_new_group: 'Create New Shop',
            go_today: 'Today',
            header: 'Ouups!',
            not_accessible: 'Link is not accessable.',
            content: 'The page you are trying to reach does not exist or the page can not be accessed. This might be due to the security settings of the page or shop.',
            my_docs: 'MY DOCS',
            my_timeline: 'My Timeline',
            my_files: 'My Files',
            my_calendar: 'My Calendar',
            my_task: 'My Tasks',
            my_contact: 'My Contacts',
            followers: 'Followers',
        },
        SEARCH : {
            search: 'Search',
            go_to_group: 'Go To Shop',
            cancel_membership: 'Cancel Follow',
            request_membership: 'Request Follow',
            follow: 'Follow',
            search_folders: 'Search Categories',
            search_in_folders: 'Search Category',
            search_files: 'Search Files',
            no_search_folders: 'No Folders Found',
            show_more: 'Show More',
            no_results: 'No Results Found',
            groups: 'Shops',
            search_for_app_page: 'Search for an app or a page',
            no_group_folder_selected : 'No Shop or Folder selected.',
            threads: 'Products',
        },
        THREAD: {
            priority: 'Highlight (optional)',
            show_destination: 'Show destination confirmation pop up',
            save: 'Create',
            update : 'Update',
            close : 'Close',
            description: 'Description',
            description_req: 'Description is Required',
            attachments: 'Attachments',
            add_attachments: 'Add an Attachment',
            where_to_create_thread: 'Where to create the product',
            where_to_update_thread: 'Where to update the product',
            your_thread_will_be_created: 'Your Product will be created in here..',
            your_thread_will_be_updated: 'Your Product will be updated in here..',
            location_group: 'Location (Shop)',
            change_group: 'Change Shop',
            create_thread: 'Create Product',
            update_thread: 'Update Product',
            dont_show_this_confirmation_page: 'Don\'t show this confirmation page',
            title: 'Title',
            share: 'Share',
            choose_group: 'Choose shop',
            fixed: 'Fixed',
            all_group: 'All Shops',
            search_for_group: 'Search for shop',

        },
        PRODUCT: {
            search_product: 'Search product',
            add_to_the_basket: ' Add to basket',
            add_to_basket: 'ADD TO BASKET',
            price: 'Price',
            product_info: 'Product info',
            category: 'Category',
            quantity: 'Quantity',
            size_color: 'Size/Colour',
            invalid_error: 'Price should be less than 10,000,000',
        },
        SLIDE : {
            one: '2 x small content',
            sec_one: '2 x small icons',
            two: '2 x large content',
            sec_two: '1 x small icons',
            three: '1 x small content',
            sec_three: '1 x small icons',
            four: '1 x small content',
            sec_four: '',
            five: 'Only Image',
            sec_five: 'Background only',
            six: 'Only Video',
            sec_six: 'Background only',
            above: 'Above',
            below: 'Below',
            image: 'Billede',
            colors: 'Colors',
            schedule: 'Schedule',
            end: 'End',
            slide_message : 'Slide Message is required',
            start_time: 'Start Time',
            slide_start_message: 'Slide Message Start Time is required!',
            end_time: 'End Time',
            slide_end_message: 'Slide Message End Time is required!',
            select_background: ' Select Background',
            select_template_type: 'Please Select a Template Type.',
            select_template: 'Select Template',
            next: 'Next',
            fill_out_slide: 'Fill out Slide Detail',
            background: 'Background',
            text_background: 'Text Background',
            text_background_color : 'Text Background Color is required!',
            text_color: 'Text Color',
            text_color_req: 'Text Color is required!',
            text_size: 'Text Size',
            text_size_req: 'Text Size is required!',
            choose_file: 'Choose File',
            title : 'Title',
            slide_title_req_1: 'Slide 1 Title is required!',
            slide_title_req_2: 'Slide 2 Title is required!',
            content : 'Title',
            slide_content_req_1: 'Slide 1 Message is required!',
            slide_content_req_2: 'Slide 2 Message is required!',
            previous : 'Previous',
            is_required: 'is required',
            slide_required: 'Schedule is required',
            date: 'Date',
            select_all: 'Select All',
            unselect_all: 'Unselect All',
            odd_week: 'Odd Week',
            even_week: 'Even Week',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            share_with_others: 'Share with others (public)',
            finish: 'Finish',
            font_size: 'Font Size',
            date_font: 'Date Font',
            request: 'Request',
            only_visible_to_friends: 'Only Visible To Friends.',
            additional: 'Additional',
            basic: 'Basic',
        },
        TASK_MANAGEMENT : {
            today_only: 'Today Only',
            today_forward: 'Today and Forward',
            today_past: 'Today and Past',
            archive: 'Archive',
            user: 'USERS',
            sub_user: 'Sub User',
            task_status: 'Task Status',
            summary: 'Summary',
            show_in_tasks: 'Show in tasks',
            show_in_calender: 'Show in Calender',
            add_task: 'Add a task',
        },
        CONTACT: {
            name: 'Name',
            groupName: 'Shopname',
            invoiceName: 'Invoice name (OR full name)',
            invoice_name_error: 'Invoice name is required',
            number: 'Number',
            contact: 'Contact',
            group: 'Shop',
            groups: 'Shop',
            address: 'Address',
            add: 'Add',
            create: 'Create'
        },
        FOOTER: {
            changelog: 'Changelog',
            documentation: 'Documentation'
        },
        CATEGORIES : {
            move_up: 'Move Up',
            move_down: 'Move Down',
            delete: 'Delete',
            new_folder: 'New Folder',
            folder_management: 'Category Management',
            name_folder: 'Name new Category',
        },
        GROUP_INVITATION: {
            group_invitation_text : 'Enter the Email address of the person you want to invite, write a personal' +
            ' greeting and select at the bottom what you want to invite. (Tip: You can invite more at one time by' +
            ' entering multiple Email addresses)',
            message: 'Message',
            send : 'Send',
            invite_to : 'Invite to',
            text: 'Text',
            message_interval: 'Message Font',
            or: 'Or',
            personal_message: 'Add Personal message (optional)',
            invite : 'Invite',
        },
        GALLERY: {
            browser_not_supported: 'Browser not supported',
            download: 'Download',
            delete: 'Delete',
            application: 'Application',
            size: 'Size',
            load_more: 'Load More',
            image: 'Image',
            actions: 'Actions',
        },
        SETTINGS: {
            activate_pro: 'Activate PRO',
            storage_settings: 'Storage Settings',
            storage: 'Storage',
            allow_large_files: 'Allow Large Files',
            payment_profile: 'Payment Profile PRO',
            number_already_exist: 'Number Already Exist.',
            add_room: 'Add Room',
            screen_width: 'Screen Width',
            screen_height: 'Screen Height',
            slide_delay_settings: 'Slides Delay(Seconds)',
            animation: 'Animation Speed(Seconds)',
            background_music: 'Background Music',
            date_time: 'Date & Time',
            add_user: 'Add User',
            user_role: 'User Role',
            yes: 'Yes',
            no: 'No',
            users: 'Users',
            accept_to_delete_all : 'I approve to permanently delete all content.',
            user: 'User',
            keep_user_threads: 'Keep User Products?',
            this_field: 'This Field',
            filter_users: 'Filter Users',
            accept: 'Accept',
            reject: 'Reject',
            active: 'Active',
            size: 'Size',
            group_image: 'Shop Image',
            phone: 'Phone',
            winter: 'Winter',
            summer: 'Summer',
            end_date: 'End Date',
            start_date: 'Start Date',
            spring: 'Spring',
            fall: 'Fall',
            show: 'Show',
            record_time: 'Record Time',
            patient_section: 'Caller Section',
            room: 'Room',
            section: 'Section',
            call_patient: 'Caller',
            video_call: 'Video Call',
            width: 'Width',
            patient_name: 'Patient Name',
            play: 'Play',
            time_interval: 'Time interval',
            create_folder : 'Create Folder',
            update_folder: 'Update Folder',
            select_group: 'Select Shop',
            modules: 'MODULES',
            group_settings: 'SHOP SETTINGS',
            folder: 'Folders',
            domain: 'Domain',
            country: 'Country',
            cvr_number: 'CVR/SE Number',
            on: 'ON',
            off: 'OFF'
        },
        LOGIN : {
            register_placeholder: 'Here you decide the weather forecast yourself and never have problems with parking. Sit back and relax while you stroll around the pages and see what the local shops have on the shelves and what\'s on sale.',
            login_placeholder: 'The platform that will help you through the process of building a community or a team, ' +
                ' or you can use it as your personal space to collect, order and plan you digital stuff.',
            reactivate_account : 'Reactivate Account',
            unlock: 'Unlock',
            password: 'Password',
            are_you_not: 'Are you not',
            inactivity: 'Due to inactivity, your session is locked. Enter your password to continue.',
            session_loked: 'YOUR SESSION IS LOCKED',
            join_using: 'Keep in touch with all your WEBSHOP stuff at the palm of your hand.',
            get_app: 'Get the APP!',
            create_new_password: 'CREATE NEW PASSWORD',
            save_my_password: 'SAVE MY PASSWORD',
            reactivate_your_account: 'REACTIVATE YOUR ACCOUNT',
            email_sent_to: ''
        },
        PROFILES: {
            personal_information: 'Personal Information',
            change_password: 'Change Password',
            security: 'Security',
            inactivity_limit: 'Inactivity Limit',
            set_limit_before_logout : 'Set minutes before logout',
            deactivate_account : 'Deactivate Account',
            delete_account : 'Delete Account',
            add_number: 'Add Number',
            mobile_matching: 'Mobile Number should not be matching other Mobile Numbers.',
            mobile_empty: 'Mobile Number cannot be empty',
            invalid_phone: 'Invalid Phone Number',
            number_greater: 'Phone number cannot be greater than 10 digits.',
            phone_less: 'Phone number cannot be less than 7 digits.',
            number_already: 'Number already exists.',
            address_empty: 'Address cannot be empty.',
            invalid: 'Invalid',
            email_empty: 'Email cannot be empty',
            email_exist: 'Email already Exist',
            primary: 'Primary',
            change: 'Change',
            add_website: 'Add Website',
            website_empty: 'Websites cannot be empty',
            not_available: 'Not Available',
            cancel_all: 'Cancel All',
            basic_information: 'Basic Information',
            profession: 'Profession',
            friends: 'Friends',
            requests: 'Requests',
            joined_groups: 'Joined Shops',
            current: 'Current',
            new: 'New',
            confirm_new: 'Confirm New',
            must_match : 'Must Match',
            friend_requests: 'Friend Requests'
        },
        BROWSER_COMPATIBILITY : {
            browser_compatibility: 'Browser Compatibility',
            browser_message: 'Looks like you are using old browser, use Modern Browser or Google Chrome to view the complete features.'
        },
        VERSION_UPDATED : {
            new_version: 'A newer version of OnlineDoc is available. Click YES to run newest version.',
            version: 'Version Update'
        },
        SUSPENDED: {
            group_suspended      : 'Shop suspended on',
            suspended_description: 'Suspended due to missing payment.',
            contact_admin        : 'Please make your payment as soon as possible to prevent permanent data loss, or if you feel this\n' +
                '                to be an error, <a href="mailto:{{support@onlinedoc.dk}}">contact support.</a>',
            contact_member       : 'To prevent permanent data loss <a (click)="openSettingsBy()" id="contact_member">contact this shops SuperAdmin</a>\n' +
                '                for further information.',
            make_payment         : 'Make payment now',
            contact_super_admin  : 'Contact SuperAdmin'
        },
        STORAGE_SETTINGS: {
            storage_plan              : 'Storage Plan',
            current_plan_1            : 'You can change your Storage',
            current_plan_2            : 'Plan to meet your storage needs',
            current_plan_3_a          : 'Choose your',
            current_plan_3_b          : 'Shop Plan',
            current_plan_3_c          : 'to meet',
            current_plan_4            : 'your needs in regards of',
            current_plan_5            : 'functions and modules',
            change_storage_plan       : 'Change storage plan',
            cancel_subscription       : 'Cancel Subscription',
            total_storage_space       : 'Total Storage Space',
            usage_by_type             : 'Usage By Type',
            type                      : 'Type',
            videos                    : 'Videos',
            images                    : 'Images',
            changeImmediatley         : 'If you are downgrading in the middle of a paid period you will not be refunded any payment made for the active period.If you are upgrading in the middle of a period, you will be charged for the full period.',
            files                     : 'Files',
            size                      : 'Size',
            used_storage              : 'used_storage',
            available_storage         : 'available_storage',
            group_plans               : 'Shop Plans',
            options                   : 'Options',
            free                      : 'FREE',
            switch_to                 : 'SWITCH TO',
            active                    : 'Active',
            current                   : 'Current',
            free_storage_included     : '10 GB storage included',
            timeline                  : 'Timeline',
            file_explore              : 'File explore',
            task                      : 'Task',
            calender                  : 'Calender',
            slideshow                 : 'Slideshow',
            caller                    : 'Caller',
            custom_integrations       : 'Custom integrations',
            third_part_software_ect   : '(3rd part software ect.)',
            get_started               : 'GET STARTED',
            support_via_email_and_chat: 'Support via Email & Chat',
            contact_sales             : 'Contact Sales',
            basic                     : 'Basic',
            shift                     : 'Shift',
            invoiceInformation        : 'Invoice information',
            private                   : 'Private',
            corporate                 : 'Corporate',
        },
        PAYMENT_INFO: {
            payment_profile            : 'Payment Profile',
            payment_profile_id         : 'Payment Profile Id',
            account_type               : 'Account Type',
            company_address            : 'Company name and address',
            corporation                : 'Corporation',
            not_available              : 'Not Available',
            profile_id_info            : 'The payment profile ID is the unique number we use to identify the legal entity responsible for the payment. The legal entity is the organization or individual registered with this payment account.',
            account_type_info          : 'Your payment profile and your country determine which fees are included in your collection and what types of documents you receive.',
            group_info                 : 'This is the legally registered information, which is indicated on invoices.',
            payment_method             : 'Payment Method',
            no_payment_method_found    : 'No card found',
            add_payment_method         : 'Add Payment Method',
            edit_payment_method        : 'Edit Payment Method',
            primary_payment_method     : 'Primary Payment Method',
            make_primary_payment_method: 'Make Primary Payment Method',
            remove_payment_method      : 'Remove Payment Method',
            payment_summary_invoices   : 'Payment Summary (Invoices)',
            pdf_invoice                : 'PDF-invoice',
            csv_invoice                : 'CSV-invoice',
            no_invoices_found          : 'No invoices found',
        },
        loading: 'loading',
        'All' : 'All',
        'Requests' : 'Requests',
        'User' : 'Users',
        'Profile': 'Profile',
        'Slider Settings' : 'Slider Settings',
        'Caller Settings' : 'Caller Settings',
        'Slider Settings > Date & Time' : 'Slider Settings > Date & Time',
        'Slider Settings > Season Schedule' : 'Slider Settings > Season Schedule',
        'Caller Settings > Caller Section (Board)' : 'Caller Settings > Caller Section (Board)',
        'Caller Settings > Room Edit' : 'Caller Settings > Room Edit',
        'Caller Settings > Voice Call' : 'Caller Settings > Voice Call',
        'Caller Settings > Room Section' : 'Caller Settings > Room Section (Board)',
        'Select Group' : 'Select Shop',
        'Caller Settings > Scroll Message': 'Caller Settings > Scroll Message',
        'Caller Settings > Caller' : 'Caller Settings > Caller',
        'Caller Settings > Date Time' : 'Caller Settings > Date Time',
        'OFFER': 'Offer',
        'LIMITED_NUMBER': 'Limited number',
        'TIME_LIMITED': 'Timelimited',
        'JUST_NOTIFY_FOLLOWERS': 'Just notify followers',
        'Today and Past' : 'Today and Past',
        'Today Only' : 'Today Only',
        'Today and Forward': 'Today and Forward',
        'Delete ' : 'Delete ',
        'Are you sure you want to delete this shop?': 'Are you sure you want to delete this shop?',
        'hours ago': 'hours ago',
        'CREATE AN ACCOUNT': 'CREATE AN ACCOUNT',
        'LOGIN TO YOUR ACCOUNT': 'LOGIN TO YOUR ACCOUNT',
        'Full Name': 'Full Name',
        'Email': 'Email',
        'Phone': 'Phone',
        'Gender': 'Gender',
        'Password': 'Password',
        'Password (Confirm)': 'Password (Confirm)',
        'Accept': 'Accept',
        'terms and conditions': 'terms and conditions',
        'Already have an account?': 'Already have an account?',
        'Login': 'Login',
        'Login Guest': 'Login',
        'Login Modal': 'Login',
        'Requires login': 'Requires login',
        'Your shopping basket is empty': 'Your shopping basket is empty',
        'To perform this action, you need to ': 'To perform this action, you need to',
        'Welcome' : 'Welcome',
        // PRODUCT MODAL
        'Show in the marketplace': 'Show in the marketplace',
        // 'LOGIN': 'LOG PÅ',
        'Login Intro': 'Welcome to the Herning area\'s digital walking street.\n\nHere you decide the weather forecast yourself and never have problems with parking. Sit back and relax while you stroll around the pages and see what the local shops have on the shelves and what\'s on sale.\n' +
            '\n' +
            'If you want to engage in dialogue with the staff in the stores, you can write directly to them here at the Herning webshop. This simply requires that you are logged in, so they know who they are writing with.',
        'Welcome to the ONLINE': 'Welcome to the ONLINE',
        'Welcome to the WEBSHOP': 'Welcome to the WEBSHOP',
        'Remember Me': 'Remember Me',
        'Continue Without Login': 'Continue without login',
        'Forgot Password?': 'Forgot Password?',
        'Male': 'Male',
        'Female': 'Female',
        'Gender is required!': 'Gender is required!',
        'OR': 'or',
        'RECOVER YOUR PASSWORD': 'Recover your password',
        'Go back to login': 'Go back to login',
        'SEND RESET LINK': 'Send Reset Link',
        'Confirm your email address!': 'Confirm your email address!',
        'A reset password e-mail has been sent to': 'A reset password e-mail has been sent to',
        'Check your inbox and click on the "Confirm my email" link to confirm your email address.': 'Check your inbox and click on the "Confirm my email" link to confirm your email address.',
        'Check your inbox and click on the "Reset Your Password" link to reset your account password.': 'Check your inbox and click on the "Reset Your Password" link to reset your account password.',
        'Go back to login page': 'Go back to login page',
        'A reset password e-mail has been sent.': 'A reset password e-mail has been sent.',
        'Hey! Thank you for checking out our app.': 'Hey! Thank you for checking out our app.',
        'Please validate your account details by insert code received in email/phone or click on link available in email.': 'Please validate your account details by insert code received in email/phone or click on link available in email.',
        'Please insert confirmation code received from your email or phone:': 'Please insert confirmation code received from your email or phone:',
        'Code': 'Code',
        'Code is required': 'Code is required',
        'Please insert complete code': 'Please insert complete code',
        'invalid or expired code': 'invalid or expired code',
        'Verify': 'Verify',
        'If you would like to resend email please type your email below:': 'If you would like to resend email please type your email below:',
        'RE SEND': 'Resend',
        'Your Account is already confirmed.': 'Your Account is already confirmed.',
        'A confirmation e - mail has been sent to': 'A confirmation e-mail has been sent to',
        'You might need to check the spam folder.' : 'You might need to check the spam folder.',
        'The code has been send to' : 'The code has been send to',
        'Maximum numbers should be 8.' : 'Maximum numbers should be 8.',
        'Minimum numbers should be 8.' : 'Minimum numbers should be 8.',
        'Maximum inactivity limit is 1500.': 'Maximum inactivity limit is 1500.',
        'Minimum inactivity limit is 5.': 'Minimum inactivity limit is 5.',
        'Threads are showing on Dashboard' : 'Products are showing on Dashboard',
        'Threads are hidden on Dashboard' : 'Products are hidden on Dashboard',
        'Sunday': 'Sunday',
        'Monday': 'Monday',
        'Tuesday': 'Tuesday',
        'Wednesday': 'Wednesday',
        'Thursday': 'Thursday',
        'Friday': 'Friday',
        'Saturday': 'Saturday',

        // error messages

        'Full Name is required': 'Phone is requied',
        'Email is required': 'Email is required',
        'Please enter a valid email address': 'Please enter a valid email address',
        'Phone is required': 'Phone is required',
        'Phone is invalid': 'Phone is invalid',
        'Password is required': 'Password is required',
        'Full Name is invalid': 'Full Name is invalid',
        'Password confirmation is required': 'Password confirmation is required',
        'Email already exists': 'Email already exists',
        'Passwords must match': 'Passwords must match',
        'Do not have an account?': 'Do not have an account?',
        'Create an account': 'Create an account',
        'Invalid username or password.' : 'Invalid username or password.',
        'Password should be at least 6 characters long.': 'Password should be at least 6 characters long.',
        'No associated record with this email exists.': 'No associated record with this email exists.',
        'Enter valid email address.': 'Enter valid email address.',

        'First Name' : 'First Name',
        'Last Name' : 'Last Name',
        'Date of Birth' : 'Date of Birth',
        'Male/Female' : 'Male/Female',
        'Contact Number' : 'Contact Number',
        'Confirm Password' : 'Confirm Password',
        'Sign Up' : 'Sign Up',
        'User Name' : 'User Name',
        'Your Password' : 'Your Password',
        'Keep me logged in' : 'Keep me logged in',
        'Sign In' : 'Sign In',
        'Forgot Password' : 'Forgot Password',
        'You can reset your Password here' : 'You can reset your Password here',
        'Send' : 'Send',
        'What type of user of you?' : 'What type of user of you??',
        'Doctor/ Health Staff/ Citizen' : 'Doctor/ Health Staff/ Citizen',
        'Update' : 'Update',
        'What are you up to?' : 'What are you up to?',
        'Photos/Camera/Documents' : 'Photos/Camera/Documents',
        'Search Filter' : 'Search Filter',
        'Search in Files' : 'Search in Filter',
        'Search in Comments' : 'Search in comments',
        'Search in Threads' : 'Search in Products',
        'Edit/ Delete' : 'Edit/ Delete',
        'Write a comment' : 'Write a comment',
        'Document List' : 'Document List',
        'Send Email' : 'Send Email',
        'Select Users' : 'Select Users',
        'Subject' : 'Subject',
        'Write your message here' : 'Write your message here',
        'Find Users' : 'Find Users',
        'Search Users' : 'Search Users',
        'Selected Users' : 'Selected Users',
        'Attach Files' : 'Attach Files',
        'Invite' : 'Invite',
        'Group Members' : 'Shop Members',
        'Send Message' : 'Send Message',
        'Delete Member' : 'Delete Member',
        'Settings' : 'Settings',
        'Save' : 'Save',
        'Write your group description here' : 'Write your shop description here',
        'Privacy' : 'Privacy',
        'Public' : 'Public',
        'Hidden' : 'Hidden',
        'Folder (Categories)' : 'Folder (Categories)',
        'Category Name' : 'Category Name',
        'Add Category' : 'Add Category',
        'Delete Group' : 'Delete Shop',
        'Send Invitation' : 'Send Invitation',
        'Invite to' : 'Invite to',
        'No user is registered with this email. Do you want to send invite to this email?': 'No user is registered with this email. Do you want to send invite to this email?',
        'No/ Yes' : 'No / Yes',
        'Chat' : 'Chat',
        'Connections' : 'Connections',
        'Other' : 'Other',
        'Day/Months/Year' : 'Day/Months/Year',
        'Few seconds/minutes/hours ago' : 'Few seconds/minutes/hours ago',
        'Accept Request/ Cancel' : 'Accept Request/ Cancel',
        'Create Thread' : 'Create Product',
        'Thread Title' : 'Product Title',
        'Thread description' : 'Product description',
        'Add Users' : 'Add Users',
        'Explore Users/Groups' : 'Explore Users/Shops',
        'Awaiting Approval' : 'Awaiting Approval',
        'Go to Group' : 'Go to Shop',
        'Create Group' : 'Create Shop',
        'Group Title' : 'Shop Title',
        'Next/Done' : 'Next/Done',
        'Select Categories' : 'Select Categories',
        'Create Categories' : 'Create Categories',
        'Can be managed and created late, user settings Categories are used in forums and file management, for the indvidual shop or clinic.' : 'Can be managed and created late, user settings Categories are used in forums and file management, for the indvidual shop or clinic.',
        'Logout/ Settings/ Invite' : 'Logout/ Settings/ Invite',
        'Contact Information' : 'Contact Information',
        'Basic Information' : 'Basic Information',
        'Website and Links' : 'Website and Links',
        'Mobile' : 'Mobile',
        'Address' : 'Address',
        'My Group History' : 'My Shop History',
        'Thread History' : 'Product History',
        'Read More' : 'Read More',
        'Account Settings' : 'Account Settings',
        'Change Password' : 'Change Password',
        'New Password' : 'New Password',
        'Update Password' : 'Update Password',
        'Inactivity Settings (Set Minutes before logout)' : 'Inactivity Settings (Set Minutes before logout)',
        'Language' : 'Language',
        'Delete Account' : 'Delete Account',
        'Deactivate Account' : 'Deactivate Account',
        'Delete/ Disable' : 'Delete/ Disable',
        'Warning You are about to delete group and  all its content. When deleting this,  All data in Follow will be deleted and can NOT be restored!' : 'Warning You are about to delete shop and  all its content. When deleting this,  All data in shop will be deleted and can NOT be restored!',
        'I Confirm that I understand that all data will be deleted with NO  option to restore.' : 'I Confirm that I understand that all data will be deleted with NO  option to restore.',
        'What You want to Delete Remove and Delete all the files  from this user. Remove and Keep the files from this user.' : 'What You want to Delete Remove and Delete all the files  from this user. Remove and Keep the files from this user.',
        'Cancel/Okay' : 'Cancel/Okay',
        'Please Enter your Password' : 'Please Enter your Password',
        'User deleted Successfully' : 'User deleted Successfully',
        'Thread created Successfully' : 'Product created Successfully',
        'Group created Successfully' : 'Shop created Successfully',
        'Message sent Successfully' : 'Message sent Successfully',
        'Message deleted Successfully' : 'Message deleted Successfully',
        'Thread deleted Successfully' : 'Product deleted Successfully',
        'Thread updated Successfully' : 'Product updated Successfully',
        'Comment sent Successfully' : 'Comment sent Successfully',
        'Comment deleted' : 'Comment deleted',
        'Comment updated' : 'Comment updated',
        'Invitation sent Successfully' : 'Invitation sent Successfully',
        'Friend request sent Successfully' : 'Friend request sent Successfully',
        'Request canceled' : 'Request canceled',
        'Membership request sent' : 'Follow request sent',
        'Membership request canceled' : 'Follow request canceled',
        'Group mail sent Successfully' : 'Shop mail sent Successfully',
        'Invalid username or password' : 'Invalid username or password',
        'Please check your email for Account Confirmation' : 'Please check your email for Account Confirmation',
        'Additional Information' : 'Additional Information',
        'Long Press for Delete Link' : 'Long Press for Delete Link',
        'Please enter valid URL' : 'Please enter valid URL',
        'User can add further description about himself' : 'User can add further description about himself',
        'Please Enter your Email and Password' : 'Please Enter your Email and Password',
        'Please fill all the fields' : 'Please fill all the fields',
        'Password does not match' : 'Password does not match',
        'You are not connected with internet' : 'You are not connected with internet',
        'User deactivated Successfully' : 'User deactivated Successfully',
        'This user is deactivated, click here here to send activation mail' : 'This user is deactivated, click here here to send activation mail',
        'Who can add files?' : 'Who can add files?',
        'All members can/ Only I can' : 'All members can/ Only I can',
        'Recipients of thread' : 'Recipients of product',
        'Who can approve new members' : 'Who can approve new members',
        'All members/ Only Admin' : 'All members/ Only Admin',
        'Who can send group email' : 'Who can send shop email',
        'Folders (Categories)' : 'Folder (Categories)',
        'DELETE MEMBER' : 'Delete Member',
        'Group Info Update' : 'Shop Info Update',
        'Group Image Update' : 'Shop Image Update',
        'Group Request' : 'Shop Request',
        'Update Message' : 'Update Message',
        'Delete Message' : 'Delete Message',
        'Group Email' : 'Shop Email',
        'Email Subject' : 'Email Subject',
        'Email Message' : 'Email Message',
        'GROUP REQUEST' : 'Shop Request',
        'Delete Thread' : 'Delete Product',
        'File Save Error' : 'File Save Error!',
        'Saved!' : 'Saved!',
        'File Download' : 'File Download',
        'Edit Thread' : 'Edit Product',
        'Change Notification Setting' : 'Change Notification Setting',
        'Updated successfully.' : 'Updated successfully.',
        'Group image uploaded successfully' : 'Shop image uploaded successfully',
        'Group Title is Required' : 'Shop Title is Required',
        'Request status has been updated.' : 'Request status has been updated.',
        'Message deleted successfully' : 'Message deleted successfully',
        'Message updated successfully' : 'Message updated successfully',
        'Email send successfully.' : 'Email send successfully.',
        'Please provide Email Subject' : 'Please provide Email Subject',
        'Please provide email message' : 'Please provide email message',
        'You have successfully unsubscribe.' : 'You have successfully unsubscribe.',
        'User Deleted successfully.' : 'User Deleted successfully',
        'Document deleted successfully' : 'Document deleted successfully',
        'Your requested file has been saved to your device' : 'Your requested file has been saved to your device',
        'Successfully downloaded.' : 'Successfully downloaded.',
        'Group deleted successfully.' : 'Shop deleted successfully.',
        'Notification update successfully' : 'Notification update successfully',
        'Thread details are updated successfully' : 'Product details are updated successfully',
        'Do you want to create new thread?' : 'Do you want to create new product?',
        '... Show More' : '... Show More',
        ' Show Less' : ' Show Less',
        'Comments' : 'Comments',
        'Files' : 'Files',
        'User Groups' : 'User Shops',
        'Requested Groups' : 'Requested Shops',
        'Other Groups' : 'Other Shops',
        'Other Users' : 'Other Users',
        'If you click on Sign Up, you accept our terms, readmore about how we collect, use and share your information, in our data policy and on how we use cookiesand similar technology, in our cookies policy. You may recieve SMS notification from us and may cancel them at any time.' : 'If you click on Sign Up, you accept our terms, readmore about how we collect, use and share your information, in our data policy and on how we use cookiesand similar technology, in our cookies policy. You may recieve SMS notification from us and may cancel them at any time.',
        'What type of user are you?' : 'What type of user are you?',
        'FORGOT PASSWORD?' : 'Forgot Password?',
        'You can reset your password here.' : 'You can reset your password here.',
        'Do you want to send invitation to this e-mail' : 'Do you want to send invitation to this e-mail',
        'Description' : 'Description',
        'Description of the group here' : 'Description of the shop here',
        'Who can send group mail?' : 'Who can send shop mail?',
        'Select Recipients' : 'Select Recipients',
        'Group posts and members will be visible to all members' : 'Shop posts and members will be visible to all members.',
        'Name' : 'Name',
        'Group posts and members will be hidden to all members' : 'Shop posts and members will be hidden to all members.',
        'SEARCH FILTERS' : 'Search Filters',
        'Group Name' : 'Shop Name',
        'ADD CATEGORIES' : 'Add Categories',
        'SELECT RECIPIENTS' : 'Select Recipients',
        'Write a note...' : 'Write a note...',
        'No user is registered with this e-mail.' : 'No user is registered with this e-mail.',
        'Recipients' : 'Recipients',
        'Closed' : 'Closed',
        'THREAD DETAILS' : 'Product Details',
        'PROFILE' : 'Profile',
        'Phone Number' : 'Phone Number',
        'Danish' : 'Danish',
        'English' : 'English',
        'My group and thread history' : 'My shop and product history',
        'Confirm New password' : 'Confirm New password',
        'Profession' : 'Profession',
        'Add additional info here...' : 'Add additional info here...',
        'Minutes for auto logout' : 'Minutes for auto logout',
        'My Thread History' : 'My Product History',
        'Web Links' : 'Web Links',
        'Inactivity Setting' : 'Inactivity Setting',
        'additional Info Here if any...' : 'additional Info Here if any...',
        'Categories are used in forums and file management, for the individual group or clinic' : 'Categories are used in forums and file management, for the individual shop or clinic.',
        'Group Description...' : 'Shop Description.....',
        'ADD NEW CATEGORY' : 'Add new category',
        'Can be manage and creates later, under settings' : 'Can be manage and creates later, under settings',
        'create thread flow' : 'create product flow',
        'Who can Add files?' : 'Who can Add files?',
        'Send also posting some e-mail notifications to members' : 'Send also posting some e-mail notifications to members',
        'PERMISSIONS' : 'Permissions',
        'All Readers Can' : 'All Readers Can',
        'Doctors' : 'Doctors',
        'Priority' : 'Highlight (optional)',
        'E-mail notification' : 'Email notification',
        'Write a Message' : 'Write a Message',
        'Others' : 'Others',
        'CategoryFieldCell' : 'CategoryFieldCell',
        'Name a Category' : 'Name a Category',
        'ChatViewCell:' : 'ChatViewCell',
        'CommentText' : 'CommentText',
        'CommentDetailViewCell' : 'CommentDetailViewCell',
        'Here goes the message' : 'Here goes the message',
        'days ago' : 'days ago',
        'ConnectionViewCell' : 'ConnectionViewCell',
        'Hello there! I"m using Inline Doc' : 'Hello there! I"m using Inline Doc',
        'GroupViewCell' : 'ShopViewCell',
        'LinearRowViewCell' : 'LinearRowViewCell',
        'Standard (Default)' : 'Standard (Default)',
        'MemberInfoTableViewCell' : 'MemberInfoTableViewCell',
        'SEND MESSAGE' : 'Send Message',
        'Next' : 'Next',
        'Back' : 'Back',
        'Profile Update' : 'Profile Update',
        'Account updated Successfully' : 'Account updated Successfully',
        'Group Creation' : 'Shop Creation',
        'Group created successfully' : 'Shop created successfully',
        'Auto Log Out' : 'Auto Log Out',
        'Due to inactivity you have been logged out from the current session' : 'Due to inactivity you have been logged out from the current session',
        'Group Description' : 'Shop Description',
        'Write a message' : 'Write a message..',
        'write a comment' : 'write a comment..',
        'Validation Failure' : 'Validation Failure',
        'Reset Password' : 'Reset Password',
        'are required' : 'are required',
        'is required' : 'is required',
        'is not a valid Email' : 'is not a valid Email',
        'Password and Confirm Password are not Same' : 'Password and Confirm Password are not Same',
        'User Sign Up Success' : 'User Sign Up Success',
        'Password should be at least 6 characters long' : 'Password should be at least 6 characters long',
        'Confirm Password should be at least 6 characters long' : 'Confirm Password should be at least 6 characters long',
        'Valid Email Required' : 'Valid Email Required',
        'To reset password Please provide a valid Email' : 'To reset password Please provide a valid Email',
        'Registered Email is required and can"t be empty.' : 'Registered Email is required and can"t be empty.',
        'Password is required and can"t be empty' : 'Password is required and can"t be empty',
        'Friend Request' : 'Friend Request',
        'Membership request sent successfully' : 'Follow request sent successfully',
        'Account confirmation is required. Please, check your email for the confirmation link' : 'Account confirmation is required. Please, check your email for the confirmation link',
        'If there is an account associated with #MAIL# you will receive an email with a link to reset your password' : 'Hvis der er en konto forbundet med [MAIL], modtager du en e -mail med et link til at nulstille din adgangskodeIf there is an account associated with #MAIL# you will receive an email with a link to reset your password',
        'year' : 'year',
        'month' : 'month',
        'day' : 'day',
        'hour' : 'hour',
        'minute' : 'minute',
        'second' : 'second',
        'months' : 'months',
        'days' : 'days',
        'hours' : 'hours',
        'minutes' : 'minutes',
        'seconds' : 'seconds',
        'a moment' : 'a moment',
        'ago' : 'ago',
        'Add additional info here' : 'Add additional info here',
        'Selected Recipients' : 'Selected Recipients',
        'Inactivity Time Update' : 'Inactivity Time Update',
        'Inactivity Time limit Updated successfully' : 'Inactivity Time limit Updated successfully',
        'Contact information' : 'Contact information',
        'Document Added Successfully' : 'Document Added Successfully',
        'Yes/No' : 'Yes/No',
        'Do you want to create another?' : 'Do you want to create another?',
        'Which Type of user are you?' : 'Which Type of user are you?',
        'Delete Profile' : 'Delete Profile',
        'Are you sure you want to delete?' : 'Are you sure you want to delete?',
        'New Password and Confirm new password are required' : 'New Password and Confirm new password are required',
        'Current password is required' : 'Current password is required',
        'Confirm new password is required' : 'Confirm new password is required',
        'Your Account is deactivated. Please confirm your account' : 'Your Account is deactivated. Please confirm your account',
        'Resend Confirmation' : 'Resend Confirmation',
        'Your account is permanent deleted. Please contact admin' : 'Your account is permanent deleted. Please contact admin',
        'Account confirmation' : 'Account confirmation',
        'This account is not confirmed' : 'This account is not confirmed',
        'Confirmation link' : 'Confirmation link',
        'You Are trying to delete #GROUP. This is not possible as long as other users are a member of this.' : 'You Are trying to delete #SHOP. This is not possible as long as other users are a member of this.',
        'If you delete a category / folder, all content will be deleted and can NOT be recreated. Alternatively, you can disable the category / folder by unchecking it in front of it in the list.' : 'If you delete a category / folder, all content will be deleted and can NOT be recreated. Alternatively, you can disable the category / folder by unchecking it in front of it in the list.',
        'I agree that all content will be deleted' : 'I agree that all content will be deleted',
        'Contacts' : 'Contacts',
        'Search Contacts' : 'Search Contacts',
        'Info' : 'Info',
        'Type' : 'Type',
        'Size' : 'Size',
        'Location' : 'Location',
        'Edited' : 'Edited',
        'Created' : 'Created',
        'Create'  : 'CREATE',

        // error messages

    }
};
