<div class=" main-container">
    <div fxLayout="row " fxLayoutGap="20px" fxLayoutAlign="start center">
        <mat-card class="product-card">
            <div class="card-image cursor-pointer" (click)="openProductDetails()">
                <div class="Sale-class" *ngIf="product?.priority && product?.priority !== 'nothing'">
                 {{product?.priority | uppercase | translate}}
                </div>
                <span *ngIf="product?.files?.length > 0">
                    <img  *ngIf="isVideo !==null &&!isVideo"mat-card-image class="card-image" src="{{product?.files ?  product?.files[0]?.src || 'assets/images/backgrounds/loading.gif' : ''}}"
                          alt="Product Files ">
                    <video *ngIf="isVideo" [src]="product?.files[0]?.src ||'assets/images/backgrounds/loading.gif' " ></video>
                </span>
                <span *ngIf="product?.files?.length === 0">
                    <img mat-card-image class="card-image" src="assets/images/place-holder/no-Image.png"
                          alt="Place holder image">
                </span>
            </div>
            <mat-card-content class="my-16">
                <div fxLayout="column" fxLayoutAlign="center center" class="card-content">
                    <h4 [innerHTML]="product?.title"></h4>
                    <div>
                        <span class="price-tag">{{'PRODUCT.price' | translate}}:</span> <span class="price-value"> {{product?.price | currency:'DKK':'symbol':'1.1-1':'da'}}</span>
                    </div>
                </div>

            </mat-card-content>

            <mat-card-actions>
                <div fxLayout="row" fxLayoutAlign="space-evenly">
                    <button  mat-stroked-button (click)="addToBasket()">{{'PRODUCT.add_to_the_basket'| translate}}</button>
                    <div fxLayout="row" fxLayoutAlign="space-between" class="my-8">
                        <mat-icon class="mx-16" (click)="openProductDetails(true)">comment</mat-icon>
                        <ng-containor *ngIf="currentUser?.email !== 'guest@onlinedoc.dk'">
                        <mat-icon *ngIf="!product?.docNotification"
                                  matTooltip="{{'post.thread_notification_off' | translate}}"
                                  (click)="this.turnThreadNotificationOn()">notifications_off
                        </mat-icon>
                        <mat-icon *ngIf="product?.docNotification" class="mx-8"
                                  matTooltip="{{'post.thread_notification_on' | translate}}"
                                  (click)="this.turnThreadNotificationOff()"
                                  > notifications_active
                        </mat-icon>
                        </ng-containor>
                        <mat-icon mx-16 [matMenuTriggerFor]="menu" *ngIf="product?.productEditable">more_vert</mat-icon>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="editProduct()" >
                                <mat-icon>edit</mat-icon>
                                <span>{{'DASHBOARD_TASK.edit' | translate}}</span>
                            </button>
                            <button mat-menu-item (click)="deleteThread()">
                                <mat-icon>delete</mat-icon>
                                <span>{{'DASHBOARD_TASK.delete' | translate}}</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>

            </mat-card-actions>


        </mat-card>
    </div>

</div>
