import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrumboardBoardCardComponent } from './card/card.component';
import { CardGalleryViewComponent } from './card/card-gellary-view/card-gallery-view.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { UtilModule } from '../util/util.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [ScrumboardBoardCardComponent, CardGalleryViewComponent],
    exports     : [
        ScrumboardBoardCardComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatIconModule,
        MatTooltipModule,
        MatMenuModule,
        MatBadgeModule,
        UtilModule,
        TranslateModule
    ]
})
export class TaskModule
{
}
