import { NgModule } from '@angular/core';
import { RichTextFieldComponent } from './rich-text-field.component';
import { FormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';

@NgModule({
    declarations: [RichTextFieldComponent],
    exports: [
        RichTextFieldComponent
    ],
    imports: [
        FormsModule,
        QuillModule.forRoot()
    ]
})
export class RichTextFieldModule
{
}
