import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as UAParser from 'ua-parser-js';

@Injectable({
    providedIn: 'root'
})
export class BrowserCompatibleGuard implements CanActivate
{
    parser = new UAParser() ;

    constructor(private _router: Router)
    {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const browserName = this.parser.getBrowser().name?.toLowerCase();
        if ( browserName === 'ie' )
        {
            this._router.navigate(['/error/browser']);
            return false;
        }
        return true;

    }

}
