import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FileUploadService } from '../../../../service/api/file-upload.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector     : 'app-attachments',
    templateUrl  : './attachments.component.html',
    styleUrls    : ['./attachments.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class AttachmentsComponent implements OnInit
{


    @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
    @Input() files: any[];
    @Input() allowLargeFiles: boolean;
    @Input() toastMessage?: string;
    @Output() filesRemoved = new EventEmitter<any>();
    @Output() lastFile = new EventEmitter<any>();


    attachedCount = 0;
    attachments: { file: any, progressStatus: string }[] = [];

    constructor(
        private fileUpload: FileUploadService,
        private toast: ToastrService,
        private _matDialog: MatDialog
    )
    {
    }

    public pickFile(): void
    {
        this.fileInput.nativeElement.click();
    }

    ngOnInit(): void
    {
    }

    reset(): void
    {
        this.attachments = [];
        this.attachedCount = 0;
    }

    removeFile(index, file: any): void
    {
        this.files.splice(index, 1);
        this.filesRemoved.emit(file);
    }

    removeAttachment(index): void
    {
        this.attachments.splice(index, 1);
        this.attachedCount = this.attachments.length;
    }

    onFileChange($event: any): void
    {
        if ( $event.target.files )
        {
            for (let i = 0; i < $event.target.files.length; i++)
            {
                if (!this.allowLargeFiles &&  $event.target.files[i].size >  20971520)
                {
                    this.toast.error(this.toastMessage ?? 'Please ask admin to allow large file upload', 'Error');
                    return;
                }
                const file: any = $event.target.files[i];
                this.attachments.push({file: file, progressStatus: ''});
                this.attachedCount = this.attachments.length;
            }
            this.fileInput.nativeElement.value = '';
        }
    }

    async directUpload(clinicId? ): Promise<any>
    {
        const temp = {
            files: this.attachments,
            sub  : new Subject()
        };
        if ( clinicId )
        {
            for (const tempElement of this.attachments)
            {
                tempElement.file.clinic_id = clinicId;
            }
        }
        this.fileUpload.uploadRequests.next(temp);
        return await temp.sub.asObservable().toPromise();
    }

    getType(type): 'image' | 'link'
    {
        return type.includes('image') ? 'image' : 'link';
    }
}
