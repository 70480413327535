import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FooterType } from 'app/types';

@Component({
    selector     : 'post-footer',
    templateUrl  : './footer.component.html',
    styleUrls    : ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit
{
    @Input() post;
    @Input() type;
    @Input() forTask;
    FooterType = FooterType;

    constructor()
    {
    }

    ngOnInit(): void
    {
    }

}
