import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
    selector   : 'attachment-preview',
    templateUrl: './attachment-preview.component.html',
    styleUrls  : ['./attachment-preview.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AttachmentPreviewComponent implements OnInit
{
    @Input() msgFromParent: any[];

    constructor()
    {
    }

    ngOnInit(): void
    {
    }

    // // tslint:disable-next-line:use-lifecycle-interface
    // ngOnChanges(changes: SimpleChanges): void
    // {
    //     this.msgFromParent = changes.model.currentValue;
    // }

    removeAttachment(index): void
    {
        this.msgFromParent.splice(index, 1);
    }

}
