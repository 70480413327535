import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { IDocRead } from './doc-read.model';

@Injectable()
export class DocReadDataService extends DefaultDataService<IDocRead>
{

    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator)
    {
        super('DocRead', http, httpUrlGenerator);
    }
}
