<div class="attachment" fxFlex="33" *ngFor="let attachment of msgFromParent; let i = index;"
     [ngSwitch]="attachment.type">
    <div *ngSwitchCase="'image'" fxLayout="column" fxLayoutAlign="start">
        <div class="attachment-preview mat-elevation-z1">
            <img class="attachment-img" [src]="attachment.file">
        </div>
        <div class="attachment-content mt-8" fxLayout="row" fxLayoutAlign="start start">
            <div fxFlex>
                <div fxLayout="row" fxLayoutAlign="start start">
                    <span class="attachment-name">{{attachment.name}}</span>
                </div>
                <span class=" secondary-text">{{attachment.name}}</span>
            </div>
            <button mat-icon-button class="attachment-actions-button" (click)="removeAttachment(i)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div *ngSwitchCase="'link'" fxLayout="column" fxLayoutAlign="start">

        <div class="attachment-preview mat-elevation-z1">
            <img class="attachment-img" src="../../../../../../../assets/icons/files-type/files_icon.png">
        </div>
        <div class="attachment-content mt-8" fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex>
                <div fxLayout="row" fxLayoutAlign="start start">
                    <span class="attachment-name">{{attachment.name}}</span>
                </div>
            </div>
            <button mat-icon-button class="attachment-actions-button" (click)="removeAttachment(i)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>

    </div>
</div>
