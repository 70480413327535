<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                <button (click)="onClickSendMessage()" mat-icon-button aria-label="message"
                        matTooltip="{{'CHAT.send_message' | translate}}">
                <mat-icon>message</mat-icon>
                </button>
                 <ng-container *ngIf="isFriend">
                     <button (click)="openInviteModal()" mat-icon-button aria-label="add"
                             matTooltip="{{'TOOLBAR.invite_to_group' | translate}}">
                        <mat-icon>add</mat-icon>
                     </button>
                 </ng-container>
                <ng-container *ngIf="!isAlreadyFriend && !friendRequestAcceptDelete">
                    <button *ngIf="addFriendRequest" mat-icon-button aria-label="person_add_alt"
                            matTooltip="{{'CHAT.send_connection' | translate}}"
                            (click)="requestConnectionRequest()">
                            <mat-icon>person_add_alt</mat-icon>
                    </button>
                    <button *ngIf="removeFriendRequest" mat-icon-button aria-label="person_add_alt"
                            matTooltip="{{'CHAT.remove_connection' | translate}}"
                            (click)="removeConnectionRequest()">
                        <mat-icon>person_outline</mat-icon>
                    </button>
                </ng-container>

                <ng-container *ngIf="!isAlreadyFriend && friendRequestAcceptDelete">
<!--                    <button mat-icon-button aria-label="person_add_alt"-->
                    <!--                            matTooltip="Accept Connection" (click)="acceptRequest()">-->
                    <!--                        <mat-icon>check</mat-icon>-->
                    <!--                    </button>-->
                    <!--                    <button mat-icon-button aria-label="close"-->
                    <!--                            matTooltip="Reject Connection" (click)="rejectRequest()">-->
                    <!--                        <mat-icon>close</mat-icon>-->
                    <!--                    </button>-->
                        <label> {{'CHAT.accept_connection' | translate}} {{'SLIDE.request' | translate}}? </label>
                        <button (click)="acceptRequest()" mat-icon-button
                                matTooltip="{{'CHAT.accept_connection' | translate}}">
                            <mat-icon class="secondary-text accept-button">check
                            </mat-icon>
                        </button>
                        <button (click)="rejectRequest()" mat-icon-button
                                matTooltip="{{'CHAT.reject_connection' | translate}}">
                            <mat-icon color="warn" class="secondary-text">close
                            </mat-icon>
                        </button>
                </ng-container>

            </span>

            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

        <mat-toolbar-row class="toolbar-bottom py-16" fxLayout="column" fxLayoutAlign="center center">
            <img [src]="detail.file? detail.file.src: 'assets/images/avatars/profile.jpg' "
                 class="avatar contact-avatar huge m-0"
                 [alt]="'Name'"/>
            <div class="contact-name mt-8">{{detail.first_name + ' ' + detail.last_name}}</div>
            <div *ngIf="isFriend"
                 class="title2">{{preNames}}</div>
            <div *ngIf="!isFriend" class="contact-name mt-4">
                <button mat-raised-button color="warn" (click)="rejectRequest()">
                    {{'SETTINGS.reject' | translate}}
                </button>
                <button class="ml-8 accept-button" mat-raised-button (click)="acceptRequest()">
                    {{'SETTINGS.accept' | translate}}
                </button>
            </div>

        </mat-toolbar-row>
    </mat-toolbar>

    <div *ngIf="isFriend" mat-dialog-content class="p-16 pb-0 m-0 overflow-content">

        <div class='row-div'>
            <div class='column-div'>
                <div class="section-title mb-4">{{'GROUPS.contact' | translate}} Information</div>
                <mat-divider class="mb-8"></mat-divider>
                <div class="info-line">
                    <div class="title">{{'CHAT.mobile_phone' | translate}}</div>
                    <div class="info">
                        <!--                        *ngFor="let tel of detail.contact.tel"-->
                        <span *ngIf="viewProfile === false">{{detail.phone !== null ? detail.phone : 'N/A'}}</span>
                        <span *ngIf="viewProfile === true">{{'SLIDE.only_visible_to_friends' | translate}}</span>
                    </div>
                </div>
                <div class="info-line">
                    <div class="title">Emails</div>
                    <div class="info">
                        <!--                        *ngFor="let email of detail.contact.emails"-->
                        <span *ngIf="viewProfile === false">{{detail.email !== null ? detail.email : 'N/A'}}</span>
                        <span *ngIf="viewProfile === true">{{'SLIDE.only_visible_to_friends' | translate}}</span>
                    </div>
                </div>
                <div class="info-line" *ngIf="detail.web_links">
                    <div class="title">{{'CHAT.websites_links' | translate}}</div>
                    <ng-container *ngIf="viewProfile === false">
                        <div class="info" *ngFor="let website of detail.web_links">
                            <span style="word-break: break-all;">{{detail.web_links.length != 0 ? website.link : 'N/A'}}</span>
                        </div>
                    </ng-container>
                    <span *ngIf="viewProfile === true">{{'SLIDE.only_visible_to_friends' | translate}}</span>
                </div>
            </div>
            <div class='column-div ml-8'>
                <div>
                    <div class="section-title mb-4">{{'SLIDE.basic' | translate}} Information</div>
                    <mat-divider class="mb-8"></mat-divider>
                    <div class="info-line">
                        <div class="title">{{'CHAT.date_of_birth' | translate}}</div>
                        <div class="info">{{detail.dob !== null ? detail.dob : 'N/A' }}</div>
                    </div>

                    <div class="info-line">
                        <div class="title">{{'CHAT.gender' | translate}}</div>
                        <div class="info">{{detail.gender === 'M' ? 'Male' : 'Female'}}</div>
                    </div>
                    <!--                    <div class="info-line">-->
                    <!--                        <div class="title">By</div>-->
                    <!--                        <div class="info">{{detail.friends[index].name}}</div>-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
            <div class="info-line">
                <div class="section-title mb-4">{{'SLIDE.additional' | translate}} Information</div>
                <mat-divider class="mb-8"></mat-divider>
                <div class="info">{{detail.bio !== null ? detail.bio : 'N/A'}}</div>
            </div>
        </div>
    </div>

</div>
