<input #fileInput multiple type="file" style="display:none"
       (change)="onFileChange($event)"/>
<div class="attachments-wrapper" [@animate]="{value:'*',params:{duration:'350ms',x:'100%'}}"
     *ngIf="attachments?.length>0 || files?.length>0">
    <div class="attachments" fxLayout="row">
        <div class="attachment" *ngFor="let file of files; let i = index;"
             [@animate]="{value:'*',params:{duration:'350ms',x:'100%'}}">
            <div fxLayout="column" fxLayoutAlign="start">

                <div class="attachment-preview mat-elevation-z1">
                    <img *ngIf="getType(file.content_type)=='link'" class="file-img"
                         src="assets/icons/files-type/files_icon.png">
                    <img *ngIf="getType(file.content_type)=='image'" [src]="file.medium_url">
                </div>

                <div class="attachment-content mt-8" fxLayout="row" fxLayoutAlign="start center">

                    <div fxFlex>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="attachment-name">{{file.name}}</span>
                        </div>

                    </div>

                    <button mat-icon-button class="attachment-actions-button" (click)="removeFile(i,file)">
                        <mat-icon id="removeFilesButton">delete</mat-icon>
                    </button>
                </div>

            </div>
        </div>


        <div class="attachment" *ngFor="let attachment of attachments; let i = index;"
             [@animate]="{value:'*',params:{duration:'350ms',x:'100%'}}">
            <div fxLayout="column" fxLayoutAlign="start">
                <div class="attachment-preview mat-elevation-z1">
                    <img *ngIf="getType(attachment.file.type)=='link'" class="file-img"
                         src="assets/icons/files-type/files_icon.png">
                    <img *ngIf="getType(attachment.file.type)=='image'" [src]="(attachment.file | secure) | async">
                    <div class="progress-div" *ngIf="attachment.progressStatus">
                        <div class="loading-div" *ngIf="attachment.progressStatus=='uploading'"
                             [@animate]="{value:'*',params:{delay:'100ms',scale:'0.2'}}">
                            <mat-spinner color="accent" [diameter]="50" strokeWidth="3"></mat-spinner>
                        </div>
                        <div class="uploaded-div" *ngIf="attachment.progressStatus=='done'"
                             [@animate]="{value:'*',params:{delay:'200ms',scale:'0.2'}}">
                            <mat-icon class="s-md-30">check</mat-icon>
                        </div>
                    </div>
                </div>

                <div class="attachment-content mt-8" fxLayout="row" fxLayoutAlign="start center">

                    <div fxFlex>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="attachment-name">{{attachment.file.name}}</span>
                        </div>

                    </div>

                    <button mat-icon-button class="attachment-actions-button" (click)="removeAttachment(i)">
                        <mat-icon id="removeAttachmentButton">delete</mat-icon>
                    </button>
                </div>

            </div>
        </div>

    </div>

</div>
