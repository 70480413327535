import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : '',
        translate: '',
        type     : 'group',
        classes   : '',
        children : [{
            id       : 'dashboard',
            title    : 'Dashboard',
            translate: 'NAV.DASHBOARD',
            type     : 'item',
            icon     : 'dashboard',
            url      : '/home',
            classes   : 'favourite-icon',
        }, {
            id       : 'notification',
            title    : 'Notification',
            translate: 'NAV.NOTIFICATION.TITLE',
            type     : 'item',
            icon     : 'notifications_active',
            url      : '/notifications',
            classes   : 'favourite-icon',
        }, {
            id       : 'checkout',
            title    : 'Checkout',
            translate: 'NAV.CHECKOUT',
            type     : 'item',
            icon     : 'shopping_cart',
            url      : '/checkout',
            classes   : 'favourite-icon',
        }, ]
    },
    {
        id       : 'quick_access',
        title    : 'Follows',
        translate: 'NAV.Quick-Access',
        type     : 'group',
        classes  : 'favourite-icon',
        children : []
    }
];
