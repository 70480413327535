import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from "@angular/material/dialog";
import {FlexModule} from "@angular/flex-layout";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {TermsAndConditionsModalComponent} from './terms-and-conditions-modal.component';

@NgModule({
    declarations: [TermsAndConditionsModalComponent],
    imports: [
        CommonModule,

        MatDialogModule,
        FlexModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
    ]
})
export class TermsAndConditionsModalModule {
}
