<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{'Delete ' | translate}} {{title}}</span>
            <button mat-icon-button (click)="matDialogRef.close(['close'])" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-12 pb-0 m-0">
        <h2>{{message | translate}}</h2>
        <mat-checkbox [(ngModel)]="isChecked" (change)="onChange($event)" matPrefix>
            <h3> {{'SETTINGS.accept_to_delete_all' | translate}} </h3>
        </mat-checkbox>

    </div>

    <div mat-dialog-actions class="m-12" fxLayout=" row" fxLayoutAlign="end center">
        <button [disabled]="deleteOn" mat-raised-button color="accent" class="post-button"
                (click)="matDialogRef.close(['yes'])"
                aria-label="yes">
            {{'SETTINGS.yes' | translate}}</button>
        <button mat-raised-button color="accent" class="post-button"
                (click)="matDialogRef.close(['no'])"
                aria-label="no">
            {{'SETTINGS.no' | translate}}</button>
    </div>

</div>
