<div class="checklist">
    <div class="section-header" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="mr-8 section-header-point-cursor">
            check_box
        </mat-icon>
        <span fxFlex class="section-title">Folders</span>
    </div>

    <div class="section-content">
        <div class="check-items">
            <div class="check-item m-0" fxLayout="row"
                 fxLayoutAlign="space-between center">

                <div fxFlex fxLayout="row" fxLayoutAlign="start center">

                    <mat-form-field appearance="outline" fxFlex>
                        <input matInput value="Standard Default" disabled>
                        <mat-checkbox matPrefix [checked]="true" disabled>
                        </mat-checkbox>
                    </mat-form-field>
                </div>
            </div>
            <div class="check-item m-0"
                 *ngFor="let item of categoriesList; let index = index; let first = first; let last = last"
                 fxLayout="row"
                 fxLayoutAlign="space-between center">

                <div fxFlex fxLayout="row" fxLayoutAlign="start center">

                    <mat-form-field appearance="outline" fxFlex>
                        <input matInput [(ngModel)]="item.name">
                        <mat-checkbox matPrefix [(ngModel)]="item.checked"
                                      aria-label="{{item.name}}">
                        </mat-checkbox>
                        <div matSuffix style="display:flex">
                            <button *ngIf="!first " mat-icon-button matTooltip="{{'CATEGORIES.move_up' | translate}}" matSuffix class="checklist-actions-button"
                                    (click)="moveItemUp(item, index)">
                                <mat-icon class="secondary-text">arrow_upward</mat-icon>
                            </button>
                            <button *ngIf="!last" mat-icon-button matSuffix matTooltip="{{'CATEGORIES.move_down' | translate}}" class="checklist-actions-button"
                                    (click)="moveItemDown(item, index)">
                                <mat-icon class="secondary-text">arrow_downward</mat-icon>
                            </button>
                            <button mat-icon-button matSuffix matTooltip="{{'CATEGORIES.delete' | translate}}" class="checklist-actions-button"
                                    (click)="removeItem(item, index)">
                                <mat-icon class="secondary-text">delete</mat-icon>
                            </button>
                        </div>

                    </mat-form-field>

                </div>

            </div>
        </div>

        <form #newCheckItemForm="ngForm" (submit)="addItem(newCheckItemForm)"
              name="newCheckItemForm" class="new-check-item-form m-0"
              fxLayout="row" fxLayoutAlign="start center">

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex>

                <mat-form-field appearance="outline" class="no-errors-spacer mr-16" fxFlex>
                    <input matInput ngModel #checkItem="ngModel" name="checkItem" autocomplete="off"
                           placeholder="{{'CATEGORIES.new_folder' | translate}}">
                </mat-form-field>

            </div>

            <button mat-mini-fab color="accent"
                    [disabled]="!newCheckItemForm.valid || newCheckItemForm.pristine"
                    aria-label="Add">
                <mat-icon>add</mat-icon>
            </button>
        </form>
    </div>

</div>
