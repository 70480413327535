import { Injectable } from '@angular/core';

export interface Friend
{
    id: number;
    senderId: number;
    receiverId: number;
    requestStatus: string;
    file: any;
    friendId: number;
    friendName: string;
}

@Injectable({
    providedIn: 'root'
})
export class FriendsAdapter
{
    public adapt(item: any): Friend
    {
        return {
            id           : item.id,
            receiverId   : item.receiver_id,
            requestStatus: item.request_status,
            senderId     : item.sender_id,
            file         : item.file,
            friendId     : item.friend_id,
            friendName   : item.full_name
        };
    }
}
