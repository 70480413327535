import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[ClickOutsideChat]'
})
export class ClickOutsideChatDirective {
    constructor(private _elementRef: ElementRef)
    {
    }

    @Output() clickOutside = new EventEmitter();

    @HostListener('document:click', ['$event.target'])

    public onClick(targetElement): void
    {
        let clickedInside = this._elementRef.nativeElement?.contains(targetElement);
        if ( targetElement.id === 'chatAction' ||
            targetElement.id === 'chatActionMenu' ||
            targetElement.id === 'chatActionEdit' ||
            targetElement.id === 'chatActionDelete' ||
            targetElement.id === 'chatFileAction' ||
            targetElement.id === 'chatFileActionMenu' ||
            targetElement.id === 'chatFileEdit' ||
            targetElement.id === 'chatFileDelete' ||
            targetElement.id === 'resetMessage' ||
            targetElement.id === 'removeAttachmentButton' ||
            targetElement.id === 'removeFilesButton'
        )
        {
            clickedInside = true;
        }
        if ( !clickedInside )
        {
            this.clickOutside.emit(null);
        }
    }
}
