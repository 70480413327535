<!-- CARD COVER -->
<div (click)="openCardDialog(card)">
<div *ngIf="card.files"
     class="list-card-cover">
    <card-gallery-view [files]="card.files"></card-gallery-view>
</div>
<!-- / CARD COVER -->

<!-- CARD DETAILS -->
<div class="list-card-details">

    <!-- CARD SORT HANDLE -->
    <div class="list-card-sort-handle">
        <mat-icon mat-font-icon="icon-cursor-move" class="icon s16"></mat-icon>
    </div>
    <!-- /CARD SORT HANDLE -->

    <!-- CARD LABELS -->
    <div class="list-card-labels" fxLayout="row "
         fxLayoutAlign="space-between center">

        <span [ngSwitch]="card.taskStatus">
                <span *ngSwitchCase="'to_do'" fxLayout="row wrap">
                      <span class="list-card-label yellow" [matTooltip]="card.taskStatus"></span>
                </span>
                <span *ngSwitchCase="'in_progress'" fxLayout="row wrap">
                      <span class="list-card-label blue" [matTooltip]="card.taskStatus"></span>
                </span>
                <span *ngSwitchCase="'done'" fxLayout="row wrap">
                      <span class="list-card-label green" [matTooltip]="card.taskStatus"></span>
                </span>
        </span>

    </div>
    <!-- / CARD LABELS -->
    <!-- CARD NAME -->
    <span class="list-card-name">
        <p class="text-wrap" [innerHTML]="card.title"></p>
    </span>
    <!-- / CARD NAME -->
    <!--        || card.checkItems-->
    <div *ngIf="card.taskEnd"
         class="list-card-badges" fxLayout="row" fxLayoutAlign="start center">

        <!-- CARD DUE -->
        <span class="badge due-date"
              [ngClass]="{'overdue': isOverdue(card.taskEnd.split('t')[0])}"
              *ngIf="card.taskEnd" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="s-16">access_time</mat-icon>
            <span>{{card.taskEnd.split('t')[0] | date:'mediumDate'}}</span>

        </span>
        <!-- / CARD DUE -->

        <!-- CARD CHECK ITEMS STATUS -->
        <!--            <span *ngIf="card.checkItems"-->
        <!--                  class="badge check-items"-->
        <!--                  [ngClass]="{'completed': card.checkItemsChecked === card.checkItems}"-->
        <!--                  fxLayout="row" fxLayoutAlign="start center">-->
        <!--            <mat-icon class="s-16">check_circle</mat-icon>-->
        <!--            <span>{{card.checkItemsChecked}}</span>-->
        <!--            <span>/</span>-->
        <!--            <span>{{card.checkItems}}</span>-->
        <!--        </span>-->
        <!-- / CARD CHECK ITEMS STATUS -->

    </div>

    <!-- CARD MEMBERS -->
    <div *ngIf="card.subscribedUsers.length > 0"
         class="list-card-members"
         fxLayout="row">

        <div class="list-card-member"
             *ngFor="let memberId of card.subscribedUsers.slice(0, 3);">
            <profile-image class="list-card-member-avatar" [src]="memberId.file?.thumb_url"></profile-image>
        </div>
        <div *ngIf="card.subscribedUsers?.length > 3" class="list-card-member">
            <mat-icon
                    class="m-8"
                    [matBadge]="'+ '+ (card.subscribedUsers?.length - 3)"
                    matBadgeColor="primary">
                people
            </mat-icon>
        </div>
    </div>
    <!-- / CARD MEMBERS -->

</div>
<!-- / CARD DETAILS -->

<!-- CARD FOOTER -->
<div class="list-card-footer" fxLayout="row" fxLayoutAlign="space-between center">

    <div fxLayout="row" fxLayoutAlign="start center">

        <!-- CARD DETAILS -->
        <span *ngIf="card.description !== ''"
              class="list-card-footer-item" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="s-18" matTooltip="{{getCardDescription(card.description)}}">description</mat-icon>
        </span>
        <!-- / CARD DETAILS -->

    </div>

    <div fxLayout="row" fxLayoutAlign="end center">

        <!-- CARD ATTACHMENT -->
        <span *ngIf="(card.clinicDocCommentFileCount+card.clinicDocFileCount)>0"
              class="list-card-footer-item"
              fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="s-18">attachment</mat-icon>
            <span class="value">{{card.clinicDocCommentFileCount + card.clinicDocFileCount}}</span>
        </span>
        <!-- / CARD ATTACHMENT -->

        <!-- CARD COMMENTS -->
        <span *ngIf="card.clinicDocCommentsCount>0"
              class="list-card-footer-item"
              fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="s-18">comment</mat-icon>
            <span class="value">{{card.clinicDocCommentsCount}}</span>
        </span>
        <!-- / CARD COMMENTS -->

    </div>
</div>
<!-- CARD FOOTER -->
</div>
