<div class="toast-bottom-right toast-container mr-16">
    <circle-progress
            *ngIf="show"
            [percent]="percentage"
            [radius]="100"
            [outerStrokeWidth]="16"
            [innerStrokeWidth]="8"
            [showUnits]="false"
            [outerStrokeColor]="'#039be5'"
            [innerStrokeColor]="'#89bad2'"
            [subtitleColor]="'#000000'"
            [titleFontSize]="'16'"
            [subtitleFontSize]="'12'"
            [subtitle]="uploadingText"
            [startFromZero]="false"
            [title]="titleText"
            [animation]="true"
            [animationDuration]="300"
    ></circle-progress>
</div>
