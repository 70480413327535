import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrefixConversionPipe } from './prefix-conversion.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';



@NgModule({
    declarations: [PrefixConversionPipe, LinkifyPipe, SafeHtmlPipe],
    imports     : [CommonModule ],
    exports     : [PrefixConversionPipe, LinkifyPipe, SafeHtmlPipe],
})
export class CommonPipeModule
{
}
