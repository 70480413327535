import { Component, Input, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../../../../../i18n/en';
import { locale as danish } from '../../../../../../../i18n/da';

@Component({
    selector   : 'card-gallery-view',
    templateUrl: './card-gallery-view.component.html',
    styleUrls  : ['./card-gallery-view.component.scss']
})
export class CardGalleryViewComponent implements OnInit
{
    type: any;
    item: any;

    @Input() set files(temp)
    {
        this.type = null;
        this.item = null;
        let value = (temp).filter(f => f.content_type.includes('image'));
        if ( value.length > 0 )
        {
            this.type = 'image';
            this.item = value[0];
        }

        value = (temp).filter(f => f.content_type.includes('video'));
        if ( value.length > 0 )
        {
            this.type = 'video';
            this.item = value[0];
        }
        value = (temp).filter(f => !(f.content_type.includes('image') || f.content_type.includes('video')));
        if ( value.length > 0 )
        {
            this.type = 'file';
            this.item = value[0];
        }


    }

    constructor()
    {
    }

    ngOnInit(): void
    {
    }

}
