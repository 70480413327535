<ng-container>
    <div >
        <mat-form-field class="ml-8" appearance="outline" fxFlex="70">
            <mat-label>{{'DASHBOARD_TASK.filter_tasks' | translate}}</mat-label>
            <mat-select [(ngModel)]="selectedValue" (selectionChange)="changeDate($event)">
                <mat-option *ngFor="let role of taskDates" [value]="role.value">
                    {{role.viewValue | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button (click)="openPopTaskDialog()" mat-icon-button class="mt-12"
                matTooltip="{{'TOOLBAR.create_task' | translate}}">
            <img class="secondary-text" matRipple [src]=" 'assets/icons/notification/add_task.png'">
        </button>
    </div>

    <scrumboard-board-card
            [@animate]="{value:'*',params:{y:'100%'}}"
            class="scrumboard-board-card ngx-dnd-item"
            *ngFor="let task of tasks"
            [card]="task"
            name="{{task.taskStatus}}">
    </scrumboard-board-card>
</ng-container>

