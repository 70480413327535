<form novalidate [formGroup]="postForm">
    <header [ngSwitch]="post?.docType" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="user" fxLayout="row" fxLayoutAlign="start center">
            <profile-image [userProfile]="post.docCreator?.id" class="avatar cursor-pointer"
                           [src]="post.docCreator?.file?.thumb_url"></profile-image>

            <div fxLayout="column">
                <div class="title">
                    <span [userProfile]="post.docCreator?.id"
                          class="username cursor-pointer">{{post.docCreator?.full_name ? post.docCreator?.full_name : '' }}</span>
                    <span *ngSwitchCase="'thread'" class="time ml-8"
                          [ngStyle]="{'font-weight': 400}">{{post?.createdTime | amTimeAgo}}</span>
                    <span *ngSwitchCase="'task'" class="time ml-8"
                          [ngStyle]="{'font-weight': 400}">{{post?.taskStart | amTimeAgo}}</span>
                </div>
                <div *ngSwitchCase="'thread'" class="time"><span
                        class="cursor-pointer group-name-color"
                        routerLink="/groups/{{post?.clinicId}}">{{ post?.clinicName }}
                    | {{ post?.categoryName }} </span></div>
                <div *ngSwitchCase="'task'" class="time"><span
                        class="cursor-pointer group-name-color"
                        routerLink="/groups/{{post?.clinicId}}">{{ post?.clinicName }}
                </span></div>
                <span class="time post-title" [innerHTML]="post?.title"></span>
            </div>
        </div>
        <div fxLayout="row">
            <ng-container *ngSwitchCase="'thread'">
                <button *ngIf="post?.allowSharing"  class="mr-24" mat-icon-button aria-label="More"
                         matTooltip="{{'THREAD.share' | translate}}" (click)="shareThread()">
                    <mat-icon class="share-icon mr-4">reply</mat-icon>
                    <span class="mt-12 mr-4   share-text ">{{'THREAD.share' | translate}}</span>
                </button>

                <button *ngIf="!post?.docNotification" class="mr-4" mat-icon-button aria-label="More"
                        matTooltip="{{'post.thread_notification_off' | translate}}"
                        (click)="turnThreadNotificationOn()">
                    <mat-icon>notifications_off</mat-icon>
                </button>
                <button *ngIf="post?.docNotification" class="mr-4" mat-icon-button aria-label="More"
                        matTooltip="{{'post.thread_notification_on' | translate}}"
                        (click)="turnThreadNotificationOff()">
                    <mat-icon>notifications_active</mat-icon>
                </button>
                <button *ngIf="threadAuthor || post?.isAdmin" mat-icon-button [matMenuTriggerFor]="postMenu"
                        aria-label="More">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </ng-container>
            <button *ngSwitchCase="'task'" mat-icon-button [matMenuTriggerFor]="postMenu"
                    aria-label="More">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #postMenu="matMenu">
                <button *ngSwitchCase="'task'" mat-menu-item (click)="editTask(post)">
                    <mat-icon>edit</mat-icon>
                    <span>{{'DASHBOARD_TASK.edit' | translate}}</span>
                </button>
                <ng-container *ngSwitchCase="'thread'">
                    <button mat-menu-item (click)="editThread()" *ngIf="!post?.isAdmin || threadAuthor">
                        <mat-icon>edit</mat-icon>
                        <span>{{'DASHBOARD_TASK.edit' | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="deleteThread()" *ngIf="post?.isAdmin || threadAuthor">
                        <mat-icon>delete</mat-icon>
                        <span>{{'DASHBOARD_TASK.delete' | translate}}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </div>
    </header>
    <div class="content" [ngSwitch]="post?.docType">
        <div observeVisibility [debounceTime]="0" (visible)="onAppear()" *ngIf="post?.description" class="message">
            <app-linkify-read-more [limit]="250" [content]="post?.description"></app-linkify-read-more>
        </div>
        <div class="container">

        </div>
        <div *ngIf="!post.isFileBeingUploaded" class="media">
            <app-gallery [items]="post?.files"></app-gallery>
        </div>
        <div *ngIf="post?.isFileBeingUploaded" class="file-upload">
            Uploading files, Please wait..
        </div>
        <div *ngIf="post?.attachingFile === true" class="file-upload">
            Loading files, Please wait..
        </div>
        <is-file-uploading [threadID]="post?.id" (isUploading)="isFilesUploading($event)"></is-file-uploading>
        <div fxLayout="row" fxLayoutAlign="start center" *ngSwitchCase="'thread'">
            <button mat-button class="share-button"
                    *ngIf="post.priority !== null && post?.priority !== 'nothing' && post.priority !== 'normal'">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="s-16">label</mat-icon>
                        <span *ngIf="post?.priority === 'notice'"


                              class="notice-priority">{{'post.notice' | translate}}</span>
                        <span *ngIf="post?.priority === 'important'"
                              class="important-priority">{{'post.important' | translate}}</span>
                        <span *ngIf="post?.priority === 'critical'"
                              class="critical-priority">{{'post.critical' | translate}}</span>
                    </span>
            </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" *ngSwitchCase="'task'">
            <button mat-button class="share-button">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <span *ngIf="post?.taskStatus === 'in_progress'" class="notice-priority">In Progress</span>
                        <span *ngIf="post?.taskStatus === 'to_do'" class="important-priority">To do</span>
                        <span *ngIf="post?.taskStatus === 'done'" class="green-priority">Done</span>
                    </span>
            </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <button mat-button class="like-button" (click)="setFooterType(FooterType.Comment)">
                        <span fxLayout="row" fxLayoutAlign="start center">
                            <span>{{'TASKS.comment' | translate}}</span>&nbsp;<span>({{post.clinicDocCommentsCount}}
                            )</span>
                        </span>
                </button>
                <button mat-button class="share-button" (click)="setFooterType(FooterType.File)">
                        <span fxLayout="row" fxLayoutAlign="start center">
                            <span>{{'TASKS.file' | translate}}</span>&nbsp;<span>({{ post?.clinicDocFileCount + post?.clinicDocCommentFileCount }}
                            )</span>
                        </span>
                </button>
            </div>
            <comment-read [id]="post?.id" [type]="readType?.doc" class="mr-16"></comment-read>
        </div>

    </div>
    <post-footer [post]="post" [type]="showFooterType"></post-footer>
</form>
