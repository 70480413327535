import { AfterViewInit, Component, Input, OnDestroy, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Gallery, ImageItem } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { GalleryRef } from 'ng-gallery/lib/services/gallery-ref';
import { saveAs } from 'file-saver';
import { AppUtils } from '../util';
import { ToastrService } from 'ngx-toastr';

interface FileInterface
{
    src: string;
    thumb_url: string;
    medium_url: string;
    content_type: string;
    name: string;
    size: number;
    date: Date;
    signed_id: string;
}

@Component({
    selector     : 'app-gallery',
    templateUrl  : './gallery.component.html',
    styleUrls    : ['./gallery.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GalleryComponent implements AfterViewInit, OnDestroy
{

    _items: FileInterface[] = [];
    displayItems: any[] = [];
    galleryId = 'myLightbox' + Math.random();
    galleryRef: GalleryRef;
    showLoadMore: boolean;
    displayGallery = false;
    documents: FileInterface[];
    videos: FileInterface[];
    @Input() size: string;


    @Input() set items(temp)
    {
        this._items = this.displayItems = this.documents = this.videos = [];
        this.displayGallery = false;
        if ( temp && temp.length )
        {
            const value = (temp as FileInterface[]).filter(f => f.content_type.includes('image'));
            this.videos = (temp as FileInterface[]).filter(f => f.content_type.includes('video')).map(v => {
                v.content_type = AppUtils.videoContentType(v.content_type);
                return v;
            });
            this.documents = (temp as FileInterface[]).filter(f => !(f.content_type.includes('image') || f.content_type.includes('video')));
            this.showLoadMore = value.length > 3;

            if ( value.length >= 3 )
            {
                this.displayItems = [{
                    cols: 2, rows: 2
                }, {
                    cols: 1, rows: 1
                }, {
                    cols: 1, rows: 1
                }];
            } else if ( value.length === 2 )
            {
                this.displayItems = [{
                    cols: 1, rows: 2
                }, {
                    cols: 1, rows: 2
                }];
            } else if ( value.length === 1 )
            {
                this.displayItems = [{
                    cols: 1, rows: 1
                }];
            }

            for (let i = 0; i < this.displayItems.length; i++)
            {
                this.displayItems[i].type = value[i].content_type;
                this.displayItems[i].medium_url = value[i].medium_url;
                this.displayItems[i].src = value[i].src;
                this.displayItems[i].name = value[i].name;
            }
            if ( this.size === 'small' )
            {
                this.displayItems = this.displayItems.splice(0, 2);
            }
            this._items = value;
        }

    }

    @ViewChild('itemTemplate') itemTemplate: TemplateRef<any>;
    @ViewChild('thumbTemplate') thumbTemplate: TemplateRef<any>;

    constructor(public gallery: Gallery, private lightbox: Lightbox,
                private toast: ToastrService,
    )
    {
    }

    openInFullScreen(index: number): void
    {
        this.lightbox.open(index, this.galleryId, {
            panelClass: 'fullscreen'
        });
    }

    ngOnDestroy(): void
    {
        this.galleryRef?.destroy();
    }

    ngAfterViewInit(): void
    {
        // Load items into gallery
        this.galleryRef = this.gallery.ref(this.galleryId);

        this.galleryRef.setConfig({
            itemTemplate : this.itemTemplate,
            thumbTemplate: this.thumbTemplate,
        });
        this.galleryRef.load(this._items.map((v) => {
            return new ImageItem({
                src  : v.src,
                thumb: v.medium_url
            });
        }));

        this.galleryRef.itemClick.subscribe((item) => {
        });
    }

    downloadPdf(pdfUrl: string, pdfName: string): void
    {
        this.toast.info('', 'Attachments are downloading');
        saveAs(pdfUrl, pdfName);
    }
}
