import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector     : 'app-image',
    templateUrl  : './image.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls    : ['./image.component.scss']
})
export class ImageComponent
{
    @Input() src: any;
    @Input() class: any;
    // defaultImage = 'https://via.placeholder.com/150/000000/FFFFFF/?text=IPaddress.net';
    // defaultImage = 'https://wpamelia.com/wp-content/uploads/2018/11/ezgif-2-6d0b072c3d3f.gif';
    defaultImage = '/assets/images/backgrounds/loading.gif';

    constructor()
    {
    }

}
