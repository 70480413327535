import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-web-storage';
import { RestService } from '../../service/api/rest.service';
import { Notification, NotificationsAdapter } from './notification.model';
import { map, tap } from 'rxjs/operators';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { ActionCableService } from '../../service/socket/action-cable.service';
import { NotificationEntityService } from './notification-entity.service';
import { ChatRoomsDataService } from '../chat-rooms/chat-room-data.service';
import { TasksEntityService } from '../task/task-entity.service';
import { Observable, Subject } from 'rxjs';
import { ChatRoomsEntityService } from '../chat-rooms/chat-room-entity.service';

@Injectable()
export class NotificationDataService extends DefaultDataService<Notification>
{
    private baseNotifications = 'doc_notifications';
    private isCalled = false;
    private groupJoiningRequests = new Subject<any>();

    constructor(http: HttpClient,
                httpUrlGenerator: HttpUrlGenerator,
                private rest: RestService,
                private local: LocalStorageService,
                private notificationsAdapter: NotificationsAdapter,
                private _fuseNavigationService: FuseNavigationService,
                private actionCableService: ActionCableService,
                private notificationEntityService: NotificationEntityService,
                private taskEntityService: TasksEntityService,
                private chatRoomsEntityService: ChatRoomsEntityService,
                private chatRoomsDataService: ChatRoomsDataService)
    {
        super('Friend', http, httpUrlGenerator);
    }

    getWithQuery(queryParams: QueryParams | string): any
    {
        return this.rest.get(`${(this.baseNotifications)}${(queryParams)}`).pipe(map(data => {
            const friends: Notification[] = [];
            for (const notification of  data.doc_notifications)
            {
                const temp = this.notificationsAdapter.adapt(notification);
                friends.push({...temp, id: temp.id});
            }
            return friends;
        }));
    }

    getGroupJoiningNotification(): Observable<any>
    {
        return this.groupJoiningRequests.asObservable();
    }

    updateNotificationCount(params): any
    {
        return this.rest.put(`${(this.baseNotifications)}/update_notification_unread_count`, params).pipe(tap(data => {
            return data;
        }));
    }

    getAllNotificationCount(): any
    {
        this.rest.get(`${(this.baseNotifications)}/get_user_all_notification_count`).subscribe((data) => {
            this.updateNotificationCountAction(data);
        });
        if ( !this.isCalled )
        {
            this.isCalled = true;
            this.actionCableService.getNotificationsUpdate().subscribe((data) => {
                switch (data.type)
                {
                    case 'thread_notification':
                        let tempNotifications: any = [];
                        let first = false;
                        this.notificationEntityService.entities$.subscribe((res) => {
                            if ( !first )
                            {
                                tempNotifications = [...res];
                                first = true;
                            }
                        });
                        const temp = this.notificationsAdapter.adapt({
                            user_notification_count: data.user_notification_count,
                            doc                    : data.doc.doc
                        });
                        const notificationIndex = tempNotifications.findIndex(res => +res?.id === +temp.id);
                        if ( notificationIndex > -1 )
                        {
                            tempNotifications.splice(notificationIndex, 1);
                            tempNotifications.unshift(temp);
                        } else
                        {
                            tempNotifications.unshift(temp);
                        }
                        this.updateNotificationCountAction({
                            notification_count: data.all_notification_count
                        });
                        this.notificationEntityService.clearCache();
                        this.notificationEntityService.addManyToCache(tempNotifications);

                        break;
                    case 'task_notification':
                        let taskNotifications: any = [];
                        let taskFirst = false;
                        this.notificationEntityService.entities$.subscribe((res) => {
                            if ( !taskFirst )
                            {
                                taskNotifications = [...res];
                                taskFirst = true;
                            }
                        });
                        const task = this.notificationsAdapter.adapt({
                            user_notification_count: data.user_notification_count,
                            activity_type          : data.activity_type,
                            doc                    : data.doc.doc
                        });
                        const taskNotificationIndex = taskNotifications.findIndex(res => +res?.id === task.id);
                        if ( taskNotificationIndex > -1 )
                        {
                            taskNotifications.splice(taskNotificationIndex, 1);
                            taskNotifications.unshift(task);
                        } else
                        {
                            taskNotifications.unshift(task);
                        }
                        this.updateNotificationCountAction({
                            notification_count: data.all_notification_count
                        });
                        this.notificationEntityService.clearCache();
                        this.notificationEntityService.addManyToCache(taskNotifications);

                        break;
                    case 'group_join_notification':
                        data.notification.notification.id = data.notification.notification.user_id;
                        data.notification.notification.activityType = 'clinic_join_request';
                        data.notification.notification.docType = 'clinic_join_request';
                        this.updateNotificationCountAction({
                            notification_count: data.all_notification_count
                        });
                        this.groupJoiningRequests.next(data.notification.notification);
                        break;
                    case 'friend_request_status':
                        break;
                    case 'notification_read':
                        let readNotifications: any = [];
                        let readFirst = false;
                        this.notificationEntityService.entities$.subscribe((res) => {
                            if ( !readFirst )
                            {
                                readNotifications = [...res];
                                readFirst = true;
                            }
                        });
                        const temporary = this.notificationsAdapter.adapt({
                            user_notification_count: data.user_notification_count,
                            doc                    : data.doc.doc
                        });
                        const currentNotification: Notification = readNotifications.find(res => +res?.id === temporary.id);
                        if ( currentNotification )
                        {
                            this.notificationEntityService.updateOneInCache({
                                userNotificationCount: 0,
                                id                   : currentNotification.id
                            });
                        }
                        this.updateNotificationCountAction({
                            notification_count: data.all_notification_count
                        });
                        break;
                    case 'all_chat_room_count':
                        this.chatRoomsDataService.updateMessageCountAction({
                            all_message_count: data.total_chat_room_count
                        });
                        break;
                    case 'read_from_other_device':
                        this.chatRoomsEntityService.updateOneInCache({
                            messageCount: 0,
                            id          : data.chat_room_id
                        });
                        break;
                }
            });
        }

    }

    updateNotificationCountAction(data: any): any
    {
        if ( data.notification_count === 0 )
        {
            const item =
                {
                    badge: null
                };
            this._fuseNavigationService.updateNavigationItem('notification', item);

        } else
        {
            setTimeout(() => {
                const nullItem =
                    {
                        badge: null
                    };
                this._fuseNavigationService.updateNavigationItem('notification', nullItem);
            }, 200);
            setTimeout(() => {
                const item =
                    {
                        badge: {
                            title: data.notification_count,
                            bg   : '#92254e',
                            fg   : '#FFFFFF'
                        }
                    };
                this._fuseNavigationService.updateNavigationItem('notification', item);
            }, 200);

        }
    }
}
