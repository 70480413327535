import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';

import { Subject, Subscription } from 'rxjs';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { SystemUsersEntityService } from '../../../store/system-users/system-user-entity.service';
import { SystemUsersDataService } from '../../../store/system-users/system-users-data.service';
import { NewAuthDataService } from '../../../service/api/newAuth-data.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import { ChatRoomsEntityService } from '../../../store/chat-rooms/chat-room-entity.service';
import { ChatRoomsDataService } from '../../../store/chat-rooms/chat-room-data.service';
import { ActionCableService } from '../../../service/socket/action-cable.service';
import * as _ from 'lodash';
import { FileUploadService } from '../../../service/api/file-upload.service';
import { AttachmentsComponent } from '../../../main/apps/components/attachments/attachments.component';
import { FriendDataService } from '../../../store/friend/friend-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { User } from 'app/types';
import { GroupUsersDataService } from '../../../store/group/GroupUsers/group-users-data.service';
import { ChatRoom, ChatRoomAdapter } from '../../../store/chat-rooms/chat-rooms.model';
import { ChatService } from '../../../main/apps/chat/chat.service';
import * as moment from 'moment';
import { NotificationDataService } from '../../../store/notifications/notification-data.service';
import { RichTextFieldComponent } from '../../../main/apps/components/rich-text-field/rich-text-field.component';
import { IContact, ISelectedContact } from '../../../types/Contact';
import { IChatUpdate, IChat } from '../../../types/ChatRoom';

@Component({
    selector     : 'chat-panel',
    templateUrl  : './chat-panel.component.html',
    styleUrls    : ['./chat-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatPanelComponent implements OnInit, AfterViewInit, OnDestroy
{
    contacts: ChatRoom[] = [];
    chat: IChat | any;
    selectedContact: ISelectedContact | any;
    user: User;
    @ViewChild(RichTextFieldComponent) replyInputField: RichTextFieldComponent;
    @ViewChild('attachmentsC', {static: false}) attachmentsC: AttachmentsComponent;

    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    // Private
    private _chatViewScrollbar: FusePerfectScrollbarDirective|any;
    private _unsubscribeAll: Subject<any>;
    page = 1;
    chatRoomIntiallised = false;
    isChatRoomCalled = false;
    chatUpdates: IChatUpdate = {
        observer   : null,
        chatChannel: null
    };
    editFlag = false;
    editMessageObj: any;
    removedFiles: any = [];
    editFileMessage = false;
    readChatFromMessages: Subscription;
    isLoadingCall = false;
    userInput = '';
    holdingScroll = false;
    isTyping: Subject<any> = new Subject();
    replyForm: FormGroup;
    isMessageSent = false;
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private systemUsersEntityService: SystemUsersEntityService,
        private systemUsersDataService: SystemUsersDataService,
        private progressBarService: FuseProgressBarService,
        private toastrService: ToastrService,
        private chatRoomsEntityService: ChatRoomsEntityService,
        private chatRoomsDataService: ChatRoomsDataService,
        private actionCableService: ActionCableService,
        private fileUploadService: FileUploadService,
        private friendDataService: FriendDataService,
        private router: Router,
        private _formBuilder: FormBuilder,
        private notificationDataService: NotificationDataService,
        private groupUser: GroupUsersDataService,
        private activatedRoute: ActivatedRoute,
        private _fuseNavigationService: FuseNavigationService,
        private newAuthDataService: NewAuthDataService,
        private chatService: ChatService,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.selectedContact = null;
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void
    {
        this.chatRoomsEntityService.entities$.subscribe((res) => {
            this.contacts = this.sortedChatRooms(res);
            this.holdingScroll = true;
        });
        this.replyForm = this._formBuilder.group({
            message   : ['']
        });
        this.newAuthDataService.getUser().subscribe((user: User) => {
            this.user = user;
            if ( !this.user )
            {
                return;
            }
            this.getSystemUser();
            if ( this.newAuthDataService.isActive() )
            {
                this.actionCableService.getNotificationsUpdate().subscribe((data) => {
                    switch (data.type)
                    {
                        case 'chat_room':
                            const newChatRoom = ChatRoomAdapter.adapt(data.chat_room);
                            const chatRoomIndex = this.contacts.findIndex((res: any) => +res?.chatRoomId === +data.chat_room.chat_room.chat_room_id);
                            if ( this.chatService.activeChatRoomID === +data.chat_room.chat_room.chat_room_id )
                            {
                                newChatRoom.messageCount = 0;
                            }
                            const tempChatRooms: any = [...this.contacts];
                            if ( chatRoomIndex > -1 )
                            {
                                tempChatRooms.splice(chatRoomIndex, 1);
                                tempChatRooms.unshift(newChatRoom);
                                this.chatRoomsEntityService.clearCache();
                                this.chatRoomsEntityService.addManyToCache(tempChatRooms);
                            } else
                            {
                                tempChatRooms.unshift(newChatRoom);
                                this.chatRoomsEntityService.clearCache();
                                this.chatRoomsEntityService.addManyToCache(tempChatRooms);
                            }
                            break;
                        case 'friend_request_status':
                            let tempCount = this._fuseNavigationService.getNavigationItem('messages').badge?.title;
                            if ( data.friend.user.friend_status === 'request' )
                            {
                                if ( this.user.id === data.friend.user.friend.receiver_id )
                                {
                                    if ( tempCount )
                                    {
                                        tempCount = ++tempCount;
                                    } else
                                    {
                                        tempCount = 1;
                                    }
                                    this.chatRoomsDataService.updateMessageCountAction({
                                        all_message_count: tempCount
                                    });
                                }
                            }
                            break;
                        case 'notification_read':
                            this.notificationDataService.updateNotificationCountAction({
                                notification_count: data.all_notification_count
                            });
                            break;
                    }
                });
                this.actionCableService.getUserAppearances().subscribe((data) => {
                    switch (data.type)
                    {
                        case 'online_status':
                            const tempContact: any = this.contacts.find((res: any) => {
                                return res.user?.id === data.user?.user.id;
                            });
                            if ( tempContact )
                            {
                                tempContact.user.online_status = data.user.user.online_status;
                            }
                            break;
                        case 'version_updated':
                            // @ts-ignore
                             window.location.reload(true); 
                             break;
                    }
                });
            }
        });
        this.readChatFromMessages = this.chatRoomsDataService.getReadChatFromMessages().subscribe((data) => {
            const tempIndex = this.contacts.findIndex((res: any) => +res.chatRoomId === +data.chatRoomId);
            if ( tempIndex !== -1 )
            {
                this.contacts[tempIndex].messageCount = 0;
            }
        });
        this._fuseSidebarService.resetChatOnClosing.subscribe(res => {
            if ( res )
            {
                this.resetChat();
            }
        });
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void
    {
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'messages';
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        this.isTyping.unsubscribe();
        this.readChatFromMessages.unsubscribe();
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Prepare the chat for the replies
     */
    private _prepareChatForReplies(): void
    {
        setTimeout(() => {
            this.replyInputField.myInput.nativeElement.focus();

            // Scroll to the bottom of the messages list
            if ( this._chatViewScrollbar )
            {
                this._chatViewScrollbar.update();

                setTimeout(() => {
                    this._chatViewScrollbar.scrollToBottom(0);
                });
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fold the temporarily unfolded sidebar back
     */
    foldSidebarTemporarily(): void
    {
        this._fuseSidebarService?.getSidebar('chatPanel')?.foldTemporarily();
    }

    /**
     * Unfold the sidebar temporarily
     */
    unfoldSidebarTemporarily(): void
    {
        this._fuseSidebarService?.getSidebar('chatPanel')?.unfoldTemporarily();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarOpen(): void
    {
        this._fuseSidebarService.getSidebar('chatPanel').toggleFold();
    }
    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    shouldShowContactAvatar(message, i): boolean
    {
        return (
            message.who === this.selectedContact?.user?.id &&
            ((this.chat.dialog[i + 1] && this.chat.dialog[i + 1].who !== this.selectedContact?.user?.id) || !this.chat.dialog[i + 1])
        );
    }

    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(message, i): boolean
    {
        return (i === 0 || this.chat.dialog[i - 1] && this.chat.dialog[i - 1].who !== message.who);
    }

    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message, i): boolean
    {
        return (i === this.chat.dialog.length - 1 || this.chat.dialog[i + 1] && this.chat.dialog[i + 1].who !== message.who);
    }

    /**
     * Toggle chat with the contact
     *
     * @param contact
     */
    toggleChat(contact): void
    {
        if ( this.chatUpdates?.chatChannel )
        {
            this.chatUpdates.chatChannel.unsubscribe();
            this.isChatRoomCalled = false;
        }
        if ( contact.messageCount )
        {
            contact.messageCount = 0;
        }
        this.selectedContact = contact;
        this.chatRoomIntiallised = false;
        this.unfoldSidebarTemporarily();
        this.attachmentsC?.reset();
        if ( this.selectedContact?.chatRoomId )
        {
            this.systemUsersDataService.getChatRoomMessages(this.selectedContact?.chatRoomId, { page  : 1, limit : 20 }).subscribe((data) => {
                const dummyChat: any = [];
                this._prepareChatForReplies();
                for (const dummyChatKey of data.message.reverse())
                {
                    dummyChat.push({
                        who           : dummyChatKey?.sender_id,
                        message       : dummyChatKey?.message,
                        time          : dummyChatKey?.created_at,
                        message_type  : dummyChatKey?.message_type,
                        files         : dummyChatKey?.files,
                        messageID     : dummyChatKey?.id,
                        senderIsRead  : dummyChatKey?.sender_is_read,
                        receiverIsRead: dummyChatKey?.receiver_is_read
                    });
                }
                this.chat = {
                    id    : this.selectedContact?.chatRoomId,
                    dialog: dummyChat
                };
                this.chatRoomsEntityService.getByKey(this.selectedContact?.chatRoomId).subscribe((_data: ChatRoom) => {
                    this.chatRoomIntiallised = true;
                    this.chatRoomsEntityService.updateOneInCache({messages: dummyChat, id: _data.id, messageCount: 0});
                });
            });
            this.socketCall();
        } else
        {
            this.chat = {
                id    : '',
                dialog: []
            };
        }
    }

    /**
     * Remove the selected contact and unload the chat
     */
    resetChat(): void
    {
        // Set the selected contact as null
        this.selectedContact = null;
        this.chatRoomIntiallised = false;
        // Set the chat as null
        this.chat = null;
        if ( this.chatUpdates?.chatChannel )
        {
            this.chatUpdates.chatChannel.unsubscribe();
            this.isChatRoomCalled = false;
        }
    }

    socketCall(): any
    {
        this.isChatRoomCalled = true;
        this.chatUpdates = this.actionCableService.getChatUpdates(this.selectedContact?.chatRoomId);
        this.actionCableService.getAppearanceChannel().send({
            chat_room_id: this.selectedContact?.chatRoomId,
            user_id     : this.user.id,
            action      : 'message_read'
        });
        this.chatUpdates.chatChannel.send({
            chat_room_id: this.selectedContact?.chatRoomId,
            user_id     : this.user.id,
            action      : 'message_read'
        });
        this.chatUpdates.observer.subscribe((res) => {
            switch (res.type)
            {
                case 'new':
                    if ( res.message.receiver_id === this.user.id )
                    {
                        this.chat.dialog.push({
                            who           : res.message.sender_id,
                            message       : res.message.message,
                            time          : res.message.created_at,
                            message_type  : res.message.message_type,
                            files         : res.message.files,
                            messageID     : res.message.id,
                            senderIsRead  : res.message.sender_is_read,
                            receiverIsRead: res.message.receiver_is_read
                        });
                        this._prepareChatForReplies();
                        this.actionCableService.getAppearanceChannel().send({
                            chat_room_id: this.selectedContact?.chatRoomId,
                            user_id     : this.user.id,
                            action      : 'message_read'
                        });
                        this.chatUpdates.chatChannel.send({
                            chat_room_id: this.selectedContact?.chatRoomId,
                            user_id     : this.user.id,
                            action      : 'message_read'
                        });
                    }
                    if ( res.message.sender_id === this.user.id )
                    {
                        this.chat.dialog.push({
                            who           : this.user.id,
                            message       : res.message.message,
                            time          : res.message.created_at,
                            message_type  : res.message.message_type,
                            files         : res.message.files,
                            messageID     : res.message.id,
                            senderIsRead  : res.message.sender_is_read,
                            receiverIsRead: res.message.receiver_is_read
                        });
                        this._prepareChatForReplies();
                    }
                    break;
                case 'update':
                    const updateIndex = this.chat.dialog.findIndex(log => +log.messageID === +res.message.id);
                    const itemToBeUpdated = this.chat.dialog[updateIndex];
                    if ( res.message.message_type === 'message' )
                    {
                        itemToBeUpdated.message = res.message.message;
                        this.chat.dialog.splice(updateIndex, 1, itemToBeUpdated);
                        this.chat.dialog = [...this.chat.dialog];
                    } else
                    {
                        itemToBeUpdated.files = res.message.files;
                        this.chat.dialog.splice(updateIndex, 1, itemToBeUpdated);
                        this.chat.dialog = [...this.chat.dialog];
                    }
                    break;
                case 'delete':
                    const deleteIndex = this.chat.dialog.findIndex(log => +log.messageID === +res.message.id);
                    this.chat.dialog.splice(deleteIndex, 1);
                    this.chat.dialog = [...this.chat.dialog];
                    this._prepareChatForReplies();
                    break;
                case 'read_message':
                    if ( +this.selectedContact.user.id === +res.user_id )
                    {
                        if ( this.chat )
                        {
                            for (const message of this.chat.dialog)
                            {
                                message.receiverIsRead = true;
                            }
                            this.chat.dialog = [...this.chat.dialog];
                        }
                    }
                    break;
                case 'typing':
                    if ( +this.selectedContact?.user.id === +res.user.user.id )
                    {
                        this.isTyping.next('typing');
                    }
                    break;
            }
        });
    }
    userIsTyping(): any
    {
        return this.isTyping.asObservable();
    }
    /**
     * Reply
     */
    async reply(event): Promise<void>
    {

        event.preventDefault();
        if ( this.editMessageObj?.message_type !== 'comment' && this.attachmentsC?.attachedCount === 0 && this.editMessageObj?.files?.length === 0 )
        {
            this.toastrService.error('Please select at least one file.', 'No Files');
            return;
        }
        let files: any = [];
        this.progressBarService.show();
        this.isMessageSent = true;
        if ( this.attachmentsC.attachedCount > 0 )
        {
            this.toastrService.info('Chat attachments are uploading!');
            files = await this.attachmentsC.directUpload();
        }

        if ( this.editFlag )
        {
            if ( this.editMessageObj?.message_type === 'message' )
            {
                if ( !this.replyForm.value.message )
                {
                    return;
                }
                const updateData = {
                    message: {
                        'message': this.replyForm.value.message,
                    }
                };
                this.systemUsersDataService.updateChatMessage(this.editMessageObj.messageID, updateData).subscribe((response) => {
                    this.progressBarService.hide();
                    this.isMessageSent = false;
                    this.toastrService.success('', 'Message Updated');
                    const messageIndex = this.chat.dialog.findIndex((message) => +message.messageID === +this.editMessageObj.messageID);
                    this.chat.dialog[messageIndex].message = this.replyForm.value.message;
                    this.replyForm.reset();
                    this.editFlag = false;
                    this._prepareChatForReplies();
                }, (error) => {
                    this.isMessageSent = false;
                    this.progressBarService.hide();
                    this.toastrService.error(error.errors.join(','), 'Error');
                });
            } else
            {
                for (const removedFile of this.removedFiles)
                {
                    try
                    {
                        await this.systemUsersDataService.deleteChatFile(removedFile.signed_id).toPromise();
                    }catch (e){
                    }
                }
                files = [...this.editMessageObj.files, ...files];
                const updateData = {
                    message: {
                        'files': files.map(f => f.signed_id),
                    }
                };
                this.systemUsersDataService.updateChatMessage(this.editMessageObj.messageID, updateData).subscribe((response) => {
                    this.progressBarService.hide();
                    this.isMessageSent = false;
                    const messageIndex = this.chat.dialog.findIndex((message) => +message.messageID === +this.editMessageObj.messageID);
                    this.chat.dialog[messageIndex].files = response.message.files;
                    this.resetEdit();
                    this._prepareChatForReplies();
                }, (error) => {
                    this.isMessageSent = false;
                    this.progressBarService.hide();
                    this.toastrService.error(error.errors.join(','), 'Error');
                });
            }
        } else
        {
            // Message
            const messageData: any = {
                message: {
                    'receiver_id' : this.selectedContact.user?.id,
                    'message'     : this.replyForm.value.message,
                    'message_type': 'message'
                }
            };
            if ( files.length > 0 )
            {
                messageData.message = {
                    ...messageData.message,
                    'message_type': 'file',
                    'files'       : files.map(f => f.signed_id)
                };
            }
            if ( messageData.message.message_type === 'message' && !this.replyForm.value.message?.trim() )
            {
                return;
            }
            this.systemUsersDataService.createChatMessage(messageData).subscribe((data) => {
                this.attachmentsC.reset();
                this.progressBarService.hide();
                this.isMessageSent = false;

                const selectedIndex = _.findIndex(this.contacts, {userId: this.selectedContact.user?.id});
                this.contacts[selectedIndex] = this.selectedContact;

                if ( this.selectedContact?.chatRoomId )
                {
                    this.selectedContact.chatRoomId = data.message.chat_room_id;
                    this.chatRoomsEntityService.updateOneInCache({
                        messages    : this.chat.dialog,
                        lastMessage : data.message.message,
                        messageCount: 0,
                        id          : this.selectedContact.chatRoomId
                    });
                } else
                {
                    this.selectedContact.chatRoomId = data.message.chat_room_id;
                    this.chat.dialog.push({
                        who           : this.user.id,
                        message       : data.message.message,
                        time          : data.message.created_at,
                        message_type  : data.message.message_type,
                        files         : data.message.files,
                        messageID     : data.message.id,
                        senderIsRead  : data.message.sender_is_read,
                        receiverIsRead: data.message.receiver_is_read
                    });
                    this._prepareChatForReplies();
                    if ( this.chatRoomIntiallised === false )
                    {
                        this.chatRoomsEntityService.getByKey(this.selectedContact.chatRoomId).subscribe((res) => {
                            this.chatRoomIntiallised = true;
                            this.chatRoomsEntityService.updateOneInCache({
                                messages    : this.chat.dialog,
                                lastMessage : data.message.message,
                                messageCount: 0,
                                id          : res.id
                            });
                        });
                    } else
                    {
                        this.chatRoomsEntityService.updateOneInCache({
                            messages    : this.chat.dialog,
                            lastMessage : data.message.message,
                            messageCount: 0,
                            id          : this.selectedContact.chatRoomId
                        });
                    }
                    if ( !this.isChatRoomCalled )
                    {
                       this.socketCall();
                    }
                }
                this.replyForm.reset();
                this._prepareChatForReplies();
                this.systemUsersEntityService.updateOneInCache({
                    // @ts-ignore
                    users: [this.selectedContact], id: this.selectedContact.user?.id
                });

            }, (error) => {
                this.isMessageSent = false;
                this.progressBarService.hide();
                this.toastrService.error(error.errors.join(','), 'Error');
            });
        }
    }

    onScrollDown(): any
    {
        this.holdingScroll = false;
        this.chatRoomsDataService.getChatRooms({
            page : ++this.page
        }).subscribe((data) => {
            this.holdingScroll = true;
        });
    }

    editMessage(message): void
    {
        this.editMessageObj = null;
        this.editFileMessage = false;
        this.attachmentsC?.reset();
        this.editFlag = true;
        if ( message.message_type === 'file' )
        {
            this.editFileMessage = true;
        } else
        {
            setTimeout(() => {
                this.replyForm.patchValue({
                    message: message.message
                });
            }, 100);
        }
        this.editMessageObj = message;
    }

    async deleteMessage(message, index): Promise<void>
    {
        this.progressBarService.show();
        if ( message.message_type === 'message' )
        {
            this.systemUsersDataService.deleteChatMessage(message.messageID).subscribe((data) => {
                    this.progressBarService.hide();
                    this.toastrService.success('', data.message);
                    this.chat.dialog.splice(index, 1);
                    this._prepareChatForReplies();
                },
                (error) => {
                    this.progressBarService.hide();
                    this.toastrService.error(error.errors.join(', '), 'Error');
                });
        } else
        {
            for (const removedFile of message.files)
            {
                await this.systemUsersDataService.deleteChatFile(removedFile.signed_id).toPromise();
            }
            this.progressBarService.hide();
            this.chat.dialog.splice(index, 1);
            this._prepareChatForReplies();
        }
    }


    removeAttachment(attachment): any
    {
        this.removedFiles.push(attachment);
    }

    private getSystemUser(): void
    {
        if ( this.newAuthDataService.isActive() )
        {
            if ( !this.isLoadingCall )
            {
                this.isLoadingCall = true;
                this.page = 1;
                this.chatRoomsDataService.getChatRooms({
                    page : this.page
                }).subscribe((data) => {
                    this.isLoadingCall = false;
                });
            }

        }
    }

    resetEdit(): void
    {
        this.replyForm.reset();
        this.editFlag = false;
        this.editFileMessage = false;
        this.editMessageObj = null;
        this.attachmentsC.reset();
    }

    userTyping(event): any
    {
        if ( event?.length > 0 )
        {
            this.userInput = event;
            this.actionCableService.getAppearanceChannel().send({
                chat_room_id: this.selectedContact?.chatRoomId,
                action      : 'typing'
            });
        }
    }

    sortedChatRooms(chatRoomlists: ChatRoom[]): any
    {
        let messagesWithCount = chatRoomlists.filter(({messageCount}) => messageCount > 0);
        let messagesWithoutCount = chatRoomlists.filter(({messageCount}) => messageCount <= 0);
        messagesWithCount = messagesWithCount.sort((a, b) => {
            return moment(b.lastMessageDate).valueOf() - moment(a.lastMessageDate).valueOf();
        });
        messagesWithoutCount = messagesWithoutCount.sort((a, b) => {
            return moment(b.lastMessageDate).valueOf() - moment(a.lastMessageDate).valueOf();
        });

        return [...messagesWithCount, ...messagesWithoutCount];
    }
}
