import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-web-storage';
import { GroupAdapter, GroupUser } from '../group.model';
import { RestService } from '../../../service/api/rest.service';


@Injectable()
export class GroupUsersDataService extends DefaultDataService<GroupUser>
{

    private _baseClinicUsers = 'clinic_users';
    private _baseClinic = 'clinics';

    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private rest: RestService, private local: LocalStorageService, private adapter: GroupAdapter)
    {
        super('GroupUser', http, httpUrlGenerator);
    }

    getWithQuery(queryParams: QueryParams | string): Observable<GroupUser[]>
    {
        return this.rest.get(`${(this._baseClinic)}/${queryParams}/clinic_users`).pipe(map(data => {
            const groupUser: GroupUser[] = [];
            for (let key in data.clinic_admins)
            {
                groupUser.push({...data.clinic_admins[key], id: data.clinic_admins[key].id});
            }
            for (let key in data.joined_users)
            {
                groupUser.push({...data.joined_users[key], id: data.joined_users[key].id});
            }
            for (let key in data.requested_users)
            {
                groupUser.push({...data.requested_users[key], id: data.requested_users[key].id});
            }
            for (let key in data.invitations)
            {
                groupUser.push({
                    clinic_role   : 'invited',
                    role          : 'invited',
                    request_status: 'invited',
                    userId        : 'invited', ...data.invitations[key],
                    id            :  +key - data.invitations.length
                });
            }
            return groupUser;
        }));
    }

    getGroupUsers(id: number, params): any
    {
        params.limit = 25;
        return this.rest.get(`${(this._baseClinic)}/${id}/clinic_users`, params).pipe(tap(data => {
            return {...data};
        }));
    }

    updateGroupUserStatus(param): any
    {
        return this.rest.put(`${(this._baseClinicUsers)}/update_clinic_user_status`, param).pipe(tap((data) => {
            return data;
        }));
    }

    removeUserFromGroup(param): any
    {
        return this.rest.post(`${(this._baseClinicUsers)}/remove_users_from_group`, param).pipe(tap((data) => {
            return data;
        }));
    }

    addUserFromSettings(param): any
    {
        return this.rest.post(`${(this._baseClinicUsers)}/add_user_via_settings`, param).pipe(tap((data) => {
            return data;
        }));
    }
}
