import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { ReplaySubject, Subject } from 'rxjs';
import { Blob } from 'activestorage';
import { User } from '../../types';
import { NewAuthDataService } from './newAuth-data.service';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService
{
    userSubject = new ReplaySubject<User>();
    public queue: any = [];
    public isUploadCalled = false;
    public uploadRequests = new Subject<any>();

    constructor(private rest: RestService, private authDataService: NewAuthDataService)
    {
        if ( this.authDataService.getSignedInUser() )
        {
            this.userSubject.next(this.authDataService.getSignedInUser());
        }
        this.uploadRequests.subscribe(async (fileData) => {
            this.queue = [...this.queue, fileData];
            if ( this.queue.length > 0 && !this.isUploadCalled )
            {
                this.isUploadCalled = true;
                for (const queueItem of this.queue)
                {

                    let signedIdList: any = [];
                    for (let j = 0; j < queueItem.files.length; j++)
                    {
                        const res = await this.uploadDirectFile(queueItem.files[j].file, j, queueItem.files.length);
                        if ( typeof res === 'string' )
                        {
                            signedIdList = [res];
                        } else
                        {
                            signedIdList.push({signed_id: res.signed_id});
                        }
                    }
                    queueItem.sub.next(signedIdList);
                    queueItem.sub.complete();
                }
                this.queue = [];
                this.isUploadCalled = false;
            }
        });
    }

    uploadDirectFile(file, currentFileNumber, totalFiles): Promise<Blob>
    {
        return this.rest.postDirect(file, currentFileNumber, totalFiles);
    }

}
