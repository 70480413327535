import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { SingleProductComponent } from './single-product.component';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { SwiperModule } from 'swiper/angular';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonPipeModule } from '../../../../common-pipe/common-pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import localeDa from '@angular/common/locales/da';

registerLocaleData(localeDa);


@NgModule({
  declarations: [ SingleProductComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatMenuModule,
        SwiperModule,
        MatIconModule,
        FuseSharedModule,
        MatButtonModule,
        TranslateModule,
        MatTooltipModule,
        MatDialogModule,
        MatDividerModule,
        CommonPipeModule
    ],
    exports: [
        SingleProductComponent
    ]
})
export class SingleProductModule { }
