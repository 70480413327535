import { NgModule } from '@angular/core';
import { PostComponent } from './post.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { GalleryModule } from '../gallery/gallery.module';
import { UtilModule } from '../util/util.module';
import { MomentModule } from 'ngx-moment';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { FooterComponent } from './footer/footer.component';
import { CommentsModule } from '../comments/comments.module';
import { PostFilesComponent } from './footer/post-files/post-files.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { CommonDirectiveModule } from '../../../../directive/common-directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { LinkifyReadMoreModule } from '../../../../layout/components/linkify-read-more/linkify-read-more.module';
import { ConfirmationDialogModule } from '../dialogs/confirmation-dailog/confirmation-dialog.module';
import { IsFileUploadingComponent } from './is-file-uploading/is-file-uploading.component';


@NgModule({
    declarations: [PostComponent, FooterComponent, PostFilesComponent, IsFileUploadingComponent],
    exports     : [
        PostComponent,
        FooterComponent
    ],
    imports     : [
        FuseSharedModule,
        MatIconModule,
        MatButtonModule,
        GalleryModule,
        UtilModule,
        MomentModule,
        MatMenuModule,
        MatRadioModule,
        CommentsModule,
        MatTooltipModule,
        RouterModule,
        MatProgressBarModule,
        MatRippleModule,
        CommonDirectiveModule,
        TranslateModule,
        LinkifyReadMoreModule,
        ConfirmationDialogModule,
    ]
})
export class PostModule
{
}
