import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-web-storage';
import { RestService } from '../../service/api/rest.service';

import { Friend, FriendsAdapter } from './friend.model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FriendDataService extends DefaultDataService<Friend>
{
    private baseFriends = 'friends';
    private baseUsers = 'users';
    public requestStatus = new Subject<any>();

    constructor(http: HttpClient,
                httpUrlGenerator: HttpUrlGenerator,
                private rest: RestService,
                private local: LocalStorageService,
                private friendsAdapter: FriendsAdapter)
    {
        super('Friend', http, httpUrlGenerator);
    }

    passValue(data): void
    {
        this.requestStatus.next(data);
    }

    getAll(): Observable<Friend[]>
    {
        return this.rest.get(`${(this.baseFriends)}`).pipe(map(data => {
            const friends: Friend[] = [];
            for (const friend of data.friends)
            {
                const temp = this.friendsAdapter.adapt(friend);
                friends.push({...temp, id: temp.id});
            }
            return friends;
        }));
    }

    getFriendProfile(id): any
    {
        return this.rest.get(`${(this.baseUsers)}/${(id)}`).pipe(map(data => {
            return data;
        }));
    }

    getRequestedFriends(): any
    {
        return this.rest.get(`${(this.baseFriends)}/requested`).pipe(map(data => {
            const friends: Friend[] = [];
            for (const friend of data.friends)
            {
                const temp = this.friendsAdapter.adapt(friend);
                friends.push({...temp, id: temp.id});
            }
            return friends;
        }));
    }

    updateFriendStatus(params): any
    {
        return this.rest.post(`${(this.baseFriends)}/update_request_status`, params).pipe(map(data => {
            return this.friendsAdapter.adapt(data.user);
        }));
    }

    createFriendRequest(params): any
    {
        return this.rest.post(`${(this.baseFriends)}`, params).pipe(map(data => {
            return data;
        }));
    }
}
