import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'terms-and-conditions-modal',
    templateUrl: './terms-and-conditions-modal.component.html',
    styleUrls: ['./terms-and-conditions-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TermsAndConditionsModalComponent implements OnInit {

    constructor(public matDialogRef: MatDialogRef<TermsAndConditionsModalComponent>,
                @Inject(MAT_DIALOG_DATA) private _data: any,
                private _matDialog: MatDialog,) {
    }

    ngOnInit(): void {
    }

}
