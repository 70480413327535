import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    Output,
    EventEmitter
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

import { fromEvent, Observable, of, Subject } from 'rxjs';
import { TimelineService } from './timeline.service';
import { ThreadDialogComponent } from '../dialogs/thread/thread.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ThreadsEntityService } from '../../../../store/thread/threads-entity.service';
import { Thread } from '../../../../store/thread/thread.model';
import { GroupsDataService } from '../../../../store/group/groups-data.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import { GroupDialogComponent } from '../dialogs/group/group.component';
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { NewAuthDataService } from '../../../../service/api/newAuth-data.service';
import { User } from '../../../../types';
import { GuestModalComponent } from '../dialogs/guest-modal/guest-modal.component';
import { ThreadsDataService } from '../../../../store/thread/threads-data.service';

@Component({
    selector     : 'timeline',
    templateUrl  : './timeline.component.html',
    styleUrls    : ['./timeline.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class TimelineComponent implements OnInit, OnDestroy
{
    timeline: any;
    threadsData: any[];
    threads: any;
    loadCounter = 0;
    allGroupData: any[];
    holdingScroll = false;
    currentUser: User;
    resetSearchText = false;
    @Output() searchEvent = new EventEmitter<string>();
    page = 1;
    searchText = '';
    viewMode = 'grid';
    allGroups$: Observable<any>;
    carouselFeatures = [{title: 'NAV.NEWGROUP.ADDGROUP', icon: 'add'}, {title: 'NAV.NEWGROUP.FINDGROUP', icon: 'search'}];

    // Private
    private _unsubscribeAll: Subject<any>;
    threads$: Observable<Thread[]>;

    /**
     * Constructor
     *
     * @param _timelineService
     * @param store
     * @param _authService
     * @param _matDialog
     * @param route
     * @param threadEntity
     */
    constructor(
        private _timelineService: TimelineService,
        private _matDialog: MatDialog,
        private route: ActivatedRoute,
        private threadEntity: ThreadsEntityService,
        private userService: NewAuthDataService,
        private groupDataService: GroupsDataService,
        private threadDataService: ThreadsDataService,
        private progressBarService: FuseProgressBarService,
        private toast: ToastrService,
    )
    {

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // this.threads = [];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.threads$ = this.threadEntity.entities$;
        this.gettingGroups();
        this.getCurrentUser();
    }




    gettingGroups(): void
    {
        const groupData = {
            'request_status': 'approved',
        };
        this.progressBarService.show();
        this.groupDataService.getGroupListing(groupData).subscribe((data) => {
            this.allGroupData = data.groups;
            for (const feature of this.carouselFeatures)
            {
                this.allGroupData.push(feature);
            }
            this.allGroups$ = of(this.allGroupData);
            this.progressBarService.hide();
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.error, 'Error');
        });
    }
    getCurrentUser(): any{
        this.userService.getUser().pipe(takeUntil(this._unsubscribeAll)).subscribe(async (user) => {
            this.currentUser = user;
        });
    }
    private loadData(data: any[]): void
    {
        this.threadsData = [...data];
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    openThreadCreationDialog(): void
    {

        this.loadCounter = this.loadCounter + 1;
        this._matDialog.open(ThreadDialogComponent, {
            panelClass: 'card-dialog',
            data      : {
                new    : true,
                counter: this.loadCounter,
            }
        });
    }
    openGroupDialog(): void{
        if (this.currentUser.email === 'guest@onlinedoc.dk')
        {
            this._matDialog.open(GuestModalComponent, {
                panelClass: 'guest-modal',
                data      : {
                    name: 'test',
                }
            }).afterClosed().subscribe((res) => {

            });
            return;
        }
            this._matDialog.open(GroupDialogComponent, {
                panelClass: 'group-card-dialog',
            });
    }
    onScrollDown(): any
    {

    }
}
