import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler
{

    handleError(error: any): void
    {
        const chunkFailedMessage = /Loading chunk (.)+ failed/;

        if ( chunkFailedMessage.test(error.message) )
        {
            window.location.reload(true);
        } else
        {
            if ( environment.production )
            {
                // tslint:disable-next-line:no-console
                console.trace(error);
            } else
            {
                console.error(error);
            }
        }
    }
}
