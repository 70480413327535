<div class="app-gallery" *ngIf="displayItems.length>0 && size!='small'">
    <mat-grid-list style="height: 400px" [cols]="displayItems.length" [rowHeight]="'fit'">
        <mat-grid-tile *ngFor="let item of displayItems; let i = index"
                       (click)="openInFullScreen(i)"
                       [colspan]="item.cols"
                       [rowspan]="item.rows">
            <div class="type-container">
                <app-image [src]="item.medium_url"></app-image>
                <div *ngIf="showLoadMore && i==(displayItems.length-1)" class="load-more">
                    <div class="center-content">
                        +{{_items.length - displayItems.length}}
                    </div>
                </div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<div class="file-gallery" *ngIf="size!='small'">
    <ng-container *ngFor="let item of documents; let i = index">
        <a mat-raised-button [href]="item.src" target="_blank" class="m-4">
            <div fxLayout="row" fxLayoutAlign="start center" class="main">
                <mat-icon class="s-24 icon">picture_as_pdf</mat-icon>
                <div fxLayout="column" fxLayoutAlign="center start" class="title-div">
                            <span class="main-title">{{ (item?.name > 17) ? item?.name : item?.name | slice: 0 : 17 }}
                                <span *ngIf="item?.name?.length > 17">...</span>
                            </span>
                    <span class="sub-title">{{'GALLERY.application' | translate}}</span>
                </div>
                <mat-icon class="s-24 download-icon">cloud_download</mat-icon>
            </div>
        </a>
    </ng-container>
</div>

<div fxLayout="column" fxLayoutAlign="end end">
    <div class="app-gallery small" *ngIf="displayItems.length>0 && size=='small'">
        <div fxLayout="row" fxLayoutGap="10px">
            <div *ngFor="let item of displayItems; let i = index"
                 (click)="openInFullScreen(i)">
                <div class="type-container">
                    <app-image [src]="item.medium_url"></app-image>
                    <div *ngIf="i>=1 && _items.length>2" class="load-more">
                        <div class="center-content">
                            +{{_items.length - 2}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="video {{size}}" *ngFor="let items of videos">
        <video controls>
            <source [src]='items.src' [type]="items.content_type"/>
            {{'GALLERY.browser_not_supported' | translate}}
        </video>
    </div>

    <div *ngIf="size=='small'">
        <div class="file-item"
             *ngFor="let item of documents; let i = index"
             fxLayout="column" fxLayoutAlign="center stretch">
            <span class="main-title">{{ item.name }}</span>
            <span class="sub-title">{{'GALLERY.application' | translate}}</span>
            <div fxFlex fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="s-50 icon">picture_as_pdf</mat-icon>
            </div>
            <div class="bb"></div>
            <div fxLayout="row wrap" fxLayoutAlign="center center">
                <button mat-button
                        (click)="downloadPdf(item.src,item.name)"
                        class="w-100-p">{{'GALLERY.download' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #itemTemplate
             let-index="index"
             let-type="type"
             let-data="data"
             let-currIndex="currIndex">
    <div class="custom-template" *ngIf="data.extra">
        <button mat-raised-button color="accent">
            {{'GALLERY.download' | translate}}
        </button>
    </div>
</ng-template>


<!--<div *ngIf="post.media" class="media">-->
<!--    <img *ngIf="post.media.type === 'image'" [src]="post.media.preview">-->
<!--    <div *ngIf="post.media.type === 'video'" [innerHtml]="post.media.embed"></div>-->
<!--</div>-->
