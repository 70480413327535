import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentPreviewComponent } from './attachment-preview.component';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    declarations: [AttachmentPreviewComponent],
    exports     : [
        AttachmentPreviewComponent
    ],
    imports     : [
        CommonModule,
        FlexModule,
        MatButtonModule,
        MatIconModule
    ]
})
export class AttachmentPreviewModule
{
}
