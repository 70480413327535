import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { GroupUser } from '../group.model';
import { RestService } from '../../../service/api/rest.service';

@Injectable({providedIn: 'root'})
export class GroupUsersEntityService extends EntityCollectionServiceBase<GroupUser>
{
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private rest: RestService)
    {
        super('GroupUser', serviceElementsFactory);
    }
}
