import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewAuthDataService } from '../../../../../../service/api/newAuth-data.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { NewAuthService } from '../../../../../../service/api/newAuth.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { IDropDown } from '../../../../../../types/Dropdown';
import { User } from 'app/types';


export const MY_FORMATS = {
    parse  : {
        dateInput: 'LL',
    },
    display: {
        dateInput         : 'DD/MM/YY',
        monthYearLabel    : 'YYYY',
        dateA11yLabel     : 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};

@Component({
    selector     : 'basic-information-form',
    templateUrl  : './basic-information-form.component.html',
    styleUrls    : ['./basic-information-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations,
    providers    : [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class BasicInformationFormComponent implements OnInit
{
    @Output() cancelEvent = new EventEmitter<boolean>();
    basicInformationForm: FormGroup;
    genders: IDropDown[] = [
        {value: 'male', viewValue: 'Male'},
        {value: 'female', viewValue: 'Female'}
    ];
    currentUser: User;
    selectedValue: string;
    dob: string;
    maxDate = new Date();

    constructor(private _formBuilder: FormBuilder,
                private newAuthDataService: NewAuthDataService,
                private progressBarService: FuseProgressBarService,
                private toast: ToastrService,
                private newAuthService: NewAuthService)
    {
    }

    ngOnInit(): void
    {
        this.basicInformationForm = this._formBuilder.group({
            dateOfBirth: ['', Validators.required],
            gender     : ['']
        });
        this.newAuthService.entities$.subscribe((user: any) => {
            this.currentUser = user[0];
            this.dob = this.currentUser.dob;
            if ( this.currentUser.gender === 'M' )
            {
                this.selectedValue = this.genders[0].value;
            } else if ( this.currentUser.gender === 'F' )
            {
                this.selectedValue = this.genders[1].value;
            }
            this.basicInformationForm.patchValue({
                dateOfBirth: moment(this.dob),
                gender     : this.selectedValue
            });
        });

    }

    submit(): void
    {
        if ( this.basicInformationForm.valid )
        {
            let tempGender = '';
            if ( this.basicInformationForm.get('gender')?.value === 'male' )
            {
                tempGender = 'M';
            } else if ( this.basicInformationForm.get('gender')?.value === 'female' )
            {
                tempGender = 'F';
            }
            this.progressBarService.show();
            const userData = {
                'user': {
                    dob   : moment(this.basicInformationForm.get('dateOfBirth')?.value).format('YYYY-MM-DD'),
                    gender: tempGender
                }
            };
            this.newAuthDataService.updateUserData(userData).subscribe((data) => {
                    this.toast.success('', 'Profile Updated');
                    this.progressBarService.hide();
                    this.cancelEvent.emit(false);
                },
                (error) => {
                    this.toast.error(error.errors.join(', '), 'Error');
                    this.progressBarService.hide();
                });
        }
    }
}
