import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UploadingFilesEntityService } from '../../../../../store/uploading-files/uploading-files-entity.service';
import { Subscription } from 'rxjs';

@Component({
    selector   : 'is-file-uploading',
    templateUrl: './is-file-uploading.component.html',
    styleUrls  : ['./is-file-uploading.component.scss']
})
export class IsFileUploadingComponent implements OnInit, OnDestroy
{

    uploadingSubscription: Subscription;
    @Input() threadID: number;
    @Output() isUploading = new EventEmitter<boolean>();

    constructor(private uploadingFilesEntityService: UploadingFilesEntityService)
    {
    }

    ngOnInit(): void
    {
        this.uploadingSubscription = this.uploadingFilesEntityService.entities$.subscribe((data) => {
            const found = data.find((element) => element.threadId === this.threadID);
            if ( found )
            {
                this.isUploading.emit(true);
            } else
            {
                this.isUploading.emit(false);
            }
        });
    }

    ngOnDestroy(): void
    {
        this.uploadingSubscription.unsubscribe();
    }
}
