import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NewAuthDataService} from "../../../../../service/api/newAuth-data.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'guest-modal',
    templateUrl: './guest-modal.component.html',
    styleUrls: ['./guest-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GuestModalComponent implements OnInit {

    constructor(public matDialogRef: MatDialogRef<GuestModalComponent>,
                @Inject(MAT_DIALOG_DATA) private _data: any,
                private _matDialog: MatDialog,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private newAuthDataService: NewAuthDataService,
    )
    {
    }

    ngOnInit(): void {
    }

    openLogin(): boolean
    {
        this.newAuthDataService.logout();
        this._matDialog.closeAll();
        const currentRoute = this.activatedRoute.snapshot?.queryParams?.route ?? this.router.url;
        this.router.navigate(['/auth/login'], {queryParams: {route: currentRoute}}).then(() => {
        });
        return false;
    }
}
