import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { StoreGridViewComponent } from './store-grid-view.component';
import { ProductDetailModule } from '../../../../components/dialogs/product-detail-modal/product-detail.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { SingleProductModule } from '../../../../components/single-product/single-product.module';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { CommonPipeModule } from '../../../../../../common-pipe/common-pipe.module';
import localeDa from '@angular/common/locales/da';

registerLocaleData(localeDa);

@NgModule({
    declarations: [StoreGridViewComponent],
    imports: [
        CommonModule,
        ProductDetailModule,
        FuseSharedModule,
        MatButtonModule,
        TranslateModule,
        SingleProductModule,
        MatIconModule,
        MatRippleModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatTooltipModule,
        MatCardModule,
        MatMenuModule,
        CommonPipeModule,
    ],
    exports: [
        StoreGridViewComponent
    ]
})
export class StoreGridViewModule
{
}
