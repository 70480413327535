import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { RestService } from '../../service/api/rest.service';
import { ChatRoom } from './chat-rooms.model';


@Injectable({providedIn: 'root'})
export class ChatRoomsEntityService extends EntityCollectionServiceBase<ChatRoom>
{
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private rest: RestService)
    {
        super('ChatRooms', serviceElementsFactory);
    }
}
