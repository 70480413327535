<div class="reply" fxLayout="row" fxFlexFill>
    <profile-image *ngIf="!editFlag" class="avatar" [src]="currentUser?.file?.thumb_url"></profile-image>
    <form fxFlex [formGroup]="postCommentForm">
        <app-attachments #attachmentsC [files]="editComment?.files" [allowLargeFiles]="allowLargeFiles"
                         (filesRemoved)="removeAttachment($event)" (lastFile)="fileCount($event)"></app-attachments>
        <ng-container *ngIf="!(editFlag && editCommentFile)">
            <rich-text-field  formControlName="comment" *ngIf="attachmentsC?.attachedCount <= 0"  [data]="'<></>'"  [placeholder]="'post.add_comment' | translate"></rich-text-field>
        </ng-container>

        <div fxLayout="row" fxLayoutAlign="end end">
            <button *ngIf="!editFlag" mat-raised-button color="accent" class="post-comment-button" type="button"
                    aria-label="Post Comment"
                    (click)="commentSubmit()">
                {{'post.post_comment' | translate}}
            </button>
            <button *ngIf="editFlag"
                    [disabled]="editComment.commentType !== 'comment' && attachmentsC?.attachedCount === 0 && editComment?.files.length === 0"
                    mat-raised-button color="accent"
                    class="post-comment-button mt-8" type="button"
                    aria-label="Post Comment"
                    (click)="commentSubmit()">
                {{'post.update_comment' | translate}}
            </button>
            <button mat-icon-button type="button"
                    *ngIf="editFlag && editComment?.files?.length && removedFiles.length === 0"
                    aria-label="Send message" (click)="resetEdit()">
                <mat-icon class="secondary-text">close</mat-icon>
            </button>
            <button mat-icon-button type="button"
                    *ngIf="editCommentFile && currentUser?.email !== 'guest@onlinedoc.dk'"
                    aria-label="Add Attachment" (click)="attachmentsC?.pickFile()">
                <mat-icon>attachment</mat-icon>
            </button>
        </div>
    </form>
</div>
