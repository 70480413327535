import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
    name: 'secure'
})
export class SecurePipe implements PipeTransform
{

    transform(file: File, ...args: any[]): Observable<any>
    {

        return new Observable<any>((observer) => {

            observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
            const reader = new FileReader();
            reader.onload = (e) => {
                observer.next(reader.result);
            };
            reader.readAsDataURL(file);
        });
    }
}
