import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NewAuthDataService } from '../../../../../service/api/newAuth-data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThreadsDataService } from '../../../../../store/thread/threads-data.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import { AttachmentsComponent } from '../../attachments/attachments.component';
import { User } from '../../../../../types';
import {MatDialog} from "@angular/material/dialog";
import {GuestModalComponent} from "../../dialogs/guest-modal/guest-modal.component";

@Component({
    selector   : 'comment-reply',
    templateUrl: './comment-reply.component.html',
    styleUrls  : ['./comment-reply.component.scss']
})
export class CommentReplyComponent implements OnInit
{

    currentUser: User;
    postCommentForm: FormGroup;
    @ViewChild('attachmentsC', {static: false}) attachmentsC: AttachmentsComponent;

    @Input() post;
    @Input() allowLargeFiles: boolean;
    @Input() editComment?;
    @Input() threadEntity: any;
    @Input() updateDone;
    @Output() filesLastCount = new EventEmitter<any>();

    editFlag = false;
    removedFiles: any = [];
    editCommentFile = true;
    lastFileCount: any;

    constructor(
        private authService: NewAuthDataService,
        private _formBuilder: FormBuilder,
        private threadDataService: ThreadsDataService,
        private progressBarService: FuseProgressBarService,
        private toast: ToastrService,
        private _matDialog: MatDialog,
    )
    {

    }

    ngOnInit(): void
    {
        this.authService.getUser().subscribe((data) => {
            this.currentUser = data;
        });
        this.postCommentForm = this._formBuilder.group({
            comment: ['', Validators.required]
        });
        if ( this.editComment )
        {
            this.editFlag = true;
            if ( this.editComment.commentType === 'comment' )
            {
                this.editCommentFile = false;
                this.postCommentForm.patchValue({
                    comment: this.editComment.comment
                });
            } else
            {
                this.editCommentFile = true;
            }
        }
    }

    commentSubmit(): void
    {
        if (this.currentUser.email === 'guest@onlinedoc.dk')
        {
            this._matDialog.open(GuestModalComponent, {
                panelClass: 'guest-modal',
                data      : {
                    name: 'test',
                }
            }).afterClosed().subscribe((res) => {

            });
            return;
        }
        if ( this.postCommentForm.valid )
        {
            this.progressBarService.show();
            const userData = {
                'doc_comment': {
                    clinic_id   : this.post.clinicId,
                    doc_id      : this.post.id,
                    comment     : this.postCommentForm.get('comment')?.value,
                    comment_type: 'comment'
                }
            };
            if ( this.editFlag )
            {
                const updateData = {
                    'doc_comment': {
                        comment: this.postCommentForm.get('comment')?.value
                    }
                };
                this.threadDataService.updateThreadComment(this.editComment.id, updateData).subscribe((data) => {
                        this.updateDone.next('Update');
                        this.toast.success('', 'Comment Updated');
                        this.progressBarService.hide();
                    },
                    (error) => {
                        this.progressBarService.hide();
                        if ( error.errors )
                        {
                            this.toast.error(error.errors.join(', '), 'Error');
                        }
                        if ( error.message )
                        {
                            this.toast.error(error.message, 'Error');

                        }
                    });
            } else
            {
                this.threadDataService.postThreadComment(userData).subscribe((data) => {
                        this.toast.success(data.message, 'Comment Posted');
                        this.progressBarService.hide();
                        this.postCommentForm.patchValue({
                            comment: ''
                        });
                    },
                    (error) => {
                        this.toast.error(error.error, 'Error');
                        this.progressBarService.hide();
                    });
            }
        } else if ( this.attachmentsC?.attachedCount > 0 || this.removedFiles?.length > 0 )
        {
            this.progressBarService.show();
            this.uploadFilesToServer().then((res) => {
            });
        }
    }

    async uploadFilesToServer(): Promise<void>
    {
        this.toast.info('Comment attachments are uploading!');
        let signedIdList: any[] = [];
        if ( this.attachmentsC.attachedCount > 0 )
        {
            signedIdList = await this.attachmentsC.directUpload(this.post.clinicId);
        }
        if ( typeof signedIdList[0] === 'string' && signedIdList[0]?.includes('Error') )
        {
            this.progressBarService.hide();
            this.toast.error('Clinic file uploading limit exceeded', 'Error');
            return;
        }
        const commentData = {
            'doc_comment': {
                clinic_id   : this.post.clinicId,
                doc_id      : this.post.id,
                comment_type: 'file',
                files       : signedIdList.map((t: any) => t.signed_id)
            }
        };
        if ( this.editFlag )
        {
            for (const removedFile of this.removedFiles)
            {
                await this.threadDataService.deleteFile(removedFile.signed_id).toPromise();
                this.post.clinicDocCommentFileCount = this.post.clinicDocCommentFileCount - this.removedFiles.length;
            }
            const files = [...this.editComment.files, ...signedIdList];
            const updateData = {
                'doc_comment': {
                    files: files.map(f => f.signed_id),
                }
            };
            this.post.clinicDocCommentFileCount = this.post.clinicDocCommentFileCount + signedIdList.length;
            this.threadDataService.updateThreadComment(this.editComment.id, updateData).subscribe(async (data) => {
                    this.updateDone.next('Update');
                    this.toast.success('', 'Comment Updated');
                    this.progressBarService.hide();
                },
                (error) => {
                    this.progressBarService.hide();
                    if ( error.errors )
                    {
                        this.toast.error(error.errors.join(', '), 'Error');
                    }
                    if ( error.message )
                    {
                        this.toast.error(error.message, 'Error');
                    }
                });
        } else
        {
            this.threadDataService.postThreadComment(commentData).subscribe((data) => {
                    this.attachmentsC?.reset();
                    this.toast.success(data.message, 'Comment Files Posted');
                    this.progressBarService.hide();
                },
                (error) => {
                    this.toast.error(error.errors.join(', '), 'Error');
                    this.progressBarService.hide();
                    this.attachmentsC?.reset();
                });
        }
    }

    resetEdit(): void
    {
        this.updateDone.next('Update');
    }

    removeAttachment(attachment): any
    {
        this.removedFiles.push(attachment);
    }

    fileCount(fileCountAttachment): void
    {
        this.filesLastCount.emit(this.editComment);
    }
}
