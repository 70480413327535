import { NgModule } from '@angular/core';
import { GalleryComponent } from './gallery.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { GalleryModule as gm } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { FuseSharedModule } from '@fuse/shared.module';
import { UtilModule } from '../util/util.module';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [GalleryComponent],
    exports     : [
        GalleryComponent
    ],
    imports: [
        UtilModule,
        FuseSharedModule,
        MatGridListModule,
        MatButtonModule,
        LightboxModule,
        MatIconModule,
        gm,
        TranslateModule
    ]
})
export class GalleryModule
{
}
