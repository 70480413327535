import { NgModule } from '@angular/core';
import { UploadProgressComponent } from './upload-progress.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [UploadProgressComponent],
    exports: [
        UploadProgressComponent
    ],
    imports: [
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius           : 100,
            outerStrokeWidth : 16,
            innerStrokeWidth : 8,
            animationDuration: 300,
        }),
        CommonModule
    ],
    bootstrap: [UploadProgressComponent]
})
export class  UploadProgressModule
{
}
