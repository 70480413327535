<form novalidate [formGroup]="groupForm">

    <div class="dialog-content-wrapper">
        <mat-toolbar matDialogTitle class="accent m-0" fxFlex="1 0 auto" fxLayout="row">

            <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

                <div fxFlex fxLayout="row" fxLayoutAlign="start center">

                    <!-- Group Status -->
                    <!--                    <div class="due-date" fxFlex="0 1 auto">-->

                    <!--                        <button mat-icon-button [matMenuTriggerFor]="groupStatusOptions">-->
                    <!--                            <mat-icon matTooltip="Group Status" *ngIf="groupForm.get('isPublic').value === true">-->
                    <!--                                remove_red_eye-->
                    <!--                            </mat-icon>-->
                    <!--                            <mat-icon class="mr-8" *ngIf="groupForm.get('isPublic').value === false">visibility_off-->
                    <!--                            </mat-icon>-->
                    <!--                        </button>-->

                    <!--                        <mat-menu #groupStatusOptions="matMenu" [overlapTrigger]="false">-->
                    <!--                            <mat-radio-group aria-label="Select an option" formControlName="isPublic" required>-->
                    <!--                                <mat-radio-button class="p-20" [value]=true>Visible To everyone</mat-radio-button>-->
                    <!--                                <mat-radio-button class="p-20" [value]=false>Private</mat-radio-button>-->
                    <!--                            </mat-radio-group>-->
                    <!--                        </mat-menu>-->

                    <!--                    </div>-->
                    <!-- / Group Status -->

                    <!-- Group Email  -->
                    <!--                    <div class="group-email" fxFlex="0 1 auto">-->

                    <!--                        <button mat-icon-button class="" [matMenuTriggerFor]="groupEmailOptions">-->
                    <!--                            <mat-icon matTooltip="Invite Option">email</mat-icon>-->
                    <!--                        </button>-->

                    <!--                        <mat-menu #groupEmailOptions="matMenu" [overlapTrigger]="false">-->
                    <!--                            <div fxFlex fxLayout="column" class="group-email-menu">-->
                    <!--                                <mat-radio-group aria-label="Select an option" formControlName="invitationSentBy">-->
                    <!--                                    <mat-radio-button class="p-20" value="Admin">Only administrators can send group-->
                    <!--                                        email-->
                    <!--                                    </mat-radio-button>-->
                    <!--                                    <mat-radio-button class="p-20" value="Everyone">All members can send group mail-->
                    <!--                                    </mat-radio-button>-->
                    <!--                                </mat-radio-group>-->
                    <!--                            </div>-->
                    <!--                        </mat-menu>-->
                    <!--                    </div>-->
                    <!-- / Group Email  -->

                    <!-- Group Approve Member  -->
                    <div class="members" fxFlex="0 1 auto">

<!--                        <button mat-icon-button class="" [matMenuTriggerFor]="groupApprovalOptions">-->
<!--                            <mat-icon matTooltip="{{'GROUPS.group_approved_by' |  translate}}">group</mat-icon>-->
<!--                        </button>-->

                        <button mat-icon-button class="" [matMenuTriggerFor]="groupVisibilityOption">
                            <mat-icon matTooltip="{{'GROUPS.group_visibility' | translate}}">remove_red_eye</mat-icon>
                        </button>

                        <mat-menu #groupVisibilityOption="matMenu" [overlapTrigger]="false">
                            <div fxFlex fxLayout="column" class="group-email-menu">
                                <mat-radio-group aria-label="Select an option" formControlName="isPublic" required>
                                    <mat-radio-button matTooltip="{{'GROUPS.visible_to_everyone_tooltip' |  translate}}"
                                                      class="p-20"
                                                      [value]=true>{{'GROUPS.visible_to_everyone' | translate}}
                                    </mat-radio-button>
                                    <mat-radio-button
                                            matTooltip="{{'GROUPS.not_visible_to_everyone_tooltip' |  translate}}"
                                            class="p-20"
                                            [value]=false>{{'GROUPS.not_visible_to_everyone' |  translate}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </mat-menu>

<!--                        <mat-menu #groupApprovalOptions="matMenu" [overlapTrigger]="false">-->
<!--                            <div fxFlex fxLayout="column" class="group-email-menu">-->
<!--                                <mat-radio-group aria-label="Select an option" formControlName="approvedBy" required>-->
<!--                                    <mat-radio-button class="p-20"-->
<!--                                                      value="Admin">{{'GROUPS.only_administrator_can_approve' | translate}}-->
<!--                                    </mat-radio-button>-->
<!--                                    <mat-radio-button class="p-20"-->
<!--                                                      value="Everyone">{{'GROUPS.all_members_can_approve' | translate}}-->
<!--                                    </mat-radio-button>-->
<!--                                </mat-radio-group>-->
<!--                            </div>-->
<!--                        </mat-menu>-->
                    </div>
                    <!-- / Group Approve Member  -->
                </div>
                <!-- Save Button -->
                <button mat-icon-button type="submit"
                        [disabled]="loadingProgress"
                        aria-label="Save Group" (click)="saveGroup()">
                    <mat-icon matTooltip="{{'THREAD.save' | translate}}">save</mat-icon>
                </button>
                <!-- Save Button -->

                <!-- CLOSE DIALOG BUTTON -->
                <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close Dialog">
                    <mat-icon matTooltip="{{'THREAD.close' | translate}}">close</mat-icon>
                </button>
                <!-- / CLOSE DIALOG BUTTON -->

            </div>

        </mat-toolbar>
        <div class="loading-div" *ngIf="loadingProgress">
            <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
        </div>
        <div mat-dialog-content class="py-24 px-0 m-0">

            <div fxLayout="row" fxLayout.xs="column">
                <div fxFlex="80" class="border-right">
                    <!-- BREADCRUMB -->
                    <div class="card-breadcrumb mt-8 mb-32 px-24" fxLayout="row" fxLayoutAlign="start center">
                        <span>{{'CONTACT.groups' | translate}}</span>
                        <mat-icon class="s-20">chevron_right</mat-icon>
                        <span>{{dialogTitle}}</span>
                    </div>
                    <!-- / BREADCRUMB -->

                    <div class="sections">
                        <div class="section">

                            <div class="section-content select-div-parent"
                                 *ngIf="!groupForm.get('attachment')?.value"
                                 fxLayoutAlign="center center"
                                 fxLayout="row">
                                <div class="select-div" matRipple (click)="fileInput.click()">
                                    <mat-icon class="s-128">add_photo_alternate</mat-icon>
                                    <input hidden #fileInput
                                           type="file"
                                           (change)="onFileChange($event)" accept="image/*"/>

                                </div>
                            </div>
                            <div *ngIf="groupForm.get('attachment')?.value"
                                 fxLayoutAlign="center center"
                                 fxLayout="row">
                                <div fxLayoutAlign="center start"
                                     fxLayout="row"
                                     class="profile-image-div">
                                    <img [src]="groupForm.get('attachment')?.value.src" alt="">
                                    <button mat-icon-button (click)="removeAttachment()">
                                        <mat-icon class="s-30">close</mat-icon>
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div class="section m-0">
                            <div fxLayout="column">

                                <!-- NAME -->
                                <div class="card-name" fxLayout="row" fxLayoutAlign="start center">
                                    <mat-form-field appearance="outline" class="w-100-p">
                                        <mat-label>{{'CONTACT.group' | translate}} {{'CONTACT.name' | translate}}:
                                        </mat-label>
                                        <input matInput formControlName="name" required>
                                        <mat-error *ngIf="groupForm.get('name')?.hasError('required')" translate>
                                            {{'CONTACT.group' | translate}} {{'CONTACT.name' | translate}} {{'SLIDE.is_required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- / NAME -->

                                <!-- DESCRIPTION -->
                                <div class="description mb-16">
                                    <mat-form-field appearance="outline" class="w-100-p">
                                        <mat-label>{{'THREAD.description' | translate}}</mat-label>
                                        <textarea rows="3" cdkTextareaAutosize matInput formControlName="description" columns="1"
                                                  mat-maxlength="150" max-rows="4"></textarea>
                                    </mat-form-field>
                                </div>
                                <!-- / DESCRIPTION -->
                            </div>
                        </div>
                        <div class="section">
                            <app-categories-list> #categoriesList</app-categories-list>
                        </div>
                    </div>

                </div>
                <div fxFlex="noshrink">
                    <div class="sections">
                        <!-- Group Approved By SECTION-->
<!--                        <div class="section" fxLayout="column" fxLayout.gt-xs="row">-->

<!--                            <div fxFlex class="labels">-->

<!--                                <div class="section-header section-header-point-cursor" fxLayout="row"-->
<!--                                     fxLayoutAlign="start center" [matMenuTriggerFor]="groupApprovalOptions">-->
<!--                                    <mat-icon class="mr-8">group</mat-icon>-->
<!--                                    <span class="section-title">{{'GROUPS.group_approved_by' |  translate}}</span>-->
<!--                                </div>-->

<!--                                <div class="section-content">-->
<!--                                    <mat-chip-list class="label-chips">-->
<!--                                        <mat-chip class="label-chip mb-4"-->
<!--                                                  fxLayout="row" fxLayoutAlign="start center"-->
<!--                                                  [ngClass]="{'active accent': groupForm.get('approvedBy')?.value === 'Admin'}">-->
<!--                                            <span class="section-header-point-cursor"-->
<!--                                                  (click)="groupApproveStatus('Admin')">{{'GROUPS.only_administrator_can_approve' | translate}}</span>-->
<!--                                        </mat-chip>-->
<!--                                        <mat-chip class="label-chip mb-4"-->
<!--                                                  fxLayout="row" fxLayoutAlign="start center"-->
<!--                                                  [ngClass]="{'active accent': groupForm.get('approvedBy')?.value === 'Everyone'}">-->
<!--                                            <span class="section-header-point-cursor"-->
<!--                                                  (click)="groupApproveStatus('Everyone')">{{'GROUPS.all_members_can_approve' | translate}}</span>-->
<!--                                        </mat-chip>-->
<!--                                    </mat-chip-list>-->
<!--                                </div>-->

<!--                            </div>-->
<!--                        </div>-->
                        <!-- Group Approved By SECTION -->

                        <div class="section" fxLayout="column" fxLayout.gt-xs="row">

                            <div fxFlex class="labels">

                                <div class="section-header section-header-point-cursor" fxLayout="row"
                                     fxLayoutAlign="start center" [matMenuTriggerFor]="groupVisibilityOption">
                                    <mat-icon class="mr-8">remove_red_eye</mat-icon>
                                    <span class="section-title">{{'GROUPS.group_visibility' | translate}}</span>
                                </div>

                                <div class="section-content">
                                    <mat-chip-list class="label-chips">
                                        <mat-chip class="label-chip mb-4"
                                                  matTooltip="{{'GROUPS.visible_to_everyone_tooltip' |  translate}}"
                                                  fxLayout="row" fxLayoutAlign="start center"
                                                  [ngClass]="{'active accent': groupForm.get('isPublic')?.value === true}">
                                            <span class="section-header-point-cursor"
                                                  (click)="groupVisibilityStatus(true)">{{'GROUPS.visible_to_everyone' | translate}}</span>
                                        </mat-chip>
                                        <mat-chip class="label-chip mb-4"
                                                  matTooltip="{{'GROUPS.not_visible_to_everyone_tooltip' |  translate}}"
                                                  fxLayout="row" fxLayoutAlign="start center"
                                                  [ngClass]="{'active accent': groupForm.get('isPublic')?.value === false}">
                                            <span class="section-header-point-cursor"
                                                  (click)="groupVisibilityStatus(false)">{{'GROUPS.not_visible_to_everyone' |  translate}}</span>
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
            <button [disabled]="loadingProgress" mat-raised-button color="accent" class="post-button" (click)="saveGroup()"
                    aria-label="group-post">{{'TIMELINE.post' | translate}}</button>
        </div>

    </div>
</form>

