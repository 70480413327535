import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-web-storage';
import { RestService } from '../../service/api/rest.service';
import { Task, TaskAdapter, TaskCommentAdapter, TaskGroupUsersAdapter } from './task.model';
import { map, tap } from 'rxjs/operators';
import { GroupUser } from '../group/group.model';
import { Subject } from 'rxjs';

@Injectable()
export class TaskDataService extends DefaultDataService<Task>
{
    private _baseDocs = 'docs';
    private _baseClinic = 'clinics';
    private _baseTaskDocs = 'task_docs';
    calendarViewMode = new Subject();
    private _baseTaskComments = 'doc_comments';

    constructor(http: HttpClient,
                httpUrlGenerator: HttpUrlGenerator,
                private rest: RestService,
                private local: LocalStorageService,
                private taskAdapter: TaskAdapter,
                private taskGroupUsersAdapter: TaskGroupUsersAdapter,
                private taskCommentAdapter: TaskCommentAdapter)
    {
        super('Task', http, httpUrlGenerator);
    }


    getAllTasks(params): any
    {
        return this.rest.post(`${(this._baseClinic)}/index_task`, params).pipe(map(data => {
            const tasks: Task[] = [];

            for (const clinicDoc of  data.clinic_docs)
            {
                const temp = this.taskAdapter.adapt(clinicDoc);
                tasks.push({...temp, id: temp.id});
            }
            return tasks;
        }));
    }

    getById(clinicId): any
    {
        return this.rest.get(`${(this._baseClinic)}/${(clinicId)}/${(this._baseDocs)}/${(this._baseTaskDocs)}`).pipe(map(data => {
            const tasks: Task[] = [];

            for (const clinicDoc of data.clinic_docs)
            {
                const temp = this.taskAdapter.adapt(clinicDoc);
                tasks.push({...temp, id: temp.id});
            }
            return tasks;
        }));
    }

    getUserClinicsWithCategories(): any
    {
        return this.rest.get(`${(this._baseClinic)}/user_clinics_with_categories`).pipe(tap(data => {
            return data;
        }));
    }

    createTask(params): any
    {
        if ( params.doc.title === '' )
        {
            params.doc.title = params.doc.description;
        }
        return this.rest.post(`${(this._baseDocs)}/create_task`, params).pipe(map(data => {
            return this.taskAdapter.adapt(data);
        }));
    }

    updateTask(params, taskID): any
    {
        return this.rest.put(`${(this._baseDocs)}/update_task/${(taskID)}`, params).pipe(map(data => {
            return this.taskAdapter.adapt(data);
        }));
    }

    deleteTask(taskID): any
    {
        return this.rest.delete(`${(this._baseDocs)}/${(taskID)}`).pipe(tap(data => {
            return data;
        }));
    }

    getGroupUsers(clinicId): any
    {
        return this.rest.get(`${(this._baseClinic)}/${clinicId}/clinic_users`).pipe(map(data => {
            const groupUser: GroupUser[] = [];
            for (const clinicAdmin of data.clinic_admins)
            {
                groupUser.push({...clinicAdmin, id: clinicAdmin.id});
            }
            for (const joinedUser of  data.joined_users)
            {
                groupUser.push({...joinedUser, id: joinedUser.id});
            }
            for (const requestedUser of data.requested_users)
            {
                groupUser.push({...requestedUser, id: requestedUser.id});
            }
            const tempData: any = [];
            for (const user of  groupUser)
            {
                tempData.push(this.taskGroupUsersAdapter.adapt(user));
            }
            return tempData;
        }));
    }

    createTaskComment(params): any
    {
        return this.rest.post(`${(this._baseTaskComments)}`, params).pipe(map(data => {
            return this.taskCommentAdapter.adapt(data.doc_comment);
        }));
    }
    updateTaskComment(id, param): any
    {
        return this.rest.put(`${(this._baseTaskComments)}/${(id)}`, param).pipe(map(data => {
            return this.taskCommentAdapter.adapt(data.doc_comment);
        }));
    }
    deleteTaskComment(id): any
    {
        return this.rest.delete(`${(this._baseTaskComments)}/${(id)}`).pipe(tap(data => {
            return data;
        }));
    }

    getManagementTasks(params): any
    {
        return this.rest.post(`${(this._baseDocs)}/task_docs`, params).pipe(map(data => {
            const tasks: Task[] = [];
            for (const clinicDoc of data.clinic_docs)
            {
                const temp = this.taskAdapter.adapt(clinicDoc);
                tasks.push({...temp, id: temp.id});
            }
            return tasks;
        }));
    }
    getTaskByID(id): any
    {
        return this.rest.get(`${(this._baseDocs)}/${(id)}`).pipe(map(data => {
            return  this.taskAdapter.adapt(data);

        }));
    }
}
