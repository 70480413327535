import { Injectable } from '@angular/core';

export interface Notification
{
    userNotificationCount: number;
    id: number;
    clinicId: number;
    title: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    clinicName: string;
    file: any;
    activityType: any;
    docType: any;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationsAdapter
{
    public adapt(item: any): Notification
    {
        return {
            activityType         : item.activity_type,
            clinicName           : item.doc.clinic_name,
            file                 : item.doc.file,
            id                   : item.doc.id,
            clinicId             : item.doc.clinic_id,
            createdAt            : item.doc.created_at,
            description          : item.doc.description,
            title                : item.doc.title,
            updatedAt            : item.doc.updated_at,
            userNotificationCount: item.user_notification_count,
            docType              : item.doc.doc_type
        };
    }

    encode(item: Notification): any
    {
        return {};
    }
}
