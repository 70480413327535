import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { RestService } from '../../service/api/rest.service';
import { Thread } from './thread.model';

@Injectable({providedIn: 'root'})
export class ThreadsEntityService extends EntityCollectionServiceBase<Thread>
{
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private rest: RestService)
    {
        super('Thread', serviceElementsFactory);
    }

}
