import {EntityDataModuleConfig, EntityMetadataMap} from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
    User          : {},
    Group         : {},
    Thread        : {},
    GroupUser     : {},
    Task          : {},
    Friend        : {},
    Notification  : {},
    SystemUsers   : {},
    ChatRooms     : {},
    UploadingFiles: {},
    DocRead       : {},
    Cart          : {},
};
const pluralNames = {User: 'Users'};

export const entityConfig: EntityDataModuleConfig = {
    entityMetadata,
    pluralNames
};
