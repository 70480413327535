import { NgModule } from '@angular/core';
import { AttachmentsComponent } from './attachments.component';
import { FuseSharedModule } from '../../../../../@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { SecurePipe } from './secure.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
    declarations: [AttachmentsComponent, SecurePipe],
    exports     : [
        AttachmentsComponent
    ],
    imports: [
        FuseSharedModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
    ]
})
export class AttachmentsModule
{
}
