<div fxLayout="row" fxLayout.lt-sm="column" class="product-container">
    <div fxLayout="row">
        <swiper
                *ngIf="product?.files?.length !== 0"
                style="--swiper-navigation-color: #fff;--swiper-pagination-color: #fff"
                [loop]="true"
                [spaceBetween]="10"
                [navigation]="false"
                [thumbs]="{ swiper: thumbsSwiper }"
                class="mySwiper2"
        >
            <ng-template swiperSlide *ngFor="let file of  this.product?.files; let i = index"
            ><img [src]="file?.src || 'assets/images/backgrounds/loading.gif'" (click)="openInFullScreen(i)" *ngIf="!file?.content_type.includes('video')"/>
                <video *ngIf="file?.content_type.includes('video')" [src]="file?.src || 'assets/images/backgrounds/loading.gif'" controls></video>
            </ng-template
            >
        </swiper>
        <img *ngIf="product?.files?.length === 0" mat-card-image class="card-image" src="assets/images/place-holder/no-Image.png"
             alt="Place holder image">
        <mat-divider vertical class="divider"></mat-divider>
        <div class="mySwiperContainer">
            <swiper *ngIf="product?.files?.length !== 0"
                    (swiper)="thumbsSwiper = $event"
                    [loop]="false"
                    [spaceBetween]="2"
                    [slidesPerView]="9"
                    [freeMode]="false"
                    [watchSlidesProgress]="true"
                    [direction]="swiperDirection"
                    #swiperSlide
                    class="mySwiper"
            >

                <ng-template swiperSlide *ngFor="let file of  this.product?.files"
                >
                    <div class="thumbnail-conatiner">
                    <img [src]="file?.src || 'assets/images/backgrounds/loading.gif'" *ngIf="!file?.content_type.includes('video')" />
                    <video *ngIf="file?.content_type.includes('video')" [src]="file?.src" ></video>
                    </div>
                </ng-template>


            </swiper>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between space-between" fxFlex="1 1 100%"
         [ngClass]="{'ml-28': product?.files?.length === 0 }">
        <div>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <p class="breadcrumbs">{{product?.clinicName}} > {{product?.categoryName}}</p>
                <div fxLayout="row">
                    <ng-containor *ngIf="currentUser?.email !== 'guest@onlinedoc.dk'">
                    <mat-icon *ngIf=" !isModal && !product?.docNotification"
                              matTooltip="{{'post.thread_notification_off' | translate}}"
                              (click)="this.turnThreadNotificationOn()"
                              >notifications_off
                    </mat-icon>
                    <mat-icon *ngIf=" !isModal && product?.docNotification" class="mx-8"
                              matTooltip="{{'post.thread_notification_on' | translate}}"

                              (click)="this.turnThreadNotificationOff()"> notifications_active
                    </mat-icon>
                    </ng-containor>
                    <mat-icon *ngIf="!isModal && product?.productEditable" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="editProduct()" >
                            <mat-icon>edit</mat-icon>
                            <span>{{'DASHBOARD_TASK.edit' | translate}}</span>
                        </button>
                        <button mat-menu-item (click)="deleteProduct()" >
                            <mat-icon>delete</mat-icon>
                            <span>{{'DASHBOARD_TASK.delete' | translate}}</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="product-title">
                <h1 [innerHTML]="product?.title"></h1>
            </div>
            <div class="product-desc">
                <p [innerHTML]="product?.description"></p>
                <p class="my-8">{{product?.price | currency:'DKK':'symbol':'1.1-1':'da'}}</p>
            </div>
            <div fxLayout="row wrap" *ngIf="variations.length >0">
                <button mat-stroked-button class="my-8 mx-4" (click)="selectVariation(variation)"
                        [ngClass]="{'selected-variation': variation?.selected === true}"
                        *ngFor="let variation of variations">{{variation?.variation}}</button>
            </div>
        </div>

        <div class="product-button ">
            <button class=" cursor-pointer" (click)="addToBasket()"> {{'PRODUCT.add_to_basket'| translate}}</button>
        </div>

    </div>
</div>
