import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'prefixConversion'
})
export class PrefixConversionPipe implements PipeTransform
{
    convertedPrefix: string;

    transform(num: number): string
    {
        this.convertedPrefix = num < 1024 ? num + ' Bytes'
            : num > 1024 && num < 1000000
                ? (num / 1000).toFixed(1) + ' Kbs'
                : (num / 1000000).toFixed(2) + ' Mbs';
        return this.convertedPrefix;
    }
}
