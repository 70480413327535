<mat-toolbar class="p-0 mat-elevation-z1" xmlns="http://www.w3.org/1999/html">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/doc_logo.png">
                </div>
            </div>

<!--            <div *ngIf="showInviteGroup" class="px-8 px-md-16">-->
<!--                <button (click)="openInviteModal()" mat-icon-button class="mr-8"-->
<!--                        matTooltip="{{'TOOLBAR.invite_to_group' | translate}}">-->
<!--                    <mat-icon class="secondary-text" matRipple>person_add</mat-icon>-->
<!--                </button>-->
<!--            </div>-->

        </div>

<!--        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">-->

<!--            <div class="toolbar-separator"></div>-->

<!--            <button mat-button [matMenuTriggerFor]="userMenu"-->
<!--                    class="user-button">-->
<!--                <div fxLayout="row" fxLayoutAlign="center center">-->
<!--                    <img class="avatar mr-0 mr-sm-16" [src]=imageSrc>-->
<!--                    <span class="username mr-12" fxHide fxShow.gt-sm>{{name}}</span>-->
<!--                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>-->
<!--                </div>-->
<!--            </button>-->

<!--            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">-->
<!--                <button mat-menu-item (click)="openProfile()">-->
<!--                    <mat-icon>account_circle</mat-icon>-->
<!--                    <span>{{'TOOLBAR.my_profile' | translate}}</span>-->
<!--                </button>-->


<!--                <button mat-menu-item class="" (click)="logout()" routerLink="/auth/login">-->
<!--                    <mat-icon>exit_to_app</mat-icon>-->
<!--                    <span>{{'TOOLBAR.logout' | translate}}</span>-->
<!--                </button>-->

<!--            </mat-menu>-->

<!--            <div class="toolbar-separator"></div>-->

<!--            <fuse-search-bar (keyup.enter)="search($event)"></fuse-search-bar>-->

<!--            <div class="toolbar-separator"></div>-->

<!--            <button mat-button fxHide fxShow.gt-xs-->
<!--                    class="language-button"-->
<!--                    [matMenuTriggerFor]="languageMenu">-->
<!--                <div fxLayout="row" fxLayoutAlign="center center">-->
<!--                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">-->
<!--                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>-->
<!--                </div>-->
<!--            </button>-->

<!--            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">-->

<!--                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">-->
<!--                    <span fxLayout="row" fxLayoutAlign="start center">-->
<!--                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">-->
<!--                        <span class="iso">{{lang.title}}</span>-->
<!--                    </span>-->
<!--                </button>-->

<!--            </mat-menu>-->

<!--            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>-->

<!--            <button mat-icon-button class="navbar-toggle-button"-->
<!--                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>-->
<!--                <mat-icon class="secondary-text">menu</mat-icon>-->
<!--            </button>-->

<!--        </div>-->

    </div>

</mat-toolbar>
