import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NewAuthDataService } from '../../../../service/api/newAuth-data.service';
import { ThreadsEntityService } from '../../../../store/thread/threads-entity.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import { ThreadsDataService } from '../../../../store/thread/threads-data.service';
import { ThreadDialogComponent } from '../dialogs/thread/thread.component';
import { MatDialog } from '@angular/material/dialog';
import { FooterType, User } from 'app/types';
import { TaskComponent } from '../dialogs/task/task.component';
import { TaskDataService } from '../../../../store/task/task-data.service';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dailog/confirmation-dialog.component';
import { DocReadEntityService } from '../../../../store/doc-read/doc-read-entity.service';
import { IDocRead, ReadType } from '../../../../store/doc-read/doc-read.model';
import { ShareThreadDialogComponent } from '../dialogs/share-thread-dialog/share-thread-dialog.component';
import { Thread } from '../../../../store/thread/thread.model';
import { GroupsDataService } from '../../../../store/group/groups-data.service';

@Component({
    selector     : 'post',
    templateUrl  : './post.component.html',
    styleUrls    : ['./post.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PostComponent implements OnInit, OnDestroy, AfterViewInit
{

    FooterType = FooterType;
    @Input() post: Thread | any;
    currentUser: User;
    threadAuthor: boolean;
    showFooterType: FooterType = FooterType.Comment;
    postForm = new FormGroup({
        notifications: new FormControl(''),
    });
    newPost: any;
    readType: typeof ReadType = ReadType;
    sharedThreadId: number;

    constructor(
        private authService: NewAuthDataService,
        private threadEntity: ThreadsEntityService,
        private progressBarService: FuseProgressBarService,
        private toast: ToastrService,
        private threadDataService: ThreadsDataService,
        private _matDialog: MatDialog,
        private taskDataService: TaskDataService,
        private groupDataService: GroupsDataService,
        private docReadEntityService: DocReadEntityService,
        private cdRef: ChangeDetectorRef
    )
    {

    }

    ngOnInit(): void
    {
        if ( 'docReads' in this.post )
        {
            const docReadList: IDocRead[] = [];
            for (const item of this.post.docReads)
            {
                const tempRead: IDocRead = {
                    id    : item.id,
                    itemId: this.post.id,
                    user  : item.user,
                    type  : ReadType.doc
                };
                docReadList.push(tempRead);
            }
            this.docReadEntityService.addManyToCache(docReadList);
        }
        this.authService.getUser().subscribe((data) => {
            this.currentUser = data;
        });
        this.threadAuthor = this.currentUser.id === this.post.docCreator?.id;

        this.groupDataService.getShareThreadId().subscribe(data => {
            this.sharedThreadId = data?.threadId;

        });

    }

    /**
     * On AfterViewInit
     */
    ngAfterViewInit(): void {
        this.cdRef.detectChanges();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {

    }


    notificationsStatus(status: any): void
    {
        this.postForm.patchValue({
            notifications: status
        });
    }

    setFooterType(type: FooterType): void
    {
        this.showFooterType = type;
    }

    turnThreadNotificationOn(): void
    {
        this.progressBarService.show();
        const clinicData = {
            'doc_notification': {
                clinic_id   : this.post.clinicId,
                doc_id      : this.post.id,
                notification: true
            }
        };
        this.threadDataService.threadNotificationToggle(clinicData).subscribe((data) => {
            this.toast.success('', data.message);
            this.progressBarService.hide();
            this.post.docNotification = true;
            this.threadEntity.updateOneInCache({docNotification: true, id: this.post.id});
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.error, 'Error');
        });
    }

    turnThreadNotificationOff(): void
    {
        this.progressBarService.show();
        const clinicData = {
            'doc_notification': {
                clinic_id   : this.post.clinicId,
                doc_id      : this.post.id,
                notification: false
            }
        };
        this.threadDataService.threadNotificationToggle(clinicData).subscribe((data) => {
            this.toast.success('', data.message);
            this.progressBarService.hide();
            this.post.docNotification = false;
            this.threadEntity.updateOneInCache({docNotification: false, id: this.post.id});
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.error, 'Error');
        });
    }

    editThread(): void
    {
        this._matDialog.open(ThreadDialogComponent, {
            panelClass: 'card-dialog',
            data      : {
                new       : false,
                threadData: this.post,
            }
        });
    }

    shareThread(): void
    {
        this._matDialog.open(ShareThreadDialogComponent, {
            panelClass: 'thread-share',
            data: {
                post: this.post
            }
        });
    }
    deleteThread(): void
    {
        this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: 'confirmation-dialog',
            data      : {
                title  : 'Delete Thread',
                message: 'Are you sure you want to delete this thread?'
            }
        }).afterClosed().subscribe((res) => {
            if ( res && res[0] === 'yes' )
            {
                this.progressBarService.show();
                this.threadDataService.deleteThread(this.post.id).subscribe((data) => {
                    this.toast.success('', data.message);
                    this.threadEntity.delete(this.post.id);
                    this.progressBarService.hide();
                }, (error) => {
                    this.progressBarService.hide();
                    this.toast.error(error.error, 'Error');
                });
            }
        });
    }

    editTask(task): void
    {
        this.taskDataService.getTaskByID(task.id).subscribe((data) => {
            this._matDialog.open(TaskComponent, {
                panelClass: 'task-card-dialog',
                data      : {
                    new     : false,
                    taskData: data
                }
            });
        });
    }

    isFilesUploading(data): void
    {
        if ( 'isFileBeingUploaded' in this.post )
        {
            this.post.isFileBeingUploaded = data;
        }
    }

    onAppear(): void
    {
        this.threadDataService.viewedDocIds.push(this.post.id);
        this.threadDataService.docReadsRequests.next({doc_id: this.post.id});
    }
}
