export interface ChatRoom
{
    id: number;
    chatRoomId: number;
    userId: number;
    chatRoomActive: boolean;
    messageCount: number;
    createdAt: string;
    lastMessage: string;
    user: any;
    messages: any;
    lastMessageDate: Date;
}

export class ChatRoomAdapter
{
    public static adapt(item: any): ChatRoom
    {
        return {
            chatRoomActive : item.chat_room.chat_room_active,
            chatRoomId     : item.chat_room.chat_room_id,
            createdAt      : item.chat_room.created_at,
            id             : item.chat_room.chat_room_id,
            userId         : item.chat_room.user_id,
            messageCount   : item.chat_room.message_count,
            lastMessage    : item.last_message,
            lastMessageDate: item.last_message_created_at,
            user           : item.user,
            messages       : []
        };
    }
}

