import { NgModule } from '@angular/core';

import { FuseCountdownComponent } from '@fuse/components/countdown/countdown.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        FuseCountdownComponent
    ],
    exports: [
        FuseCountdownComponent
    ],
    imports: [
        TranslateModule
    ]
})
export class FuseCountdownModule
{
}
