import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NewAuthDataService } from '../../../../../service/api/newAuth-data.service';
import { LocalStorageService } from 'angular-web-storage';

@Component({
    selector   : 'app-threadconfirmation',
    templateUrl: './threadconfirmation.component.html',
    styleUrls  : ['./threadconfirmation.component.scss']
})
export class ThreadconfirmationComponent implements OnInit, OnDestroy
{
    category: any;
    group: any;
    confirmation = true;
    counter = 0;
    card: any;
    list: any;

    userData: any;
    threadData: any;
    newThread: any;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private toast: ToastrService,
        public matDialogRef: MatDialogRef<ThreadconfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _matDialog: MatDialog,
        private _authService: NewAuthDataService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private _router: Router,
        private local: LocalStorageService
    )
    {
        this._unsubscribeAll = new Subject<any>();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On initz
     */
    ngOnInit(): void
    {
        this.category = this._data.category.name;
        this.group = this._data.group.name;
        this.confirmation = this._data.confirmation;
        this.newThread = this._data.newThread;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    saveThread(): void
    {
        this.matDialogRef.close('Thread Created');
    }


    changeGroup(): void
    {
        this.matDialogRef.close('Change Group');
    }

    confirmationChange(event): void
    {
        this.confirmation = !this.confirmation;

        this.local.set('displayThreadConfirmation', {'status': this.confirmation});
    }
}


