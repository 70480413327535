import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { ActionCableService } from '../service/socket/action-cable.service';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[appStatusUpdate]'
})
export class StatusUpdateDirective implements OnInit, OnDestroy
{
    @Input()
    status;
    private statusUpdate: Subscription;

    constructor(private readonly actionCableService: ActionCableService)
    {

    }

    ngOnInit(): void
    {
        this.statusUpdate = this.actionCableService.getStatusUpdate().subscribe((res) => {

        });
    }

    ngOnDestroy(): void
    {
        this.statusUpdate.unsubscribe();
    }


}
