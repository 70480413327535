import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-web-storage';
import { RestService } from '../../service/api/rest.service';
import { ChatRoom, ChatRoomAdapter } from './chat-rooms.model';
import { map } from 'rxjs/operators';
import { ChatRoomsEntityService } from './chat-room-entity.service';
import { Observable, Subject } from 'rxjs';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Injectable()
export class ChatRoomsDataService extends DefaultDataService<ChatRoom>
{
    private baseChatRooms = 'chat_rooms';
    private readChatFromMessages = new Subject<any>();

    constructor(http: HttpClient,
                httpUrlGenerator: HttpUrlGenerator,
                private rest: RestService,
                private chatRoomsEntityService: ChatRoomsEntityService,
                private _fuseNavigationService: FuseNavigationService)
    {
        super('ChatRooms', http, httpUrlGenerator);
    }

    getReadChatFromMessagesSubject(): Subject<any>
    {
        return this.readChatFromMessages;
    }

    getReadChatFromMessages(): Observable<any>
    {
        return this.readChatFromMessages.asObservable();
    }

    getChatRooms(params): any
    {
        params.limit = 15;
        return this.rest.get(`${(this.baseChatRooms)}`, params).pipe(map(data => {
            const tempChatRooms: ChatRoom[] = [];
            for (const index in data.chat_rooms)
            {
                const temp = ChatRoomAdapter.adapt(data.chat_rooms[index]);
                tempChatRooms.push(temp);
            }
            this.chatRoomsEntityService.addManyToCache(tempChatRooms);
            return data;
        }));
    }

    getById(key: number | string): Observable<ChatRoom>
    {
        return this.rest.get(`${(this.baseChatRooms)}/show/${key}`).pipe(map(data => {
            return ChatRoomAdapter.adapt(data);
        }));
    }

    getChatRoomNotificationCount(): any
    {
        return this.rest.get(`${(this.baseChatRooms)}/total_chat_room_count`).subscribe((data) => {
            this.updateMessageCountAction(data);
        });
    }

    updateMessageCountAction(data: any): any
    {
        if ( data.all_message_count === 0 )
        {
            const item =
                {
                    badge: null
                };
            this._fuseNavigationService.updateNavigationItem('messages', item);
        } else
        {
            setTimeout(() => {
                const item =
                    {
                        badge: null
                    };
                this._fuseNavigationService.updateNavigationItem('messages', item);
            }, 200);
            setTimeout(() => {
                const item =
                    {
                        badge: {
                            title: data.all_message_count,
                            bg   : '#F44336',
                            fg   : '#FFFFFF'
                        }
                    };
                this._fuseNavigationService.updateNavigationItem('messages', item);
            }, 200);
        }
    }

    getSearchedChatRooms(query): any
    {
        return this.rest.get(`${(this.baseChatRooms)}?search=${(query)}`).pipe(map((data) => {
            const tempChatRooms: ChatRoom[] = [];
            for (const index in data.chat_rooms)
            {
                const temp = ChatRoomAdapter.adapt(data.chat_rooms[index]);
                tempChatRooms.push(temp);
            }
            this.chatRoomsEntityService.addManyToCache(tempChatRooms);
            return data;
        }));
    }
}
