import { NgModule } from '@angular/core';
import { ImageComponent } from './image/image.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ProfileImageComponent } from './profile-image/profile-image.component';


@NgModule({
    declarations: [ImageComponent, ProfileImageComponent],
    exports: [
        ImageComponent,
        ProfileImageComponent
    ],
    imports     : [
        LazyLoadImageModule
    ]
})
export class UtilModule
{
}
