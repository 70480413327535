import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import 'quill-emoji/dist/quill-emoji.js';

@Component({
    selector   : 'rich-text-field',
    templateUrl: './rich-text-field.component.html',
    styleUrls  : ['./rich-text-field.component.scss'],
    providers  : [
        {
            provide    : NG_VALUE_ACCESSOR,
            multi      : true,
            useExisting: RichTextFieldComponent
        }
    ]
})
export class RichTextFieldComponent implements OnInit, ControlValueAccessor
{

    @Input() placeholder: string;
    @Input() data: string;
    @Input() limit?: number;
    @Output() inputData = new EventEmitter<string>();

    @ViewChild('myInput', {read: ElementRef}) myInput: ElementRef<HTMLInputElement>;
    modules = {};
    content = '';
    public model = {
        editorData: '<p></p>'
    };
    touched = false;
    disabled = false;
    onTouched = () => {
    }
    onChange = (quantity) => {
    }

    constructor()
    {
    }

    ngOnInit(): void
    {
        this.model.editorData = this.data;
        if (!this.limit)
        {
            this.modules = {
                'emoji-shortname': true,
                'emoji-textarea' : true,
                'emoji-toolbar'  : true,
                'syntax'         : false,
                'toolbar'        : [
                    ['bold', 'italic'],
                ],
                keyboard         : {
                    bindings: {
                        tab: {
                            key: 9,
                            handler: (range, context) => {
                                return true;
                            },
                        },
                        enter: {
                            key    : 13,
                            handler: (range, context) => {
                                return true;
                            }
                        }
                    }
                }
            };
        }
        else{
            this.modules = {
                'emoji-shortname': true,
                'emoji-textarea' : true,
                'emoji-toolbar'  : true,
                'syntax'         : false,
                'toolbar'        : [
                    ['bold', 'italic'],
                ],
                clipboard        : {
                    newLines: false
                },
                keyboard         : {
                    bindings: {
                        tab: {
                            key: 9,
                            handler: (range, context) => {
                                return true;
                            },
                        },
                        enter: {
                            key    : 13,
                            handler: (range, context) => {
                                return false;
                            }
                        }
                    }
                }
            };
        }
    }

    changeEvent({editor}: any): any
    {
    }
    editorInit(quill: any): any
    {

        quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            delta.ops.forEach(e => {
                if ( e.attributes )
                {
                    e.attributes.color = '';
                    e.attributes.background = '';
                    e.attributes['code-block'] = false;
                    e.attributes.link = '';
                }
            });
            return delta;
        });

        quill.on('text-change', (delta, old, source) => {
            if ( this.limit && quill.getLength() > this.limit )
            {
                quill.deleteText(this.limit, quill.getLength());
            }
        });

    }


    onContentChanged(event): any
    {
        this.markAsTouched();
        const data = this.model.editorData;
        this.inputData.emit(data);
        this.onChange(data);
    }

    registerOnChange(onChange: any): void
    {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any): void
    {
        this.onTouched = onTouched;
    }

    writeValue(input: string): void
    {
        this.model.editorData = input;
    }

    markAsTouched(): void
    {
        if ( !this.touched )
        {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean): void
    {
        this.disabled = disabled;
    }
}
