<form novalidate [formGroup]="productForm" xmlns="http://www.w3.org/1999/html">
    <div class="dialog-content-wrapper">

        <mat-toolbar matDialogTitle class="accent m-0" fxFlex="1 0 auto" fxLayout="row">

            <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                <!-- Save Button -->
                <button [disabled]="saveButton" mat-icon-button type="submit" aria-label="Save Group"
                        (click)="saveProduct()">
                    <mat-icon *ngIf="newThread" matTooltip="{{'THREAD.save' | translate}}">save</mat-icon>
                    <mat-icon *ngIf="!newThread" matTooltip="{{'THREAD.update' | translate}}">save</mat-icon>
                </button>
                <!-- Save Button -->

                <!-- CLOSE DIALOG BUTTON -->
                <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close Dialog">
                    <mat-icon matTooltip="{{'THREAD.close' | translate}}">close</mat-icon>
                </button>

                <!-- / CLOSE DIALOG BUTTON -->

            </div>

        </mat-toolbar>
        <div class="loading-div" *ngIf="loadingProgress">
            <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
        </div>
        <div mat-dialog-content class="py-24 px-0 m-0">
            <div fxLayout="row" fxLayout.lt-xl="column" fxLayoutAlign.lt-xl="center center">
            <!--DRAG&DROP AND FIELDS CONTAINER-->
                <div fxLayout="row">
                 <!--DRAG&DROP SECTION-->
                <div fxLayout="column" fxLayoutAlign="center center" class="dragAndDropSection" DropZone
                     (FileDropped)="droppedFiles($event)">
                    <h2>Drag Files Here</h2>
                    <h5>Or,If you prefer </h5>
                    <input type="file" class="file-input" accept="video/mp4,video/x-m4v,image/*"
                           (change)="droppedFiles($event?.target?.files)" #fileDropRef id="fileDropRef" multiple/>
                    <button (click)="fileDropRef.click()">Choose files to upload</button>

                </div>
                <mat-divider vertical class="mx-4"></mat-divider>
                <div fxLayout="row" >
                    <!--Images Container-->
                    <div fxLayout="column">
                        <div class="file-containers" *ngFor="let file of this.imageSrc; let i = index">
                            <img src="{{file?.src}}" *ngIf="getType(file.content_type)">
                            <mat-icon *ngIf="!getType(file.content_type)" class="video-icon">videocam</mat-icon>
                            <div class="overlay" >
                                <mat-icon class="cross-icon" (click)="removeAttachment(i ,file)">close</mat-icon>
                            </div>
                        </div>

                    </div>
                    <!-- Fields Container-->
                    <div fxLayout="column"  class="field-container">
                        <!--                        SELECT FOLDER-->
                        <div class=" categories px-24 ">
                            <mat-form-field appearance="outline">
                                <mat-select name="categories" placeholder="{{'PRODUCT.category'|translate}}"   formControlName="categoryId">
                                    <mat-option *ngFor="let category of categories" [value]="category?.id">
                                        {{category.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!--                        TITLE-->
                        <div class="card-name px-24 mb-8">
                            <rich-text-field formControlName="title" [data]="'<></>'"
                                             [placeholder]="'THREAD.title' | translate" [limit]="50"></rich-text-field>
                        </div>
                        <!--                        DESCRIPTION-->
                        <div class="description px-24 mb-4">
                            <rich-text-field formControlName="description" class="rich-text1" [data]="'<></>'"
                                             [placeholder]="'THREAD.description' | translate"></rich-text-field>
                        </div>
                        <!--                        PRICE-->
                        <div class="text-fields px-24 mb-4" fxLayout="row">
                            <mat-form-field appearance="outline">
                                <input matInput type="text" formControlName="price"
                                       placeholder="{{'PRODUCT.price'|translate}}" (input)="inputValidator($event)">
                            </mat-form-field>
                            <p>DKK</p>
                        </div>
                        <mat-error class="px-24 mb-4" *ngIf="productForm.get('price')?.hasError('invalid')">
                            {{'PRODUCT.invalid_error'|translate}}
                        </mat-error>
                        <!--PRIORIIES-->
                        <div class="section-content px-24">
                            <mat-chip-list class="label-chips">

                                <ng-container *ngFor="let priority of priorities;let i = index">
                                    <mat-chip class="label-chip mb-4 "
                                              [ngClass]="productForm?.get('priority').value === priority.name ? priority.color : ''"
                                              fxLayoutAlign="start center">
                                        <span class="section-header-point-cursor"
                                              (click)="prioritySelection(priority.name)">{{ priority.name | uppercase | translate}}</span>
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
                    </div>
                </div>
                </div>
                <mat-divider vertical></mat-divider>
                <!--PRODUCT VARIATION SECTION-->
                <div fxLayout="column" fxLayoutAlign="space-between space-between" class="variation-section"
                     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
                    <div>
                        <div formArrayName="variations">
                            <div *ngFor="let variation of variations.controls; let i = index;">
                                <div class="text-fields px-24 mb-4" [formGroupName]="i" fxLayout="row">
                                    <mat-form-field appearance="outline" class="mx-4 my-8">
                                        <input matInput class="quantity-field"   formControlName="quantity"
                                               placeholder="{{'PRODUCT.quantity'|translate}}"   (input)="inputValidatorQuantity($event)">
                                        <mat-error class="my-8" *ngIf="variation?.controls?.quantity?.errors?.required && variation.controls.quantity.touched" >Quantity is required</mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="mx-4 my-8 ">
                                        <input matInput type="text" formControlName="variation"
                                               placeholder="{{'PRODUCT.size_color'|translate}}">
                                        <mat-error class="my-8" *ngIf="variation?.controls?.variation?.errors?.required && variation.controls.variation.touched" >Variation is required</mat-error>
                                    </mat-form-field>
                                    <button mat-fab
                                            *ngIf="i!==0"
                                            color="accent"
                                            (click)="removeVariation(i  ,variation)"
                                            class="variation-button"
                                            aria-label="Add Variation">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <button mat-fab
                                            *ngIf="i===0"
                                            color="accent"
                                            (click)="addVariation()"
                                            class="add-product-button ml-3 mx-12"
                                            aria-label="Add Variation">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!--                    BOTTOM SECTION-->
                    <div fxLayout="column">
                        <div class="categories px-24 " *ngIf="marketplace?._checked">
                            <mat-form-field appearance="outline">
                                <mat-select name="categories" placeholder="{{'PRODUCT.category'|translate}}"   formControlName="marketCategory">
                                    <mat-option *ngFor="let category of marketCategories" [value]="category?.id">
                                        {{category.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" class="py-8 px-28">
                            <mat-checkbox class="example-margin"
                                          #marketplace
                                          formControlName="isMarketplaceProduct">{{'Show in the marketplace'|translate}}</mat-checkbox>
                        </div>
                        <div class="px-8  create-button-container">
                            <button class="create-button" (click)="saveProduct()"
                                    *ngIf="newThread">{{'Create' |translate}}</button>
                            <button class="create-button" (click)="saveProduct()"
                                    *ngIf="!newThread">{{'THREAD.update' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
