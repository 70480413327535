import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { IUploadingFiles } from './uploading-files.model';

@Injectable({providedIn: 'root'})
export class UploadingFilesEntityService extends EntityCollectionServiceBase<IUploadingFiles>
{
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory)
    {
        super('UploadingFiles', serviceElementsFactory);
    }
}
