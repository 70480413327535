import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { IVariation, Thread } from '../../../../store/thread/thread.model';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dailog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import { ThreadsDataService } from '../../../../store/thread/threads-data.service';
import { ThreadsEntityService } from '../../../../store/thread/threads-entity.service';
import { ProductCreationModalComponent } from '../dialogs/product-creation-modal/product-creation-modal.component';
import { NewAuthDataService } from '../../../../service/api/newAuth-data.service';
import { User } from '../../../../types';
import { GuestModalComponent } from '../dialogs/guest-modal/guest-modal.component';
import { CartDataService } from '../../../../store/cart/cart-data.service';
import { ActivatedRoute } from '@angular/router';
import { GroupsEntityService } from '../../../../store/group/groups-entity.service';
import { IGroup } from '../../../../store/group/group.model';
import {Lightbox} from "ng-gallery/lightbox";
import {GalleryRef} from "ng-gallery/lib/services/gallery-ref";
import {Gallery, ImageItem} from "ng-gallery";

@Component({
  selector: 'single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SingleProductComponent implements OnInit, OnDestroy {
    currentUser: User;
    threadAuthor: boolean;
    currentClinic: IGroup | any;
    variations: IVariation[] = [];
    thumbsSwiper: any;
    swiperDirection = 'vertical';
    @Input() product: Thread | any;
    @Input() isModal: boolean;
    private clinicId: number;
    galleryId = 'myLightbox' + Math.random();
    galleryRef: GalleryRef;
    @ViewChild('itemTemplate') itemTemplate: TemplateRef<any>;
    @ViewChild('thumbTemplate') thumbTemplate: TemplateRef<any>;
    constructor(private _matDialog: MatDialog,
                private progressBarService: FuseProgressBarService,
                private toast: ToastrService,
                private threadDataService: ThreadsDataService,
                private cartDataService: CartDataService,
                private threadEntity: ThreadsEntityService,
                private authService: NewAuthDataService,
                private _route: ActivatedRoute,
                private groupEntity: GroupsEntityService,
                private lightbox: Lightbox,
                public gallery: Gallery,
    )
    {

    }



    ngOnInit(): void
    {

        this.authService.getUser().subscribe((data) => {
            this.currentUser = data;
        });
        this.variations = this.product?.productVariations.map((item, index) => {
            return {
                ...item,
                selected: index === 0 ? true : false,
            };
        });
        this.threadAuthor = this.currentUser.id === this.product.docCreator?.id;

    }
    ngAfterViewInit(): void
    {
        // Load items into gallery
        this.galleryRef = this.gallery.ref(this.galleryId);

        this.galleryRef.setConfig({
            itemTemplate : this.itemTemplate,
            thumbTemplate: this.thumbTemplate,
        });
        this.galleryRef.load(this.product?.files?.map((v) => {
            return new ImageItem({
                src  : v.src,
                thumb: v.medium_url
            });
        }));

        this.galleryRef.itemClick.subscribe((item) => {
        });
    }
    ngOnDestroy(): void
    {
        this.galleryRef?.destroy();
    }
    openInFullScreen(index: number): void
    {
        this.lightbox.open(index, this.galleryId, {
            panelClass: 'fullscreen'
        });
    }
    deleteProduct(): void
    {
        this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: 'confirmation-dialog',
            data      : {
                title  : 'Delete Thread',
                message: 'Are you sure you want to delete this product?'
            }
        }).afterClosed().subscribe((res) => {
            if ( res && res[0] === 'yes' )
            {
                this.progressBarService.show();
                this.threadDataService.deleteThread(this.product?.id).subscribe((data) => {
                    this.toast.success('Product Deleted Successfully');
                    this.threadEntity.delete(this.product?.id);
                    this.progressBarService.hide();
                }, (error) => {
                    this.progressBarService.hide();
                    this.toast.error(error.error, 'Error');
                });
            }
        });
    }

    editProduct(): void
    {
        this._matDialog.open(ProductCreationModalComponent, {
            panelClass: 'card-dialog',
            data      : {
                new       : false,
                threadData: this.product,
            }
        });
    }

    selectVariation(variation): void
    {
        for (const x of this.variations)
        {
            if ( x.id === variation.id )
            {
                x.selected = true;
            } else
            {
                x.selected = false;
            }
        }
    }


    addToBasket(): void
    {
        if (this.currentUser.email === 'guest@onlinedoc.dk')
        {
            this._matDialog.open(GuestModalComponent, {
                panelClass: 'guest-modal',
                data      : {
                    name: 'test',
                }
            }).afterClosed().subscribe((res) => {

            });
            return;
        }
        this.progressBarService.show();
        const data = {
            "clinic_id": this.product.clinicId,
            "doc_id": this.product.id,
            "variation_id": this.product?.productVariations[0]?.id,
            "quantity": 1
        };
        this.cartDataService.addToCart(this.currentUser.shopping_cart_id,data).subscribe((res) => {
            this.toast.success('', res?.message ?? 'Product added to basket successfully');
            this.cartDataService.updateCartCount({total_cart_items: res?.total_cart_items ?? 69})
            this.progressBarService.hide();
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.errors.join(','), 'Error');
        })
    }

    turnThreadNotificationOff(): void
    {
        if (this.currentUser.email === 'guest@onlinedoc.dk')
        {
            this._matDialog.open(GuestModalComponent, {
                panelClass: 'guest-modal',
                data      : {
                    name: 'test',
                }
            }).afterClosed().subscribe((res) => {

            });
            return;
        }
        this.progressBarService.show();
        const clinicData = {
            'doc_notification': {
                clinic_id   : this.product.clinicId,
                doc_id      : this.product.id,
                notification: false
            }
        };
        this.threadDataService.threadNotificationToggle(clinicData).subscribe((data) => {
            this.toast.success('Product Notifications off');
            this.progressBarService.hide();
            this.product.docNotification = false;
            this.threadEntity.updateOneInCache({docNotification: false, id: this.product.id});
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.error, 'Error');
        });
    }


    turnThreadNotificationOn(): void
    {
        if (this.currentUser.email === 'guest@onlinedoc.dk')
        {
            this._matDialog.open(GuestModalComponent, {
                panelClass: 'guest-modal',
                data      : {
                    name: 'test',
                }
            }).afterClosed().subscribe((res) => {

            });
            return;
        }
        this.progressBarService.show();
        const clinicData = {
            'doc_notification': {
                clinic_id   : this.product.clinicId,
                doc_id      : this.product.id,
                notification: true
            }
        };
        this.threadDataService.threadNotificationToggle(clinicData).subscribe((data) => {
            this.toast.success('Product Notifications on');
            this.progressBarService.hide();
            this.product.docNotification = true;
            this.threadEntity.updateOneInCache({docNotification: true, id: this.product.id});
        }, (error) => {
            this.progressBarService.hide();
            this.toast.error(error.error, 'Error');
        });
    }
}
