<div class="product-dialog-container">
    <div><mat-icon class="icon-color" (click)="matDialogRef.close(['close'])">close</mat-icon></div>
    <div class="tab-container">
    <mat-tab-group [backgroundColor]="tabBackgroundColor" [selectedIndex]="selectedIndex"  (selectedTabChange)="_selectedTabChange($event.index)"
                   (selectedIndexChange)="_selectedIndexChange($event)">

        <mat-tab label="{{'PRODUCT.product_info'|translate}}">

            <div class="tab-content p-16" >
                <single-product [product]="product" [isModal]="isModal"></single-product>
            </div>

        </mat-tab>
        <mat-tab label="{{'TASKS.comment'| translate}}">

            <div class="tab-content p-16" >
                <post-footer [post]="product" [type]="showFooterType"></post-footer>
            </div>

        </mat-tab>

    </mat-tab-group>
    </div>
</div>
