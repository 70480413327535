import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GuestModalComponent} from './guest-modal.component';
import {MatDialogModule} from "@angular/material/dialog";
import {FlexModule} from "@angular/flex-layout";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [GuestModalComponent],
    imports: [
        CommonModule,

        MatDialogModule,
        FlexModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule
    ]
})
export class GuestModalModule {
}
