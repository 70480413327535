<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <div></div>
            <span class="title dialog-title">{{'THREAD.choose_group' | translate}}</span>
            <div fxLayout="row">
                <button mat-icon-button aria-label="Close dialog" matTooltip="close" (click)="matDialogRef.close(['close'])">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <div mat-dialog-content class="overflowContent" (scrolled)="getApprovedGroups()"
         [infiniteScrollDisabled]="false"
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="0"
         [scrollWindow]="false"
         infinite-scroll>
        <form>
            <div fxLayout="row" fxLayoutAlign="start start" class="search-field-con">
                <span class="ml-4"><mat-icon class="share-icons">search</mat-icon></span>
                <input class="search-field full-width" [formControl]="searchField" type="search" placeholder="{{'THREAD.search_for_group' | translate}}">
            </div>
        </form>
        <p class="mt-16 bold-text">
            {{'THREAD.fixed'|translate}}
        </p>
        <div fxLayout="row" fxLayoutAlign="space-between" class="mt-4 cursor-pointer"
             (click)="shareContent === 'contact' ? shareContact(myDocId) : shareThread(myDocId, post ,currentUser?.my_doc) ">
            <div fxLayout="row">
                <img  [src]="currentUser?.file?.src" class="group-image">
                <div fxLayout="column" class="ml-12">
                    <p class="bold-text">My Docs</p>
                    <p class="group-description">My Personal Group</p>
                </div>
            </div>
            <div>
                <mat-icon class="share-icons">
                    arrow_forward_ios
                </mat-icon>
            </div>
        </div>
        <div>
            <p class="mt-16 bold-text">
                {{'THREAD.all_group'|translate}}
            </p>
            <div *ngIf="groupListing.length > 0 else noGroup">
            <div fxLayout="row" fxLayoutAlign="space-between" class="mt-4 mb-12 cursor-pointer" *ngFor="let group of groupListing"
                 (click)="shareContent === 'contact' ? shareContact(group.id) : shareThread(group.id, post ,group) ">
                <div fxLayout="row">
                    <img [src]="group.file ? group.file.src : '/assets/images/Group/users-group.png'" class="group-image">
                    <div fxLayout="column" class="ml-12">
                        <p class="bold-text">{{group?.name}}</p>
                        <p class="group-description">{{group?.description}}</p>
                    </div>
                </div>
                <div class="mt-12">
                    <mat-icon class="share-icons">
                        arrow_forward_ios
                    </mat-icon>
                </div>
        </div>
            </div>
            <ng-template #noGroup>
                <div class="noGroupFound">
                    <h4>No Groups Found</h4>
                </div>
            </ng-template>
    </div>

</div>
</div>
