<div class="loading-div comment-loading" *ngIf="loadingProgress">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>
<div class="post-files-content">
    <table class="simple">
        <thead>
        <tr>
            <th class="w-80">{{'GALLERY.image' | translate}}</th>
            <th>{{'THREAD.description' | translate}}</th>
            <th>info</th>
            <th class="w-80">{{'SETTINGS.users' | translate}}</th>
            <th class="w-80">{{'GALLERY.actions' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr class="product-row" *ngFor="let fileObj of files; let index = index">
            <td>
                <div *ngIf="isTypeImage(fileObj,'image') || isTypeImage(fileObj,'link')">
                    <a [href]="openFile(fileObj)" target="_blank" matRipple class="cursor-pointer">
                        <img *ngIf="isTypeImage(fileObj,'image')" [src]="fileObj.thumb_url">
                        <mat-icon *ngIf="isTypeImage(fileObj,'link')" class="s-50 icon">picture_as_pdf</mat-icon>
                    </a>
                </div>
                <div *ngIf="isTypeImage(fileObj,'video')">
                    <button mat-icon-button (click)="openVideo(fileObj)" matRipple>
                        <mat-icon class="s-50 icon">video_library</mat-icon>
                    </button>
                </div>
            </td>
            <td class="text-overflow">
                {{ fileObj.name || fileObj.filename}}
            </td>
            <td class="text-overflow">
                <b>{{'GALLERY.size' | translate}} : </b>  {{ fileObj.byte_size / 1000 | number : '1.0-2'}}<br/>
                <b>Type :</b> {{ fileObj.content_type}}
            </td>
            <td>
                {{ fileObj.uploadedBy || 'YOU' }}
            </td>
            <td>
                <mat-icon *ngIf="deletePrivilige || user.id === fileObj.creatorId"
                          (click)="deleteFile(fileObj, this.post, index)">delete_outline
                </mat-icon>
            </td>
        </tr>
        </tbody>
    </table>
</div>
<ng-container>
    <ng-container *ngIf="(post.clinicDocCommentFileCount + post.clinicDocFileCount)>files?.length">
        <div (click)="loadMore()" class="comment-count group-name-color pt-12"
             fxLayout="row" fxLayoutAlign="start center">
            {{'GALLERY.load_more' | translate}}
        </div>
    </ng-container>
</ng-container>
