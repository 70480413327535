<mat-menu #commentReadListMenu="matMenu" [overlapTrigger]="false">
    <div infinite-scroll class="specific-height"
         [scrollWindow]="false"
         [infiniteScrollDisabled]="holdingScroll"
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="0"
         (scrolled)="loadMoreReads()">

        <div fxFlex fxLayout="column" class="scrumboard-members-menu"
             (click)="$event.stopPropagation()" *ngFor="let member of commentReadList; let i = index">
            <div [userProfile]="member.doc_read_user?.id" class="mx-8 my-8 cursor-pointer" fxLayout="row"
                 fxLayoutAlign="start center">
                <profile-image [src]="member.doc_read_user?.file?.thumb_url"
                               class="avatar"></profile-image>
                <p class="member-name">{{member.doc_read_user?.full_name}}</p>
            </div>
        </div>
    </div>
</mat-menu>
<span class="time" *ngFor="let read of readList.slice(0, 3); let index = index;">
                 <profile-image [userProfile]="read.user?.id" class="read-image cursor-pointer"
                                [matTooltip]="read.user?.full_name"
                                [src]="read.user?.file?.thumb_url"></profile-image>
                </span>
<div *ngIf="readList.length > 3" class="more-reads cursor-pointer" (menuClosed)="menuClosed()"
     [mat-menu-trigger-for]="commentReadListMenu" (click)="getCommentReads()">
    <mat-icon>expand_more</mat-icon>
</div>
